
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  createLoadingStatesSelector } from 'ngrx-loading-state';
import * as ImageViewerActions from './image-viewer.actions';
import * as ImageViewerReducer from './image-viewer.reducer';


const selectState = createFeatureSelector<ImageViewerReducer.ImageViewerState>(ImageViewerReducer.IMAGE_VIEWER_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);


export const selectOpened = createSelector(
    selectState,
    (state) => state.opened
  );

  
