import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";



@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  public getBackgroundColor(colors: string, isMobile = false, p1 = false) : string | null {
    let bgColor: string|null = null;
    if(!colors) return bgColor;
    const colorsList = colors.split('|');
    let p = 45;
    let g = 50;
    let r = 55;
    if(isMobile && !p1) p = 25;
    if(isMobile && !p1) r = 75;
    switch (colorsList.length) {
      case 1:
        bgColor = `radial-gradient(circle,rgba(255,255,255,.65) 0%,rgba(255,255,255,.45) 65%, rgba(0,0,0,.25) 100%), ${colors};`;
        if (p1) bgColor = `radial-gradient(circle,rgba(255,255,255,.65) 0%, rgba(0,0,0,.25) 100%), ${colors};`;
        break;
      case 2:
        bgColor = `radial-gradient(circle,${colorsList[0]} 0%,rgba(255,255,255,.45) 65%, ${colorsList[1]}) 100%), ${colors};`;
        if (p1) bgColor = `radial-gradient(circle, ${colorsList[0]},${colorsList[1]}) ;`;
        break;
      case 3:
        bgColor = `radial-gradient(circle,${colorsList[0]},${colorsList[1]},${colorsList[1]}, ${colorsList[2]})`;
        if (p1) bgColor = `radial-gradient(circle, ${colorsList[0]},${colorsList[1]},${colorsList[2]}) ;`;
        break;

      default:
        break;
    }
  
    return bgColor;
  }

}
