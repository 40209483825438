import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/permissions.service';
import { LayoutMainComponent } from './modules/layout-main/layout-main/layout-main.component';

const routes: Routes = [
  {
    path: ':languageCode/document',
    loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'document', redirectTo: '/fr-fr/document'
  },
  {
    path: '',
    component: LayoutMainComponent,
    children: [
      {
        path: ':languageCode/catalog',
        loadChildren: () => import('./modules/catalog/catalog.module').then(m => m.CatalogModule)
      },
      {
        path: 'catalog', redirectTo: '/fr-fr/catalog'
      },

      {
        path: ':languageCode/contact',
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'contact', redirectTo: '/fr-fr/contact'
      },

      {
        path: ':languageCode/info',
        loadChildren: () => import('./modules/info/info.module').then(m => m.InfoModule)
      },
      {
        path: 'info', redirectTo: '/fr-fr/info'
      },

      {
        path: ':languageCode/manage',
        loadChildren: () => import('./modules/manage/manage.module').then(m => m.ManageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage', redirectTo: '/fr-fr/manage'
      },

      {
        path: ':languageCode/error',
        loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule)
      },
      {
        path: 'error', redirectTo: '/fr-fr/error'
      },

    
      {
        path: ':languageCode/auth',
        loadChildren: () => import('./modules/authentification/authentification.module').then(m => m.AuthentificationModule),
      },
      {
        path: 'auth', redirectTo: '/fr-fr/auth'
      },


      {
        path: ':languageCode',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        // canActivate: [AuthGuard]
      },

      {
        path: '', redirectTo: '/fr-fr', pathMatch: 'full'
      }
    ]
  },
  
  

  {path:"**", redirectTo:'/fr-fr/error/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0],
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
