import { Component, Input } from '@angular/core';
import { ProductItem } from '../../../models/product.model';

@Component({
  selector: 'app-item-pub-product',
  templateUrl: './item-pub-product.component.html',
  styleUrls: ['./item-pub-product.component.scss']
})

export class ItemPubProductComponent {
  @Input() product!: ProductItem; 
}
