import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoadAction, LoadingState } from "ngrx-loading-state";
import { Observable } from "rxjs";
import { User } from "../../models/user.model";
import { LoginStart } from "../../modules/authentification/models/login-start.model";
import { RegisterStart } from "../../modules/authentification/models/register-start.model";
import { ResetPassword } from "../../modules/authentification/models/reset-password.model";


import * as AuthActions from './auth.actions';
import * as AuthSelectors from "./auth.selectors";



@Injectable({ providedIn: 'root' })
export class AuthFacade {
  constructor(private store: Store) { }

  register(registerViewModel:RegisterStart & LoadAction): void {
    this.store.dispatch(AuthActions.register.load(registerViewModel));
  }


  login(loginViewModel:LoginStart & LoadAction): void {
    this.store.dispatch(AuthActions.login.load(loginViewModel));
  }

  loginAs(options:{userId:string, redirect:boolean} & LoadAction): void {
    this.store.dispatch(AuthActions.loginAs.load(options));
  }

  refreshLogin(options: any & LoadAction): void {
    this.store.dispatch(AuthActions.refreshLogin.load(options));
  }

  requestResetPassword(options : {email: string} & LoadAction): void {
    this.store.dispatch(AuthActions.requestResetPassword.load(options));
  }

  resetPassword(resetPasswordViewModel : ResetPassword & LoadAction): void {
    this.store.dispatch(AuthActions.resetPassword.load(resetPasswordViewModel));
  }

  autoLogin(options: LoadAction): void {
    this.store.dispatch(AuthActions.autoLogin.load(options));
  }

  logout(options: LoadAction): void {
    this.store.dispatch(AuthActions.logout.load(options));
  }


  /////////////

  getUser(): Observable<User | null> {
    return this.store.select(AuthSelectors.selectUser);
  }

  getIsAuthenticated(): Observable<boolean> {
    return this.store.select(AuthSelectors.selectIsAuthenticated);
  }

  getMessage(): Observable<string|null> {
    return this.store.select(AuthSelectors.selectMessage);
  }

  /////////////

  getRegisterState(): Observable<LoadingState> {
    return this.store.select(AuthSelectors.registerSelectors.state);
  }

  getLoginState(): Observable<LoadingState> {
    return this.store.select(AuthSelectors.loginSelectors.state);
  }


  getRefreshLoginState(): Observable<LoadingState> {
    return this.store.select(AuthSelectors.refreshLoginSelectors.state);
  }

  getRequestPasswordState(): Observable<LoadingState> {
    return this.store.select(AuthSelectors.requestPasswordSelectors.state);
  }

  getResetPasswordState(): Observable<LoadingState> {
    return this.store.select(AuthSelectors.resetPasswordSelectors.state);
  }

  
  getAuoLoginState(): Observable<LoadingState> {
    return this.store.select(AuthSelectors.autoLoginSelectors.state);
  }

  getLogoutState(): Observable<LoadingState> {
    return this.store.select(AuthSelectors.logoutSelectors.state);
  }

}