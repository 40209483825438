import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoadAction, LoadingState } from "ngrx-loading-state";
import { Observable } from "rxjs";
import { DoofinderResult } from "src/app/models/doofinder-result.model";

import * as DoofinderActions from './doofinder.actions';
import * as DoofinderSelectors from "./doofinder.selectors";



@Injectable({ providedIn: 'root' })
export class DoofinderFacade {
    constructor(private store: Store) { }

    search(options: { query:string,filterBy?:string, rpp?:number, query_name?:string, grouping?:boolean, page?:number, sortBy?:string } & LoadAction): void {
        this.store.dispatch(DoofinderActions.search.load(options));
    }

    getSearchResult(): Observable<DoofinderResult | null> {
        return this.store.select(DoofinderSelectors.selectResult);
    }

    getSearchState(): Observable<LoadingState> {
        return this.store.select(DoofinderSelectors.searchSelectors.state);
    }


    /////



}