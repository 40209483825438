
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  createLoadingStatesSelector } from 'ngrx-loading-state';
import * as CartActions from './cart.actions';
import * as CartReducer from './cart.reducer';


const selectState = createFeatureSelector<CartReducer.CartState>(CartReducer.CART_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);


export const selectCart = createSelector(
    selectState,
    (state) => state.cart
  );

  export const selectPaymentForm = createSelector(
    selectState,
    (state) => state.paymentForm
  );

  export const selectPaymentStatus = createSelector(
    selectState,
    (state) => state.payStatus
  );


  export const selectDrawerOpened = createSelector(
    selectState,
    (state) => state.drawerOpened
  );


  export const selectDrawerContent = createSelector(
    selectState,
    (state) => state.drawerContent
  );

  export const selectNewProductId = createSelector(
    selectState,
    (state) => state.newProductId
  );

  export const selectDocumentId = createSelector(
    selectState,
    (state) => state.documentId
  );
  export const selectDocumentValue = createSelector(
    selectState,
    (state) => state.documentValue
  );


export const shippingAddressesSelectors = CartReducer.adapterShippingAddresses.getSelectors(createSelector(selectState, (state) => state.addresses));
export const productsRelatedSelectors = CartReducer.adapterProductsRelated.getSelectors(createSelector(selectState, (state) => state.productsRelated));

  

export const fetchCartStateSelectors = CartActions.fetchCart.createSelectors(selectLoadingStates);
export const updateCartStateSelectors = CartActions.updateCart.createSelectors(selectLoadingStates);
export const deleteCartStateSelectors = CartActions.deleteCart.createSelectors(selectLoadingStates);
export const loadCartStateSelectors = CartActions.loadCart.createSelectors(selectLoadingStates);
export const addToCartStateSelectors = CartActions.addToCart.createSelectors(selectLoadingStates);
export const addRemoveFromCartStateSelectors = CartActions.addRemoveFromCart.createSelectors(selectLoadingStates);
export const addCommentToCartStateSelectors = CartActions.addCommentToCart.createSelectors(selectLoadingStates);
export const addCommentToRowCartStateSelectors = CartActions.addCommentToRowCart.createSelectors(selectLoadingStates);
export const addCouponToCartStateSelectors = CartActions.addCouponToCart.createSelectors(selectLoadingStates);
export const clearCartStateSelectors = CartActions.clearCart.createSelectors(selectLoadingStates);
export const setShippingAddressStateSelectors = CartActions.setShippingAddress.createSelectors(selectLoadingStates);
export const fetchProductsRelatedStateSelectors = CartActions.fetchProductsRelated.createSelectors(selectLoadingStates);
export const fetchUserAddressesStateSelectors = CartActions.fetchUserAddresses.createSelectors(selectLoadingStates);
export const replaceProductInCartStateSelectors = CartActions.replaceProductInCart.createSelectors(selectLoadingStates);
export const fetchPaymentFormSelectors = CartActions.fetchPaymentForm.createSelectors(selectLoadingStates);
export const checkoutOrderSelectors = CartActions.checkoutOrder.createSelectors(selectLoadingStates);
export const checkMandatSelectors = CartActions.checkMandat.createSelectors(selectLoadingStates);
