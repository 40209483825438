export class User {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public id: string,
    private _token?: string,
    public refreshToken?: string,
    public _tokenExpirationDate?: Date,
    public  userRoles?:string[],
    public loginAs?:boolean,
    public customerId?:number|null|undefined
  ) { }

  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    return this._token;
  }
}

export class Profile {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public phone?: string,
    public cellPhone?: string,
    public address1?: string,
    public address2?: string,
    public zipCode?: string,
    public city?: string,
    public country?: string,
    public dateOfBirth? :string,
  ) { }


}


export class UserRole{
  constructor(
    public role:string,
    public granted:boolean
  ){}
}