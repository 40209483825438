import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, debounceTime } from 'rxjs';
import { Category } from 'src/app/models/category.model';
import { environment } from 'src/environments/environment';

interface DialogData {
  type: string,
  numbers?: number[],
  categories?: Category[],
  selectedCategories?:number[],
  selectedVhsTypes?:number[],
  selectedValue?: number,
  scrollValue?: number
}

@Component({
  selector: 'app-dialog-slider-selector',
  templateUrl: './dialog-slider-selector.component.html',
  styleUrls: ['./dialog-slider-selector.component.scss']
})
export class DialogSliderSelectorComponent implements OnInit {

  selectedIndex: number = 0;
  selectedValue: number = this.data.selectedValue!;
  scrollTop!: number;

  ctrlSelectedCategories = new FormControl<number[] | null>(null);
  ctrlSelectedVhsType = new FormControl<number[] | null>(null);

  attributElectrique = environment.ATTRIBUT_VALUE.ELECTRIQUE;
  attributThermique = environment.ATTRIBUT_VALUE.THERMIQUE;

  constructor(
    public dialogRef: MatDialogRef<DialogSliderSelectorComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }

  ngOnInit(): void {
    //   if(!this.data.numbers ||  this.data.numbers.length == 0){
    //   for (let i = 60; i < 200; i++) {
    //     this.data.numbers.push(i);

    //   }
    // }
    if(this.data.type != 'categories'){
    this.onScroll();
    this.onInitScroll();
    }
    if(this.data.selectedCategories){
      this.ctrlSelectedCategories.setValue(this.data.selectedCategories!);
    }
    if(this.data.selectedVhsTypes){
      this.ctrlSelectedVhsType.setValue(this.data.selectedVhsTypes!);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onValidate() {
    if (this.data.type != 'categories') {
      this.dialogRef.close({ selectedValue: this.selectedValue, scrollValue: this.scrollTop });
    }
    else {
      this.dialogRef.close({ selectedCategories:this.ctrlSelectedCategories.value, selectedVhsTypes : this.ctrlSelectedVhsType.value });
    }
  }

  onScroll() {
    if (this.data.numbers) {
      const container = document.querySelector('.scroll-container');
      if (container) {
        const scrollTop = container.scrollTop;
        this.scrollTop = scrollTop;
        const itemHeight = container.clientHeight / 9;
        this.selectedIndex = Math.round((scrollTop - itemHeight * 4) / itemHeight) + 4;
        this.selectedValue = this.data.numbers[this.selectedIndex];
      }
    }
    this.cdr.detectChanges();
  }

  onInitScroll() {
    const container = document.querySelector('.scroll-container');
    if (container) {
      const itemHeight = container.clientHeight;
      if (this.data.scrollValue || this.data.scrollValue == 0 ) {
        container.scrollTo(0, this.data.scrollValue);
      }
      else {
        container.scrollTo(0, itemHeight);
      }
    }

  }

}
