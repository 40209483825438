import { createReducer } from "@ngrx/store";
import { getInitialState, WithLoadingStates } from "ngrx-loading-state";
import { Tab } from "../../models/tab.model";
import { JsonLd } from "../../models/jsonld.model";

import * as SeoActions from './seo.actions';


export const SEO_FEATURE_KEY = 'seo';


export type SeoState = WithLoadingStates & {
    tab:Tab|null,
    jsonLd:JsonLd|null,

};


export const initialState: SeoState = {
    ...getInitialState(),
    tab:null,
    jsonLd:null
};


export const seoReducer = createReducer(
    initialState,
    ...SeoActions.fetchTab.reducer<SeoState>({
        onSuccess: (state, response): SeoState => {
            return {
                ...state,
                 tab: {...response}
            };
        }
    }),
    ...SeoActions.fetchJsonLd.reducer<SeoState>({
        onSuccess: (state, response): SeoState => {
            return {
                ...state,
                 jsonLd: {...response}
            };
        }
    }),
);
