<div class="pt-3">
    <div class="row mx-0" *ngIf="documentRow">
        <div class="w-auto">
            <img [src]="documentRow.pictureUrlSmall" width="100">
        </div>
        <div class="col">
            <h3>{{documentRow.designation}}</h3>
            <p>ID: {{documentRow.productId}}</p>
        </div>
    </div>
    <hr *ngIf="documentRow">
    <div class="row mb-3">
        <div class="col-12 px-0">
            <ng-container *ngFor="let productVariant of productVariants">
                <div class="col-12 row mx-0 row justify-content-between justify-content-md-start mt-2 mb-3">
                    <p class="f-size-16px font-weight-medium mb-0 px-0" [class.col-12]="mobileQuery.matches">
                        {{ productVariant.designation | translate}}
                    </p>
                </div>

                <div class="col-12 row mx-0 option">
                    <ng-container *ngFor="let variantDetails of productVariant.variants">
                      <a (click)="onSelectVariant(variantDetails)"
                         class="d-flex option-img-three text-decoration-none cursor-pointer position-relative"
                         [class.select]="variantDetails.current || (selectedVariantDetails && selectedVariantDetails.designation == variantDetails.designation)"
                         [class.disabled]="variantDetails.current" *ngIf="variantDetails.pictureFile">
                        <!--<img [src]="variantDetails.pictureFile" [alt]="variantDetails.designation" *ngIf="getProductBgColor(variantDetails) != '' && productVariant.attributId == 29 && mobileQuery.matches"
                             [title]="variantDetails.designation" [class.col-12]="mobileQuery.matches" [class.px-0]="mobileQuery.matches" [style]="'background:' + (getProductBgColor(variantDetails) && productVariant.attributId == 29 ? getProductBgColor(variantDetails) : '') " />-->
                        <div class="color-box" [class.w-100]="mobileQuery.matches" *ngIf="getProductBgColor(variantDetails) != '' && productVariant.attributId == 29"
                             [style]="'background:' + (getProductBgColor(variantDetails, true) && productVariant.attributId == 29 ? getProductBgColor(variantDetails, true) : '') ">
                        </div>
                        <span class="f-size-13px m-auto font-weight-medium text-center" [class.w-100]="mobileQuery.matches">{{variantDetails.designation}}</span>
                        <!-- PASTILLE DISPO -->
                        <span *ngIf="variantDetails.disponibility == 2"
                              class="rounded-circle bg-danger text-white position-absolute d-flex border-white tag-available">
                          <span class="k-bell text-white m-auto f-size-12px"></span>
                        </span>
                        <span *ngIf="variantDetails.disponibility == 1"
                              class="rounded-circle bg-main-gradient position-absolute d-flex border-white tag-available">
                          <span class="k-warning m-auto f-size-12px"></span>
                        </span>
                      </a>

                        <a (click)="onSelectVariant(variantDetails)"
                            class="d-flex cursor-pointer option-text-six text-decoration-none"
                            [class.select]="variantDetails.current || (selectedVariantDetails && selectedVariantDetails.designation == variantDetails.designation)"
                            [class.disabled]="variantDetails.current" *ngIf="!variantDetails.pictureFile">
                            <span class="f-size-13px m-auto font-weight-medium">{{variantDetails.designation}}</span>
                            <span *ngIf="variantDetails.disponibility == 2"
                                class="rounded-circle bg-danger text-white position-absolute d-flex border-white tag-available"><span
                                    class="k-bell text-white m-auto f-size-12px"></span></span>
                            <span *ngIf="variantDetails.disponibility == 1"
                                class="rounded-circle bg-main-gradient position-absolute d-flex border-white tag-available"><span
                                    class="k-warning m-auto f-size-12px"></span></span>
                        </a>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="row mt-2 mx-0 px-0 justify-content-between">
        <div class="col-md-4 col-12 px-0">
            <button color="primary" (click)="onClose()"
                class="border-grey border-0 font-weight-semibold w-100 text-black bg-grey rounded minh-60px">
                <span class="f-size-14px font-weight-semibold m-auto d-flex justify-content-center">
                    {{'cancel' | translate}}
                </span>
            </button>
        </div>

        <div class="col-md-4 col-12 px-0 mt-3 mt-md-0">
            <button (click)="onReplaceProduct()"
                *ngIf="documentRow && product && (product.disponibility == 0 || product.disponibility == 1) && documentRow.productId != product.productId"
                [disabled]="(cartFacade.getReplaceProductInCartState() | async)?.loading"
                class="btn-theme text-decoration-none minh-56px">
                <p class="m-auto font-weight-bold">
                    {{ (cartFacade.getReplaceProductInCartState() | async)?.loading ? ('loading' | translate) + '...' :
                    (product.disponibility == 0 ? ('validate' | translate) : ('preorder' | translate)) }}
                </p>
            </button>

            <button (click)="onAddToCart()" class="btn-theme to-bounce"
                *ngIf=" selectedVariantDetails &&  (selectedVariantDetails.disponibility == 0 || selectedVariantDetails.disponibility == 1)">
                <span class="f-size-16px font-weight-semibold m-auto d-flex ">
                    <ng-container *ngIf="addToCart">
                        <span>
                            {{ (cartFacade.getReplaceProductInCartState() | async)?.loading ? ('loading' | translate) +
                            '...' :
                            (selectedVariantDetails.disponibility == 0 ? ('addToCart' | translate) : ('preorder' |
                            translate)) }}
                        </span>
                        <span class="ml-2 k-basket bounce-item font-weight-normal f-size-20px my-auto"></span>
                    </ng-container>
                    <ng-container *ngIf="!addToCart">
                        <span>
                            {{ (selectedVariantDetails.disponibility == 0 ? ('validate' | translate) : ('preorder' | translate)) }}
                        </span>
                    </ng-container>              
                </span>
            </button>
        </div>
    </div>
</div>
