import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoadAction, LoadingState } from "ngrx-loading-state";
import { Observable } from "rxjs";
import { JsonLd } from "../../models/jsonld.model";
import { Tab } from "../../models/tab.model";

import * as SeoActions from './seo.actions';
import * as SeoSelectors from './seo.selectors';


@Injectable({ providedIn: 'root' })
export class SeoFacade {
  constructor(private store: Store) { }

  ////// Tab

  fetchTab(options: {tabId:number} & LoadAction): void {
    this.store.dispatch(SeoActions.fetchTab.load(options));
  }


  getTab(): Observable<Tab | null> {
    return this.store.select(SeoSelectors.selectTab);
  }

  getFetchTabState(): Observable<LoadingState> {
    return this.store.select(SeoSelectors.fetchTabStateSelectors.state);
  }



  ///// jsonld


  fetchJsonLd(options: { t:string ,id?:number|undefined} & LoadAction): void {
    this.store.dispatch(SeoActions.fetchJsonLd.load(options));
  }


  getJsonLd(): Observable<JsonLd | null> {
    return this.store.select(SeoSelectors.selectJsonLd);
  }

  getFetchJsonLdState(): Observable<LoadingState> {
    return this.store.select(SeoSelectors.fetchJsonLdStateSelectors.state);
  }


}