<div class="col-12 px-0 row mx-0 bg-white" *ngIf="product">
  <div class="col-12 bloc-img px-0">
    <!-- <img [src]="product.pictureSmallUrl" alt="" width="100%"> -->
    <!-- <ng-image-slider [images]="productPictures"
  [imageSize]="{width:'100%', height:'140px'}"
  [manageImageRatio]="true"
  id="slider-{{product.productId}}"
  #nav></ng-image-slider> -->

  
  <div class="w-100 scrollable-image-container" *ngIf="mobileQuery.matches" [class.opacity-60]="product.disponibility == 2">
    <img *ngFor="let item of carouselItems" (click)="onClickImage()" 
    [src]="item.pictureUrlMedium" [alt]="product.designationDynamic" [title]="product.designationDynamic" class="img-carousel">
  </div>

    <!-- BLOC IMAGE PRODUIT -->
    <ngu-carousel #myCarouselImagesItem [inputs]="carouselConfigProductImages" [dataSource]="carouselItems"
      class="w-100 h-100" [class.opacity-60]="product.disponibility == 2"
      *ngIf="!mobileQuery.matches">

      <div *nguCarouselDef="let item;" class="item">
        <a *ngIf="redirect" (click)="scrollToService.scrollToTop()" [routerLink]="[ product.linkProduct ]"
        [queryParams]="{'returnUrl':returnUrl}"
          class="d-flex justify-content-center h-100">
          <img [src]="item.pictureUrlMedium" [alt]="product.designationDynamic" [title]="product.designationDynamic"
            class="img-carousel " >
        </a>
        <a *ngIf="!redirect" (click)="onClickProduct();"
          class="d-flex justify-content-center h-100">
          <img [src]="item.pictureUrlMedium" [alt]="product.designationDynamic" [title]="product.designationDynamic"
            class="img-carousel " >
        </a>
      </div>

      <button *ngIf="carouselNb > 1 && !myCarouselImagesItem.isLast" NguCarouselNext
        class="rightRs btn-carousel position-absolute bg-black border-0 px-0">
        <span class="k-chevron-right f-size-22px text-white m-auto"></span>
      </button>

      <button *ngIf="carouselNb > 1 && myCarouselImagesItem.isLast" NguCarouselNext
        class="rightRs btn-carousel position-absolute bg-black border-0 px-0 opacity-0">
        <span class="k-chevron-right f-size-22px text-white m-auto"></span>
      </button>

      <button *ngIf="carouselNb > 1 && !myCarouselImagesItem.isFirst" NguCarouselPrev
        class="leftRs btn-carousel position-absolute bg-black border-0 px-0">
        <span class="k-chevron-left f-size-22px text-white m-auto"></span>
      </button>

      <button *ngIf="carouselNb > 1 && myCarouselImagesItem.isFirst" NguCarouselPrev
        class="leftRs btn-carousel position-absolute bg-black border-0 px-0 opacity-0">
        <span class="k-chevron-left f-size-22px text-white m-auto"></span>
      </button>

      <div class="myPoint row px-0 mx-0 position-absolute w-100" NguCarouselPoint>
        <div *ngFor="let j of myCarouselImagesItem.pointNumbers; let i = index"
          [class.active]="i==myCarouselImagesItem.activePoint" (click)="myCarouselImagesItem.moveTo(i)" class="carousel"
          [ngStyle]="{'width': styleCarousel}"></div>
      </div>
    </ngu-carousel>

    <!-- <div class="keen-slider" #sliderRef>
      <div class="keen-slider__slide item" *ngFor="let item of carouselItems; let idx = index">
        <a *ngIf="redirect" (click)="scrollToService.scrollToTop()" [routerLink]="[ product.linkProduct ]"
        [queryParams]="{'returnUrl':returnUrl}">
        <img [src]="item.pictureUrlLarge" [alt]="item.designation" [title]="item.designation"
          class="img-carousel w-100" >
        </a>
        <a *ngIf="!redirect" (click)=" onClickProduct();">
          <img [src]="item.pictureUrlLarge" [alt]="item.designation" [title]="item.designation"
          class="img-carousel w-100" >
        </a>
      </div>
    </div> -->

    <button *ngIf="slider && currentSlide < slider.track.details.slides.length - 1" (click)="slider.next()"
      class="rightRs btn-carousel position-absolute bg-black border-0 px-0">
      <span class="k-chevron-right f-size-22px text-white m-auto"></span>
    </button>

    <button  *ngIf="slider && currentSlide >= slider.track.details.slides.length - 1" 
      class="rightRs btn-carousel position-absolute bg-black border-0 px-0 opacity-0">
      <span class="k-chevron-right f-size-22px text-white m-auto"></span>
    </button>

    <button *ngIf="slider && currentSlide > 0" (click)="slider.prev()"
      class="leftRs btn-carousel position-absolute bg-black border-0 px-0">
      <span class="k-chevron-left f-size-22px text-white m-auto"></span>
    </button>

    <button *ngIf="slider && currentSlide == 0" 
      class="leftRs btn-carousel position-absolute bg-black border-0 px-0 opacity-0">
      <span class="k-chevron-left f-size-22px text-white m-auto"></span>
    </button>
<!-- 
    <svg *ngIf="slider"  [ngClass]="
'arrow arrow--left ' + (currentSlide === 0 ? 'arrow--disabled' : '')
" (click)="slider.prev()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
    <svg *ngIf="slider" [ngClass]="
'arrow arrow--right ' +
(slider.track.details.slides.length - 1 === currentSlide
  ? 'arrow--disabled'
  : '')
" (click)="slider.next()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg> -->
  <!-- <div class="dots w-100 d-flex justify-content-center" *ngIf="slider" >
    <button  (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
      [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
  </div> -->

    <!-- TAGS -->
    <div class="tags position-absolute tag-promo">
      <!-- TAG NEW -->
      <p class="bg-dark pastilles px-3 rounded d-flex w-fit-content"
         *ngIf="currentPill == 'new'" [@fade-pill]="'in'">
        <span class="my-auto text-white">
          {{'new' | translate }}
        </span>
      </p>
      <!-- TAG CHRONO-->
      <p class="bg-main-gradient text-black pastilles px-3 rounded d-flex w-fit-content"
        *ngIf="promoCounter && product.disponibility != 2 && currentPill == 'promo-counter'" [@fade-pill]="'in'">
        <span class="my-auto mr-1 k-clock f-size-md-18px f-size-20px mb--1px mt--1px"></span>
        <span class="my-auto">
          {{promoCounter}}
        </span>
      </p>
      <!-- TAG INDISPO -->
      <p class="bg-danger text-white pastilles px-3 rounded d-flex w-fit-content"
         *ngIf="product.disponibility == 2 && currentPill == 'unavailable'">
        <span class="my-auto text-white">
          {{'bientotDisponible' | translate}}
        </span>
        
      </p>
      <!-- TAGS EXPRESS DELIVERY : TODAY & TOMORROW & MORE -->
      <p class="bg-grey-dark pastilles px-3 rounded d-flex"
        [@fade-pill]="'in'" *ngIf="currentPill == 'shipping-info'">
        <span class="k-truck mr-1 my-auto text-white f-size-18px mb--1px mt--1px"></span>
        <span class="my-auto font-weight-bold font-italic text-white">
          {{'expedition' | translate }}
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 1">{{'today-short' | translate }}</span>
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 2">{{'tomorrow' | translate }}</span>
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 3">{{'afterTomorrow' | translate }}</span>
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 4">{{'monday' | translate }}</span>
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 5">{{'tuesday' | translate }}</span>
          <!--<span class=" f-size-9px f-size-md-12px font-weight-bold font-italic text-white">{{'wednesday' | translate }}</span>-->
          <!--<span class=" f-size-9px f-size-md-12px font-weight-bold font-italic text-white">{{'thursday' | translate }}</span>-->
          <!--<span class=" f-size-9px f-size-md-12px font-weight-bold font-italic text-white">{{'friday' | translate }}</span>-->
        </span>
      </p>
    </div>
  </div>

  <div class="col-12 mt-0 bloc-text bg-white mh-100 h-39" [class.opacity-60]="product.disponibility == 2">
    <!-- BLOC PRICE -->
    <a class="row align-items-center w-100 px-0 mx-0 mb-0 text-decoration-none pt-2" *ngIf="redirect"
     [routerLink]="[ product.linkProduct ]" [queryParams]="{'returnUrl':returnUrl}">
      <span class="px-0 mx-0 w-fit-content">
        <!-- PRODUCT PRICE -->
        <div *ngIf="typeClient" class="row px-0 mx-0">
          <span *ngIf="!product.containerHasAllSamePrice && product.typeId == 9" class="px-0 mr-1">
            <span class="f-size-12px line-height-1">{{'from' | translate}} </span>
          </span>
          <span class="f-size-20px font-weight-bold my-auto px-0 line-height-1"
            [innerHTML]="product.priceValueTtc | formatPrice" [class.text-danger]="product.percentOff > 0"></span>
          <!-- PRODUCT PRICE REDUCTION -->
          <span class="position-relative f-size-12px mx-1 my-auto px-0 d-block text-decoration-linethrough line-height-1"
            *ngIf="product.percentOff > 0 && typeClient && !mobileQuery.matches" [innerHTML]="product.priceValueTtcOff | formatPrice"></span>
          <!-- REDUCTION -->
          <span
            class="f-size-12px font-weight-semibold rounded text-center px-1 mx-2 my-auto border-1-5px border-grey"
            *ngIf="product.percentOff > 0 && typeClient">-{{product.percentOff}}%</span>
        </div>
        <div *ngIf="typeClient && mobileQuery.matches && product.percentOff > 0" class="row px-0 mx-0">
          <span class="position-relative f-size-12px my-auto px-0 d-block text-decoration-linethrough line-height-1"
            [innerHTML]="product.priceValueTtcOff | formatPrice"></span>
        </div>
      </span>
      
      <!-- <span class="d-flex col-4 px-0 justify-content-start">
      </span> -->
    </a>

    <a class="d-flex justify-content-between col-12 row px-0 mx-0 mb-0 text-decoration-none pt-2" *ngIf="!redirect" (click)="onClickProduct()">
      <span class="px-0 mx-0 w-fit-content">
        <!-- PRODUCT PRICE -->
        <div *ngIf="typeClient" class="row px-0 mx-0">
          <span *ngIf="!product.containerHasAllSamePrice && product.typeId == 9" class="mb-1 mt-auto px-0 line-height-1 mr-1">
            <span class="f-size-12px line-height-1 mr-2">{{'from' | translate}}</span>
          </span>
          <span class="f-size-20px font-weight-bold my-auto px-0 line-height-1"
            [innerHTML]="product.priceValueTtc | formatPrice" [class.text-danger]="product.percentOff > 0"></span>
          <!-- PRODUCT PRICE REDUCTION -->
          <span class="position-relative f-size-md-14px f-size-12px mx-1 my-auto px-0 d-block text-decoration-linethrough line-height-1"
            *ngIf="product.percentOff > 0 && typeClient && !mobileQuery.matches" [innerHTML]="product.priceValueTtcOff | formatPrice"></span>
          <!-- REDUCTION -->
          <span
            class="f-size-12px font-weight-semibold rounded text-center px-1 mx-2 my-auto border-1-5px border-grey"
            *ngIf="product.percentOff > 0 && typeClient">-{{product.percentOff}}%</span>
        </div>
        <div *ngIf="typeClient && mobileQuery.matches && product.percentOff > 0" class="row px-0 mx-0">
          <span class="position-relative f-size-12px my-auto px-0 d-block text-decoration-linethrough line-height-1"
            [innerHTML]="product.priceValueTtcOff | formatPrice"></span>
        </div>
      </span>
      
      <!-- <span class="d-flex col-4 px-0 justify-content-start">
      </span> -->
    </a>

    <!-- REVIEWS -->
    <a class="col-12 d-flex px-0 mt-auto text-decoration-none" *ngIf="redirect && product.nbReviews > 0" 
      [routerLink]="[ product.linkProduct ]" [queryParams]="{'returnUrl':returnUrl}">
      <mat-icon class="f-size-16px mx-0 rep-star" *ngFor="let i of [1,2,3,4,5]"
        [class.text-main-gradient]="ratingService.starFillType(i, product.averageRating) == 0 || ratingService.starFillType(i, product.averageRating) == 1"
        [class.text-grey]="ratingService.starFillType(i, product.averageRating) == 2"></mat-icon>
      <span class="ml-2  f-size-13px">
        <span>{{product.nbReviews}} {{'opinion' | translate}}</span>
        <!-- <span class="avis-full">{{product.nbReviews}} {{'opinion' | translate}}</span>
        <span class="avis-tronc">({{product.nbReviews}})</span> -->
      </span>
    </a>

    <a class="col-12 d-flex px-0 mt-auto text-decoration-none" *ngIf="!redirect && product.nbReviews > 0" (click)="onClickProduct()">
      <mat-icon class="f-size-16px mx-0 rep-star" *ngFor="let i of [1,2,3,4,5]"
                [class.text-main-gradient]="ratingService.starFillType(i, product.averageRating) == 0 || ratingService.starFillType(i, product.averageRating) == 1"
                [class.text-grey]="ratingService.starFillType(i, product.averageRating) == 2"></mat-icon>
      <span class="ml-2  f-size-13px">
        <span>{{product.nbReviews}} {{'opinion' | translate}}</span>
        <!-- <span class="avis-full">{{product.nbReviews}} {{'opinion' | translate}}</span>
        <span class="avis-tronc">({{product.nbReviews}})</span> -->
      </span>
    </a>

    <!-- DESIGNATION -->
    <div [class.col-9]="!typeClient && showInputQuantity" [class.col-12]="!(!typeClient && showInputQuantity)"
      class="px-0 pb-0 pt-1">
      <p class="mb-0 description">
        <a *ngIf="redirect && product.designationDynamic" (click)="scrollToService.scrollToTop()"
          [routerLink]="[ product.linkProduct ]" [queryParams]="{'returnUrl':returnUrl}" class="text-black text-decoration-none"
          [class.f-size-16px]="!mobileQuery.matches" [class.f-size-13px]="mobileQuery.matches">
          {{ product.designationDynamic}}
        </a>
        <a *ngIf="!redirect && product.designationDynamic" (click)=" onClickProduct();"
          class="text-black text-decoration-none" [class.f-size-16px]="!mobileQuery.matches"
          [class.f-size-13px]="mobileQuery.matches">
          {{ product.designationDynamic }}
        </a>
      </p>
    </div>
  </div>
</div>

<!-- Loader -->
<div class="col-12 px-0 row mx-0 bg-white content-loader" *ngIf="!product">
  <div class="col-12 bloc-img px-0">
    <ngu-carousel #myCarouselImagesItem [inputs]="carouselConfigProductImages" [dataSource]="[1]"
      class="w-100 h-100 bg-grey">
      <div *nguCarouselDef="let item;" class="item">
        <a class="d-flex justify-content-center h-100 minh-210px">
          <div class="w-100 h-100">&nbsp;</div>
        </a>
      </div>
    </ngu-carousel>

    <!-- TAG  -->
    <div class=" position-absolute t-15px l-15px z-index-1">
      <!-- TAG  -->
      <p
        class="bg-grey-light text-white font-weight-bold font-style-italic px-1 f-size-9px f-size-md-12px rounded d-flex w-fit-content pr-30px">
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      </p>

    </div>

  </div>
  <div class="col-12 row mx-0 pb-1 bloc-text bg-white mh-100 h-39">
    <!-- DESIGNATION -->
    <div class="col-12 px-0 pt-2 pb-0 bg-grey-light">
      <p class="mb-0 description">
        <a class="text-black text-decoration-none f-size-16px">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </a>
      </p>
    </div>

  </div>

</div>
