import { createLoadingActions, failure, load, success } from "ngrx-loading-state";
import { DoofinderResult } from "src/app/models/doofinder-result.model";

import {Error} from '../../models/results/base-result.model';

export const search = createLoadingActions(
    '[Doofinder] Search',
    load<{query:string,filterBy?:string,rpp?:number, query_name?:string, grouping?:boolean, page?:number, sortBy?:string}>(),
    success<{results:DoofinderResult}>(),
    failure<{ errors: Error[] | undefined }>()
);
