import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ImageViewerFacade } from './image-viewer.facade';
import { IMAGE_VIEWER_FEATURE_KEY,imageViewerReducer } from './image-viewer.reducer';


@NgModule({
  declarations: [
  
  ],
  imports: [
    StoreModule.forFeature(IMAGE_VIEWER_FEATURE_KEY, imageViewerReducer),
],
providers: [ImageViewerFacade],
})
export class ImageViewerStoreModule { }
