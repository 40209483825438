
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadAction, LoadingState } from 'ngrx-loading-state';
import { Observable } from 'rxjs';
import {  ReviewsResult } from 'src/app/models/review.model';
import { MicroFiche } from 'src/app/models/microfiche.model';
import { Attribut } from '../../models/attribut.model';
import { Brand } from '../../models/brand.model';
import { Category } from '../../models/category.model';
import { Product, ProductItem } from '../../models/product.model';

import * as CatalogActions from './catalog.actions';
import * as CatalogSelectors from './catalog.selectors';
import { ProductFilter } from 'src/app/models/product-filter.model';
import { CrossSellGroup } from 'src/app/models/cross-sell.model';
import { Carrier } from 'src/app/models/carrier.model';


@Injectable({ providedIn: 'root' })
export class CatalogFacade {
  constructor(private store: Store) { }


  
  fetchAttributs(options: LoadAction): void {
    this.store.dispatch(CatalogActions.fetchAttributs.load(options));
  }


  getAttributs(): Observable<Attribut[] | null> {
    return this.store.select(CatalogSelectors.attributsSelectors.selectAll);
  }

  getFetchAttributsState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchAttributsStateSelectors.state);
  }


  //////////

  
  
  fetchProductFilters(options: LoadAction): void {
    this.store.dispatch(CatalogActions.fetchProductFilters.load(options));
  }


  getProductFilters(): Observable<ProductFilter[] | null> {
    return this.store.select(CatalogSelectors.productFiltersSelectors.selectAll);
  }

  getFetchProductFilters(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchProductFiltersStateSelectors.state);
  }


  //////////

  
  fetchCategories(options: LoadAction): void {
    this.store.dispatch(CatalogActions.fetchCategories.load(options));
  }


  getCategories(): Observable<Category[] | null> {
    return this.store.select(CatalogSelectors.categoriesSelectors.selectAll);
  }

  getFetchCategoriesState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchCategoriesStateSelectors.state);
  }

  
  //////////

  
  fetchCategoriesHome(options: LoadAction): void {
    this.store.dispatch(CatalogActions.fetchCategoriesHome.load(options));
  }


  getCategoriesHome(): Observable<Category[] | null> {
    return this.store.select(CatalogSelectors.categoriesHomeSelectors.selectAll);
  }

  getFetchCategoriesHomeState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchCategoriesHomeStateSelectors.state);
  }

  
  //////////

  
  fetchCategoriesSameParent(options: {categoryId:number, random?:number} & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchCategoriesSameParent.load(options));
  }


  getCategoriesSameParent(): Observable<Category[] | null> {
    return this.store.select(CatalogSelectors.categoriesSameParentSelectors.selectAll);
  }

  getFetchCategoriesSameParentState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchCategoriesSameParentStateSelectors.state);
  }

  //////////

   
  fetchBrands(options: LoadAction): void {
    this.store.dispatch(CatalogActions.fetchBrands.load(options));
  }


  getBrands(): Observable<Brand[] | null> {
    return this.store.select(CatalogSelectors.brandsSelectors.selectAll);
  }

  getFetchBrandsState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchBrandsStateSelectors.state);
  }
  //////////

   
  fetchBrand(options: {brandId:number} & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchBrand.load(options));
  }


  getBrand(): Observable<Brand | null> {
    return this.store.select(CatalogSelectors.selectBrand);
  }

  getFetchBrandState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchBrandStateSelectors.state);
  }

  clearbrand(options: LoadAction): void {
    this.store.dispatch(CatalogActions.clearBrand.load(options));
  }


  
  //////////

   
  fetchCarriersByProduct(options: {productId:number, country?:string, zipCode?:string} & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchCarriersByProduct.load(options));
  }

  clearCarriersByProduct(options: LoadAction): void {
    this.store.dispatch(CatalogActions.clearCarriersByProduct.load(options));
  }



  getCarriersByProduct(): Observable<Carrier[] | null> {
    return this.store.select(CatalogSelectors.carriersByProductSelectors.selectAll);
  }

  getCarriersByProductCountry(): Observable<string | null> {
    return this.store.select(CatalogSelectors.selectCarriersByProductCountry);
  }

  getCarriersByProductZipCode(): Observable<string | null> {
    return this.store.select(CatalogSelectors.selectCarriersByProductZipCode);
  }

  getFetchCarriersByProductState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchCarriersByProductStateSelectors.state);
  }

  
  //////////

   
  fetchProducts(options: {categoriesIds?:number[], brandId?:number, page?:number, pageSize?:number,nbColumns?:number, filters?:ProductFilter[], sortingEnumId?:number,attributsValues?: { [key: number]: any}, search?:string}  & LoadAction): void {
    // console.log('fetchProducts',options)
    this.store.dispatch(CatalogActions.fetchProducts.load(options));
  }


  getProducts(): Observable<ProductItem[] | null> {
    return this.store.select(CatalogSelectors.selectProducts);
  }

  getTotalProducts(): Observable<number | null> {
    return this.store.select(CatalogSelectors.selectTotalProducts);
  }

  getTotalPages(): Observable<number | null> {
    return this.store.select(CatalogSelectors.selectTotalPages);
  }

  getPageNumber(): Observable<number | null> {
    return this.store.select(CatalogSelectors.selectPageNumber);
  }

  getSortEnumId(): Observable<number | null> {
    return this.store.select(CatalogSelectors.selectSortEnumId);
  }


  getFetchProductsState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchProductsStateSelectors.state);
  }


  
  //////////

   
  fetchProductsCrossSell(options: {categoriesIds?:number[], page?:number, pageSize?:number,nbColumns?:number, 
    filters?:ProductFilter[], sortingEnumId?:number, attributsValues?: { [key: number]: any},
  productForCrossSell:number } & LoadAction): void {
    // console.log(options)
    this.store.dispatch(CatalogActions.fetchProductsForCrossSell.load(options));
  }

  clearProductsCrossSell(options: LoadAction): void {
    this.store.dispatch(CatalogActions.clearProductsForCrossSell.load(options));
  }

  

  getProductsCrossSell(): Observable<ProductItem[] | null> {
    return this.store.select(CatalogSelectors.selectProductsCrossSell);
  }

  getProductFiltersCrossSell(): Observable<ProductFilter[] | null> {
    return this.store.select(CatalogSelectors.productFiltersCrossSellSelectors.selectAll);
  }


  getTotalProductsCrossSell(): Observable<number | null> {
    return this.store.select(CatalogSelectors.selectTotalProductsCrossSell);
  }

  getTotalPagesCrossSell(): Observable<number | null> {
    return this.store.select(CatalogSelectors.selectTotalPagesCrossSell);
  }

  getPageNumberCrossSell(): Observable<number | null> {
    return this.store.select(CatalogSelectors.selectPageNumberCrossSell);
  }


  getFetchProductsCrossSellState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchProductsCrossSellStateSelectors.state);
  }

  //////////

  
  fetchProductReviews(options: {productId:number,count?:number } & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchProductReviews.load(options));
  }


  getProductReviews(): Observable<ReviewsResult | null> {
    return this.store.select(CatalogSelectors.selectProductReviews);
  }

  getFetchProductReviewsState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchProductReviewsSelectors.state);
  }

  
  //////////

  
  fetchCrossSellGroups(options: {categoryId:number } & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchCrossSellGroups.load(options));
  }


  getCrossSellGroups(): Observable<CrossSellGroup[] | null> {
    return this.store.select(CatalogSelectors.crossSellGroupsSelectors.selectAll);
  }

  getFetchCrossSellGroupsState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchCrossSellGroupsSelectors.state);
  }

  //////////

   
  fetchRelatedProducts(options: {productId:number, count:number}  & LoadAction): void {
    // console.log(options.productId);
    this.store.dispatch(CatalogActions.fetchRelatedProducts.load(options));
  }


  getRelatedProducts(): Observable<ProductItem[] | null> {
    return this.store.select(CatalogSelectors.relatedProductsSelectors.selectAll);
  }

  getFetchRelatedProductsState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchRelatedProductsStateSelectors.state);
  }

  
  
  //////////

   
  fetchProductsBestSeller(options: { count:number, withCategory:boolean, categoryId?:number}  & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchProductsBestSeller.load(options));
  }


  getProductsBestSeller(): Observable<ProductItem[] | null> {
    return this.store.select(CatalogSelectors.productsBestSellerSelectors.selectAll);
  }

  getFetchProductsBestSellerState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchProductsBestSellerStateSelectors.state);
  }

  
  //////////

   
  fetchProduct(options: {productId:number}  & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchProduct.load(options));
  }


  getProduct(): Observable<Product | null> {
    return this.store.select(CatalogSelectors.selectProduct);
  }

  getFetchProductState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchProductStateSelectors.state);
  }

  
  
  //////////

   
  fetchProductForCrossSell(options: {productId:number,calcDiscountCrossSell:boolean,documentRowId?:number, quantityRequested?:number}  & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchProductForCrossSell.load(options));
  }


  getProductCrossSell(): Observable<Product | null> {
    return this.store.select(CatalogSelectors.selectProductCrossSell);
  }

  getFetchProductForCrossSellState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchProductForCrossSellStateSelectors.state);
  }


  
  //////////

   
  fetchCategory(options: {categoryId:number}  & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchCategory.load(options));
  }


  getCategory(): Observable<Category | null> {
    return this.store.select(CatalogSelectors.selectCategory);
  }

  getFetchCategoryState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchCategoryStateSelectors.state);
  }

  clearCategory(options: LoadAction): void {
    this.store.dispatch(CatalogActions.clearCategory.load(options));
  }

  
  //////////

   
  fetchMicroFiches(options: {categoryId:number}  & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchMicroFiches.load(options));
  }


  getMicroFiches(): Observable<MicroFiche[] | null> {
    return this.store.select(CatalogSelectors.microFichesSelectors.selectAll);
  }

  getFetchMicroFichesState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchMicrofichesStateSelectors.state);
  }


  
  //////////

   
  fetchProductForModify(options: {productId:number}  & LoadAction): void {
    this.store.dispatch(CatalogActions.fetchProductForModify.load(options));
  }


  getProductForModify(): Observable<Product | null> {
    return this.store.select(CatalogSelectors.selectProductEdit);
  }

  getFetchProductForModifyState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.fetchProductForModifyStateSelectors.state);
  }


  //////////

   
  notifyStock(options: {email:string, productId:number}  & LoadAction): void {
    this.store.dispatch(CatalogActions.notifyStock.load(options));
  }


  getResultNotifyStock(): Observable<boolean | null> {
    return this.store.select(CatalogSelectors.selectResultNotifyStock);
  }

  getNotifyStockState(): Observable<LoadingState> {
    return this.store.select(CatalogSelectors.notifyStockStateSelectors.state);
  }

}