import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions,createEffect, ofType } from "@ngrx/effects";
import {  map, mergeMap } from 'rxjs/operators'

import * as SeoActions from './seo.actions';

import { BaseResult } from "../../models/results/base-result.model";
import { environment } from "../../../environments/environment";

@Injectable()
export class SeoEffects {

    fetchTab$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SeoActions.fetchTab.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'tab/get-tab',
                {
                    params:new HttpParams().append('tabId', options.tabId.toString())
                }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SeoActions.fetchTab.failure({ errors: result.errors , error: 'error'});

                                return SeoActions.fetchTab.success(result.entity);
                        })
                    )
            }),
            SeoActions.fetchTab.catchError()
        )
    });

    fetchJsonLd$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SeoActions.fetchJsonLd.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'tab/get-jsonld',
                {
                    params:new HttpParams().append('id', options.id!.toString())
                                        .append('type', options.t)
                }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SeoActions.fetchJsonLd.failure({ errors: result.errors , error: 'error'});

                            return SeoActions.fetchJsonLd.success(result.entity);
                        })
                    )
            }),
            SeoActions.fetchJsonLd.catchError()
        )
    });

     ////////////////

     constructor(
        private actions$: Actions,
        private http: HttpClient,
    ) {
      
    }
}