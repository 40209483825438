import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoadAction, LoadingState } from "ngrx-loading-state";
import { Observable } from "rxjs";

import * as ImageViewerActions from './image-viewer.actions';
import * as ImageViewerSelectors from './image-viewer.selectors';


@Injectable({ providedIn: 'root' })
export class ImageViewerFacade {
  constructor(private store: Store) { }

  ////// Tab

  opened(options: {opened:boolean} & LoadAction): void {
    this.store.dispatch(ImageViewerActions.imageViewerOpened.load(options));
  }


  getOpened(): Observable<boolean | null> {
    return this.store.select(ImageViewerSelectors.selectOpened);
  }


}