
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  createLoadingStatesSelector } from 'ngrx-loading-state';
import * as SharedActions from './shared.actions';
import * as SharedReducer from './shared.reducer';


const selectState = createFeatureSelector<SharedReducer.SharedState>(SharedReducer.SHARED_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);



// Website languages
//
export const selectLanguages = createSelector(
    selectState,
    (state) => state.languages
  );

  export const selectMaturities = createSelector(
    selectState,
    (state) => state.maturities
  );


  export const selectCurrentLanguage = createSelector(
    selectState,
    (state) => state.currentLanguage
  );

  
  export const selectResult = createSelector(
    selectState,
    (state) => state.result
  );

    
  export const selectVersion = createSelector(
    selectState,
    (state) => state.version
  );

  
  export const selectWebsiteReviews= createSelector(
    selectState,
    (state) => state.websiteReviews!
  );



  export const countriesSelectors = SharedReducer.adapterCountries.getSelectors(createSelector(selectState, (state) => state.countries));
  export const gatewaysSelectors = SharedReducer.adapterGateways.getSelectors(createSelector(selectState, (state) => state.gateways));
  export const settingsSelectors = SharedReducer.adpaterSettings.getSelectors(createSelector(selectState, (state) => state.settings));



export const fetchTabStateSelectors = SharedActions.fetchWebsiteLanguages.createSelectors(selectLoadingStates);
export const addToNewsletterStateSelectors = SharedActions.addToNewsletter.createSelectors(selectLoadingStates);
export const fetchCountriesStateSelectors = SharedActions.fetchCountries.createSelectors(selectLoadingStates);
export const fetchGatewaysStateSelectors = SharedActions.fetchGateways.createSelectors(selectLoadingStates);
export const fetchGatewayMaturitiesStateSelectors = SharedActions.fetchGatewayMaturities.createSelectors(selectLoadingStates);
export const fetchSettingsStateSelectors = SharedActions.fetchSettings.createSelectors(selectLoadingStates);
export const fetchWebsiteReviewsSelectors = SharedActions.fetchWebsiteReviews.createSelectors(selectLoadingStates);


// 