import { createLoadingActions, failure, load, success } from "ngrx-loading-state";

import {BaseResult, Error} from '../../models/results/base-result.model';
import { Attribut } from "../../models/attribut.model";
import { Brand } from "../../models/brand.model";
import { Category } from "../../models/category.model";
import { Product, ProductItem } from "../../models/product.model";
import {  ReviewsResult } from "src/app/models/review.model";
import { MicroFiche } from "src/app/models/microfiche.model";
import { ProductFilter } from "src/app/models/product-filter.model";
import { CrossSellGroup } from "src/app/models/cross-sell.model";
import { Carrier } from "src/app/models/carrier.model";
import { GatewayOffers } from "src/app/models/gateway-offer.model";


export const fetchAttributs = createLoadingActions(
    '[Catalog] Fetch Attributs',
    load(),
    success<{attributs:Attribut[]}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchProductFilters = createLoadingActions(
    '[Catalog] Fetch Product Filters',
    load(),
    success<{filters:ProductFilter[]}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchCategories = createLoadingActions(
    '[Catalog] Fetch Categories',
    load(),
    success<{categories:Category[]}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchCategoriesHome = createLoadingActions(
    '[Catalog] Fetch Categories Home',
    load(),
    success<{categories:Category[]}>(),
    failure<{ errors: Error[] | undefined }>()
);



export const fetchCategoriesSameParent = createLoadingActions(
    '[Catalog] Fetch Categories Same Parent',
    load<{categoryId:number, random?:number}>(),
    success<{categories:Category[]}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchCategory = createLoadingActions(
    '[Catalog] Fetch Category',
    load<{categoryId?:number}>(),
    success<{category:Category}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const clearCategory = createLoadingActions(
    '[Catalog] Clear Category',
    load(),
    success(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchMicroFiches = createLoadingActions(
    '[Catalog] Fetch MicroFiches',
    load<{categoryId?:number}>(),
    success<{microFiches:MicroFiche[]}>(),
    failure<{ errors: Error[] | undefined }>()
);




export const fetchBrands = createLoadingActions(
    '[Catalog] Fetch Brands',
    load(),
    success<{brands:Brand[]}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchBrand = createLoadingActions(
    '[Catalog] Fetch Brand',
    load<{brandId:number}>(),
    success<{brand:Brand}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const clearBrand = createLoadingActions(
    '[Catalog] Clear Brand',
    load(),
    success(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchProducts = createLoadingActions(
    '[Catalog] Fetch Products',
    load<{categoriesIds?:number[], brandId?:number, page?:number, pageSize?:number,nbColumns?:number, filters?:ProductFilter[], sortingEnumId?:number, attributsValues?: { [key: number]: number}, search?:string}>(),
    success<{result:BaseResult}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchProductsForCrossSell = createLoadingActions(
    '[Catalog] Fetch Products For Cross Sell',
    load<{categoriesIds?:number[],
         page?:number, pageSize?:number,nbColumns?:number, 
         filters?:ProductFilter[], sortingEnumId?:number, attributsValues?: { [key: number]: any},
         productForCrossSell:number
        }>(),
    success<{result:BaseResult}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const clearProductsForCrossSell = createLoadingActions(
    '[Catalog] Fetch Products For Cross Sell',
    load(),
    success<{result:BaseResult}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchRelatedProducts = createLoadingActions(
    '[Catalog] Fetch Related Products',
    load<{productId:number, count:number}>(),
    success<{products:ProductItem[]}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchProductsBestSeller = createLoadingActions(
    '[Catalog] Fetch Products Best Seller',
    load<{count:number, withCategory:boolean, categoryId?:number}>(),
    success<{products:ProductItem[]}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchProduct = createLoadingActions(
    '[Catalog] Fetch Product',
    load<{productId:number}>(),
    success<{product:Product}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchProductForCrossSell = createLoadingActions(
    '[Catalog] Fetch Product For Cross Sell',
    load<{productId:number, calcDiscountCrossSell:boolean, documentRowId?:number, quantityRequested?:number}>(),
    success<{product:Product}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchProductForModify = createLoadingActions(
    '[Catalog] Fetch Product For Modify',
    load<{productId:number}>(),
    success<{product:Product}>(),
    failure<{ errors: Error[] | undefined }>()
);



export const notifyStock = createLoadingActions(
    '[Catalog] Notify Stock',
    load<{email:string, productId:number}>(),
    success<{result:boolean}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchProductReviews = createLoadingActions(
    '[Catalog] Fetch Product Reviews',
    load<{productId:number,count?:number}>(),
    success<{reviews:ReviewsResult}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchCrossSellGroups = createLoadingActions(
    '[Catalog] Fetch CrossSell Groups',
    load<{categoryId:number}>(),
    success<{crossSellGroups:CrossSellGroup[]}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchProductsByCategories = createLoadingActions(
    '[Catalog] Fetch Products By Categories',
    load<{categoriesIds:number[], page?:number, pageSize?:number}>(),
    success<{result:BaseResult}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchCarriersByProduct = createLoadingActions(
    '[Catalog] Fetch Carriers By Product',
    load<{productId:number, country?:string, zipCode?:string}>(),
    success<{carriers:Carrier[], country?:string, zipCode?:string}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const clearCarriersByProduct = createLoadingActions(
    '[Catalog] Clear Carriers By Product',
    load(),
    success(),
    failure<{ errors: Error[] | undefined }>()
);




