import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoadAction, LoadingState } from "ngrx-loading-state";
import { Observable } from "rxjs";
import { ShippingAddress } from "../../models/shipping-address.model";
import { Document } from "../../models/document.model";

import * as CartActions from './cart.actions';
import * as CartSelectors from './cart.selectors';
import { ProductItem } from "../../models/product.model";
import { KeyValuePair } from "src/app/Utils/KeyValuePair.interface";


@Injectable({ providedIn: 'root' })
export class CartFacade {
  constructor(private store: Store) { }


  fetchCart(options: { setCart: boolean, calcShipping: boolean, calcDiscount: boolean, includeCarriers: boolean, includeGateways: boolean, validate: boolean } & LoadAction): void {
    this.store.dispatch(CartActions.fetchCart.load(options));
  }


  getCart(): Observable<Document | null> {
    return this.store.select(CartSelectors.selectCart);
  }

  getFetchCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.fetchCartStateSelectors.state);
  }


  /////////


  loadCart(options: { key: string } & LoadAction): void {
    this.store.dispatch(CartActions.loadCart.load(options));
  }


  getLoadCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.loadCartStateSelectors.state);
  }

  /////////


  updateCart(options: { carrierId: number } & LoadAction): void {
    this.store.dispatch(CartActions.updateCart.load(options));
  }


  getUpdateCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.updateCartStateSelectors.state);
  }

  /////////


  deleteCart(options: { documentId: number } & LoadAction): void {
    this.store.dispatch(CartActions.deleteCart.load(options));
  }


  getDeleteCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.deleteCartStateSelectors.state);
  }



  /////////


  clearCart(options: LoadAction): void {
    this.store.dispatch(CartActions.clearCart.load(options));
  }


  getClearCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.clearCartStateSelectors.state);
  }



  /////////


  addToCart(options: {quantity: number,  productId?: number, validateCart?: boolean, productIdCrossSellId?: KeyValuePair, documentRowParentId?: number } & LoadAction): void {
    this.store.dispatch(CartActions.addToCart.load(options));
  }


  getAddToCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.addToCartStateSelectors.state);
  }


  /////////


  addRemoveFromCart(options: { documentRowId: number, quantity: number, resetQuantity?: boolean, validateCart?: boolean } & LoadAction): void {
    this.store.dispatch(CartActions.addRemoveFromCart.load(options));
  }


  getAddRemoveFromCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.addRemoveFromCartStateSelectors.state);
  }


  /////////


  addCommentToCart(options: { comment: string, documentId: number } & LoadAction): void {
    this.store.dispatch(CartActions.addCommentToCart.load(options));
  }


  getAddCommentToCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.addCommentToCartStateSelectors.state);
  }



  /////////


  addCommentToRowCart(options: { comment: string, documentRowId: number } & LoadAction): void {
    this.store.dispatch(CartActions.addCommentToRowCart.load(options));
  }


  getAddCommentToRowCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.addCommentToRowCartStateSelectors.state);
  }

  /////////


  addCouponToCart(options: { code: string } & LoadAction): void {
    this.store.dispatch(CartActions.addCouponToCart.load(options));
  }


  getAddCouponToCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.addCouponToCartStateSelectors.state);
  }


  /////////


  setShippingAddress(options: { shippingAddressId: number } & LoadAction): void {
    this.store.dispatch(CartActions.setShippingAddress.load(options));
  }


  getSetShippingAddressState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.setShippingAddressStateSelectors.state);
  }


  /////////


  fetchUserShippingAddresses(options: LoadAction): void {
    this.store.dispatch(CartActions.fetchUserAddresses.load(options));
  }

  getUserShippingAddresses(): Observable<ShippingAddress[] | null> {
    return this.store.select(CartSelectors.shippingAddressesSelectors.selectAll);
  }


  getFetchShippingAddressesState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.fetchUserAddressesStateSelectors.state);

  }
  /////////


  fetchProductsRelated(options: { count: number } & LoadAction): void {
    this.store.dispatch(CartActions.fetchProductsRelated.load(options));
  }

  getProductsRelated(): Observable<ProductItem[] | null> {
    return this.store.select(CartSelectors.productsRelatedSelectors.selectAll);
  }


  getFetchProductsRelatedState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.fetchProductsRelatedStateSelectors.state);

  }


  /////////


  replaceProductInCart(options: { oldProductId: number, newProductId: number } & LoadAction): void {
    this.store.dispatch(CartActions.replaceProductInCart.load(options));
  }

  getNewProductId(): Observable<number | null> {
    return this.store.select(CartSelectors.selectNewProductId);
  }


  getReplaceProductInCartState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.replaceProductInCartStateSelectors.state);

  }

  /////////

  openCloseDrawer(options: { value: boolean, content: { componentName: string, data: any } | null } & LoadAction): void {
    this.store.dispatch(CartActions.openCloseDrawer.load(options));
  }

  getDrawerOpened(): Observable<boolean | null> {
    return this.store.select(CartSelectors.selectDrawerOpened);
  }


  getDrawerContent(): Observable<{ componentName: string, data: any } | null> {
    return this.store.select(CartSelectors.selectDrawerContent);
  }


  /////////


  fetchPaymentForm(options: { gatewayId: number, documentId: number, shippingAddressId: number, device?: string } & LoadAction): void {
    this.store.dispatch(CartActions.fetchPaymentForm.load(options));
  }

  getPaymentForm(): Observable<{ form: string, type: string } | null> {
    return this.store.select(CartSelectors.selectPaymentForm);
  }




  getPaymentStatus(): Observable<string | null> {
    return this.store.select(CartSelectors.selectPaymentStatus);
  }


  checkMandat(options: LoadAction): void {
    this.store.dispatch(CartActions.checkMandat.load(options));
  }


  getCheckMandatState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.checkMandatSelectors.state);

  }


  getFetchPaymentFormState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.fetchPaymentFormSelectors.state);

  }



  checkoutOrder(options: { gatewayId: number, documentId: number, transactionId?: number, info?: string } & LoadAction): void {
    this.store.dispatch(CartActions.checkoutOrder.load(options));
  }

  getCheckoutOrderState(): Observable<LoadingState> {
    return this.store.select(CartSelectors.checkoutOrderSelectors.state);

  }




}