import { createReducer } from "@ngrx/store";
import { getInitialState, WithLoadingStates } from "ngrx-loading-state";

import * as ImageViewerActions from './image-viewer.actions';


export const IMAGE_VIEWER_FEATURE_KEY = 'image viewer';


export type ImageViewerState = WithLoadingStates & {
    opened:boolean,

};


export const initialState: ImageViewerState = {
    ...getInitialState(),
    opened:false
};


export const imageViewerReducer = createReducer(
    initialState,
    ...ImageViewerActions.imageViewerOpened.reducer<ImageViewerState>({
        onLoad: (state, response): ImageViewerState => {
            return {
                ...state,
                 opened: response.opened
            };
        }
    }),
    
);
