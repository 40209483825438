import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-global-loader',
  templateUrl: './dialog-global-loader.component.html',
  styleUrls: ['./dialog-global-loader.component.scss']
})
export class DialogGlobalLoaderComponent {

}
