import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoadAction } from "ngrx-loading-state";
import { Observable } from "rxjs";

import * as GlobalLoaderActions from './global-loader.actions';
import * as ContactSelectors from "./global-loader.selectors";


@Injectable({ providedIn: 'root' })
export class GlobalLoaderFacade {
  constructor(private store: Store) { }

  setLoading(options:{loading:boolean} & LoadAction): void {
    this.store.dispatch(GlobalLoaderActions.setLoading.load(options));
  }

  clearLoading(options: LoadAction): void {
    this.store.dispatch(GlobalLoaderActions.clearLoading.load(options));
  }


  getLoading(): Observable<boolean | null> {
    return this.store.select(ContactSelectors.selectLoading);
  }


}