import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SeoEffects } from './seo.effects';
import { SeoFacade } from './seo.facade';
import { SEO_FEATURE_KEY,seoReducer } from './seo.reducer';


@NgModule({
  declarations: [
  
  ],
  imports: [
    StoreModule.forFeature(SEO_FEATURE_KEY, seoReducer),
EffectsModule.forFeature([SeoEffects]),
],
providers: [SeoFacade],
})
export class SeoStoreModule { }
