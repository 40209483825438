import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CustomToastrService {
  constructor(private toastr: ToastrService) {}

  success(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    override = {
      closeButton: false,
      toastClass:'toast-success mat-elevation-z8',
      ...override,
    };
    return this.toastr.success(message, title, override);
  }

  error(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    override = {
      closeButton: false,
      toastClass:'toast-success mat-elevation-z8',
      ...override,
    };
    return this.toastr.error(message, title, override);
  }
}