import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';

import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CatalogFacade } from '../../../store/catalog/catalog.facade';
import { CartFacade } from '../../../store/cart/cart.facade';
import { Document } from '../../../models/document.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogModifyProductComponent } from '../modify-product/dialog-modify-product/dialog-modify-product.component';
import { BsModifyProductComponent } from '../modify-product/bs-modify-product/bs-modify-product.component';

import { MediaMatcher } from '@angular/cdk/layout';
import { DocumentRow } from 'src/app/models/document-row.model';

@Component({
  selector: 'app-drawer-cart-content',
  templateUrl: './drawer-cart-content.component.html',
  styleUrls: ['./drawer-cart-content.component.scss']
})
export class DrawerCartContentComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() drawerCart!: MatSidenav;
  
  loadedComponenet=false;

  cart: Document | null = null;
  hasCartProducts =false;
  priceToFreeShipping: number = 1029;
  priceLeftToFreeShippingHt!: number;
  priceLeftToFreeShippingTtc!: number;
  percentageFreeShippingTtc = 0;
  attributesToShow!: string[];

  showPack:{[id:number]: boolean} = {};

  ctrlCoupon = new FormControl(null, [Validators.required]);

  typeClient=true;
  modeEdit=false;
  
  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  subscriptions: Subscription[] = [];

  constructor(
    private cartFacade: CartFacade,
    private catalogFacade:CatalogFacade,
    private router: Router,
    private dialog: MatDialog,
    private bottomSheet:MatBottomSheet,
    private cdr:ChangeDetectorRef,
    private media:MediaMatcher,
    public translateService:TranslateService
  ) { 
    this.mobileQuery = media.matchMedia('(max-width: 1200px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    // this.cartFacade.fetchCart({ setCart: true, calcShipping: false, calcDiscount: true, includeCarriers: false, includeGateways: false, validate:false });

    this.subscriptions.push(...[
      this.cartFacade.getDrawerContent()
      .subscribe(content => {
        // if(content && content.componentName){
        //   if(content.componentName == 'EditOrderComponent' && content.data && content.data.document){
        //     this.cart = content.data.document;
        //     this.modeEdit = true;
        //   }
        // }
        // else{
        //   this.cart = null;
        //   this.modeEdit = false;
        // }
      }),

      this.cartFacade.getCart()
          .subscribe(cart => {
            this.cart = cart!;
            // console.log(this.cart)
            if (cart) {
              this.hasCartProducts = cart.numberProductRequested > 0;
              this.priceLeftToFreeShippingHt = this.priceToFreeShipping - cart.totalWoShipHt;
              this.priceLeftToFreeShippingTtc = this.priceToFreeShipping - cart.totalWoShipTtc;
    
              this.percentageFreeShippingTtc = 100 - ((this.priceLeftToFreeShippingTtc * 100) / this.priceToFreeShipping);
              if(cart.rows){

                cart.rows.filter(r => r.documentRowParentId).forEach(row => {
                  this.showPack[row.documentRowParentId] = true;
                });
              }
            }
          })
    ]);
  }

  ngAfterViewChecked(): void {
    this.loadedComponenet = true;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }  

  onGoToCheckout() {
    this.drawerCart.close();
    this.router.navigate(['/',this.translateService.currentLang, 'document']);
  }
  
  onAddRemoveFromCart(documentRowId: number, quantity: number) {
    this.cartFacade.addRemoveFromCart({ documentRowId, quantity: quantity });
  }

  onAddRemoveQuantity(rowId: number, quantity: number) {
    this.cartFacade.addRemoveFromCart({ documentRowId: rowId, quantity: quantity })
  }
  
  onApplyCoupon() {
    this.cartFacade.addCouponToCart({ code: this.ctrlCoupon.value! });
  }  
  
  onChangeQuantity(rowId: number, event: any) {
    if(! event.target.value) return;
    let quantity = event.target.value;
    if(!quantity){
     quantity = 1;
    }
    this.cartFacade.addRemoveFromCart({ documentRowId: rowId, quantity: quantity,resetQuantity:true })
   }
  
  filterAttributes(attributes: string[]) {
    return attributes.filter(x => this.attributesToShow.includes(x));
  }

  getRowChildrenForPack(rowId:number){
    return this.cart?.rows.filter(r => r.documentRowParentId == rowId && r.rowType != '10' ); //&& r.rowType != '0'
  }
  
  getRowChildrenOfPack(rowId:number){
    return this.cart?.rows.filter(r => r.documentRowParentId == rowId && r.rowType == '9' );
  }

  getRowChildrenForCrossSell(rowId:number){
    return this.cart?.rows.filter(r => r.documentRowParentId == rowId && r.rowType == '0');
  }

  onOpenDialogModifyProduct(documentRow:DocumentRow){
    if (!this.mobileQuery.matches) {
      const dialogRef = this.dialog.open(DialogModifyProductComponent, {
        width: '850px',
        maxWidth: '100vw',
        data: {documentRow:documentRow}
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else {
      const bsRef = this.bottomSheet.open(BsModifyProductComponent, {
        data: {documentRow:documentRow}
      });
    }
  }
}
