import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";



@Injectable({
  providedIn: 'root'
})
export class ScrollToService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  public scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      const top = document.getElementById('_top');
      if (top) {
        setTimeout(() => {
          top.scrollIntoView();
        }, 50);
      }
    }
  }

  public scrollTo(id: string) {
    if (isPlatformBrowser(this.platformId)) {
      const top = document.getElementById(id);
      if (top) top.scrollIntoView();
    }
  }

}