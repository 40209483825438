
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  createLoadingStatesSelector } from 'ngrx-loading-state';
import * as ScrollReducer from './scroll.reducer';


const selectState = createFeatureSelector<ScrollReducer.ScrollState>(ScrollReducer.SCROLL_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);


export const selectScrollValue = createSelector(
    selectState,
    (state) => state.scrollValue
  );

  