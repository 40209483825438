import { createLoadingActions, load, success, failure } from "ngrx-loading-state";
import { Gateway } from "../../models/gateway.model";
import { Setting } from "../../models/setting.model";
import { Country } from "../../models/country.model";
import {Error} from '../../models/results/base-result.model';
import { ReviewsResult } from "src/app/models/review.model";


export const fetchWebsiteLanguages = createLoadingActions(
    '[Shared] Fetch Website Languages',
    load(),
    success<{languages: string[]}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const setWebsiteLanguage = createLoadingActions(
    '[Shared] Set Website Language',
    load<{language:string}>(),
    success(),
    failure<{ errors: Error[] | undefined }>()
);

export const addToNewsletter = createLoadingActions(
    '[Shared] Add to NewsLetter',
    load<{email:string, recaptchaToken:string}>(),
    success<{result:string}>(),
    failure<{ errors: Error[] | undefined }>()
);
 
export const fetchCountries = createLoadingActions(
    '[Shared] Countries',
    load(),
    success<{countries: Country[]}>(),
    failure<{ errors: Error[] | undefined }>()
);
 

export const fetchSettings = createLoadingActions(
    '[Shared] Fetch Settings',
    load(),
    success<{settings: Setting[]}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchGateways = createLoadingActions(
    '[Shared] Fetch Gateways',
    load(),
    success<{gateways: Gateway[]}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const fetchGatewayMaturities = createLoadingActions(
    '[Shared] Fetch Gateway Maturities',
    load<{gatewayId:number}>(),
    success<{maturities: string[]}>(),
    failure<{ errors: Error[] | undefined }>()
);

export const setNewVersion = createLoadingActions(
    '[Shared] Set New Version',
    load<{version:number}>(),
    success(),
    failure<{ errors: Error[] | undefined }>()
);

export const clearVersion = createLoadingActions(
    '[Shared] Clear Version',
    load(),
    success(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchWebsiteReviews = createLoadingActions(
    '[Catalog] Fetch Website Reviews',
    load<{count?:number}>(),
    success<{reviews:ReviewsResult}>(),
    failure<{ errors: Error[] | undefined }>()
);