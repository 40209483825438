import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    UrlTree,
    CanActivateFn
} from '@angular/router';

import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import * as jwt_decode from 'jwt-decode';

import { AuthFacade } from '../store/authentification/auth.facade';
import { UserRole } from '../models/user.model';


@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    private tokenExpirationTimer: any;


    constructor(private router: Router,
        private authFacade: AuthFacade,
        @Inject(PLATFORM_ID) private platformId: any) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
        if (isPlatformBrowser(this.platformId)) {
            this.authFacade.autoLogin({});
        }
        const culture = route.params['languageCode'];
        const result = this.authFacade.getUser().pipe(
            take(1),
            map(user => {
                const isAuth = !!user;
                if (!isAuth) {
                    this.router.navigate(['/', culture, 'auth'], { queryParams: { returnUrl: this.router.url } });
                    return false;
                }

                if (route.data && route.data['role']) {
                    const userRole = user?.userRoles?.find(x => x.toLowerCase() === route.data['role'].toLowerCase());
                    const isInRole = route.data['role'] && route.data['role'].toLowerCase() === userRole?.toLowerCase();

                    if (!isInRole) {
                        this.router.navigate(['/', culture, 'auth'], { queryParams: { returnUrl: this.router.url } });
                        return false;
                    }
                }

                return true;
            })
        );

        return result;
    }

    setLogoutTimer(expirationDuration: number) {
        this.tokenExpirationTimer = setTimeout(() => {
            // console.log(expirationDuration / 1000);
            this.authFacade.logout({});
        }, expirationDuration);


    }

    clearLogoutTimer() {
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
            this.tokenExpirationTimer = null;
        }
    }

    isInRole(role: string): boolean {
        if(isPlatformBrowser(this.platformId)){
        const userRoles: UserRole[] = [];

        const userData: {
            username: string;
            id: string,
            token: string,
            refreshToken: string,
            expiresIn: string
        } = JSON.parse(localStorage.getItem('userData')!);

        if (!userData) return false;
        if (!userData.token) return false;
        const decodedToken: {
            exp: number,
            iat: number,
            unique_name: string,
            given_name: string,
            family_name: string,
            role: string[]

        } = jwt_decode.default(userData.token);

      if (decodedToken.role) {
        if (typeof (decodedToken.role) === 'string') {
          const userRole: UserRole = { role: decodedToken.role, granted: true };
          userRoles.push(userRole);
        } else {
          decodedToken.role.forEach(role => {
            const userRole: UserRole = { role: role, granted: true };
            userRoles.push(userRole);
          });
        }
      }
        

        if (userRoles.find(x => x.role.toLowerCase() === role.toLowerCase() && x.granted)) {
            return true;
        }
        return false;
    }
    return false;
    }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> => {
    return inject(PermissionsService).canActivate(next, state);
}
