import { createLoadingActions, failure, load, success } from "ngrx-loading-state";

import { Error } from '../../models/results/base-result.model';

export const setLoading = createLoadingActions(
    '[GlobalLoader] Set Loading',
    load<{loading:boolean}>(),
    success<{loading:boolean}>(),
    failure<{ errors: Error[] | undefined }>()
  );


  export const clearLoading = createLoadingActions(
    '[GlobalLoader] Clear Loading',
    load(),
    success(),
    failure<{ errors: Error[] | undefined }>()
  );