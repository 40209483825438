<div class="d-flex mb-3">
    <img src="assets/img/logox2.svg" alt="SmallMX" width="120"
    title="SmallMX"  class="m-auto"  loading="lazy" />
</div>

<div mat-dialog-content class="text-center">
  <p class="f-size-16px">
    {{'newSiteVersion' | translate}}
  </p>
  <p class="f-size-16px font-weight-bold">
    {{'refreshSite' | translate}}
  </p>
</div>
<div mat-dialog-actions class="row text-center justify-content-around pb-4">
    <button
    (click)="onRefresh()"
    mat-button cdkFocusInitial class="btn-hover  p-2">
        <span class="f-size-16px font-weight-semibold m-auto px-3 text-uppercase"><span class="icon-refresh-cw"></span> {{'refresh' | translate}}</span>
    </button>
</div>
