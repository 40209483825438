import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toBasicString'
})
export class ToBasicStringPipe implements PipeTransform {

  transform(text: string, ...args: unknown[]): string {
    text = text.replace(/\s/g, ''); // supprimer espace
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // supprimer accents
    text = text.replace(/[^a-zA-Z0-9]/g, ''); // supprimer caractères spéciaux
    text = text.charAt(0).toLowerCase() + text.slice(1); // minuscule sur la 1ère lettre
    return text;
  }

}
