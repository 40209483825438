import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { interval, Subscription, pairwise } from 'rxjs';
import { RatingService } from '../../../services/rating.service';
import { Document } from '../../../models/document.model';
import { ProductItem } from '../../../models/product.model';
import { CustomToastrService } from '../../../services/custom-toastr.service';
import { CartFacade } from '../../../store/cart/cart.facade';
import { MediaMatcher } from '@angular/cdk/layout';
import { ScrollToService } from 'src/app/services/scroll-to.service';
import { FormatterService } from 'src/app/services/formatter.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { BackgroundService } from 'src/app/services/background.service';
import { Picture } from 'src/app/models/picture.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-large-product',
  templateUrl: './item-large-product.component.html',
  styleUrls: ['./item-large-product.component.scss'],
  animations: [
    trigger('fade-pill', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(500)]),
      transition(':leave', animate(0, style({ opacity: 0 }))),
    ]),
  ],
})

export class ItemLargeProductComponent implements AfterViewInit {

  @Input() product!: ProductItem;
  @Input() i!: number;
  @Input() count!: number;
  @Input() typeClient!: boolean;
  @Input() showBtnAddToCart: boolean = false;
  @Input() showInputQuantity: boolean = false;
  @Output() productIdClicked = new EventEmitter<number>();
  @Input() carouselGridXS: number = 2;
  @Input() carouselGridSM: number = 2;
  @Input() carouselGridMD: number = 2;
  @Input() carouselGridLG: number = 2;
  @Input() carouselGridALL: number = 0;
  @Input() returnUrl!:string;

  @Input() redirect: boolean = true;

  quantity: number[] = [];
  currentIndex!: number;
  addingToCart: boolean[] = [];
  existInCart: boolean[] = [];
  quantityInCart: number[] = [];
  loading = false;
  cart!: Document;
  maxVariantsToShow = 4;
  firstLinkProduct!:string;

  promoCounter!: string;

  pillsToShow: string[] = [];
  currentPill = '';
  currentPillIdx = 0;

  // @ViewChild('myCarousel') myCarousel: NguCarousel;
  @Input() carouselConfigProductImages: NguCarouselConfig = {
    grid: { xs: this.carouselGridXS, sm: this.carouselGridSM, md: this.carouselGridMD, lg: this.carouselGridLG, all: this.carouselGridALL },
    load: 5,
    slide: 1,
    // interval: { timing: 300},
    loop: false,
    touch: true,
    velocity: 0,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
    // animation: 'lazy',
    speed: 150,
    point: {
      visible: true,
      hideOnSingleSlide: true
    },

  }
  carouselItems: Picture[] = [];
  carouselNb = 0;
  carouselOne = 0;
  styleCarousel = '';

  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  subscriptions: Subscription[] = [];
  constructor(
    private cdr: ChangeDetectorRef,
    private cartFacade: CartFacade,
    private toastr: CustomToastrService,
    public ratingService: RatingService,
    media: MediaMatcher,
    public scrollToService: ScrollToService,
    private formatterService: FormatterService,
    private router: Router,
    public backgroundService:BackgroundService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    
  }

  scrollLeft = 0;
  scrollPhoto(dir: string) {
    const container = document.getElementById('scrollable-container-' + this.product.productId);

    if (container) {
      this.scrollLeft = container.scrollLeft;
      if (dir == 'R')
        // container.scrollLeft += 200;
        container.scrollTo({left:container.scrollLeft+200, behavior:'smooth'});
      else {
        // container.scrollLeft -= 200;
        container.scrollTo({left:container.scrollLeft-200, behavior:'smooth'});
      }
      this.scrollLeft = container.scrollLeft;
    }
  }

  ngOnInit(): void {
    this.firstLinkProduct = this.product.linkProduct;
    if(this.product.variantsForContainer){
      const variantColor = this.product.variantsForContainer.find(x => x.attribut.attributId == environment.ATTRIBUTS.COLOR);
      if(variantColor){
        const colors = [...variantColor.attributListValues].sort((a,b) => a.designation > b.designation ? 1 : -1);
        this.firstLinkProduct = colors[0].productLink;
      }
    }
    const container = document.getElementById('scrollable-container-' + this.product.productId);
    if(container) this.scrollLeft = container.scrollLeft;
    this.carouselConfigProductImages = {
      ...this.carouselConfigProductImages,
      grid: { xs: this.carouselGridXS, sm: this.carouselGridSM, md: this.carouselGridMD, lg: this.carouselGridLG, all: this.carouselGridALL },
    }
    if (this.product) {
      let rem = 0;
      if (this.product.priceDateEndFlash) {
        rem = Math.floor((new Date(this.product.priceDateEndFlash!).getTime() - new Date().getTime()) / 1000);
        if (rem > 0) {
          this.promoCounter = this.formatterService.DateTimeToCounterString(rem);
        }
      }
      this.subscriptions.push(interval(1000)
        .subscribe(x => {
          if (this.product.priceDateEndFlash) {
            rem = Math.floor((new Date(this.product.priceDateEndFlash!).getTime() - new Date().getTime()) / 1000);
            if (rem > 0) {
              this.promoCounter = this.formatterService.DateTimeToCounterString(rem);
            }
          }
        }));

        this.product.productPictures.forEach(picture => {
        this.carouselItems.push(picture);
      });
      // if(this.carouselItems.length > 2){
      //   this.carouselConfigProductImages = {
      //     ...this.carouselConfigProductImages,
      //     grid: { xs: 2.1, sm: 2.1, md: 2.1, lg: 2.1, all: 0 },
      //   }
      // }

      if (this.carouselItems.length > 0) {
        this.carouselNb = this.carouselItems.length;
        this.styleCarousel = `calc(100%/(${this.carouselNb} - 1))`;
      }

      if (this.promoCounter) {
        this.pillsToShow.push('promo-counter');
      }

      if (this.product.isNew) {
        this.pillsToShow.push('new');
      }

      if (this.product.shippingDayInfo != 0 && this.product.disponibility == 0) {
        this.pillsToShow.push('shipping-info');
      }

      if (this.product.disponibility == 2) {
        this.pillsToShow = ['unavailable'];
      }
 
      
      // TEST With hard data
      // rem = Math.floor((new Date(2024,3,24).getTime() - new Date().getTime()) / 1000);
      // if (rem > 0) {
      //   this.promoCounter = this.formatterService.DateTimeToCounterString(rem);
      // }

      // this.pillsToShow.push('promo-counter');
      // this.pillsToShow.push('new');

      

      if (this.pillsToShow.length > 0) {
        this.currentPillIdx = 0;
        this.currentPill = this.pillsToShow[this.currentPillIdx];
      }

      if (this.mobileQuery.matches) {
        this.maxVariantsToShow = 7;
      }
      else {
        this.maxVariantsToShow = 10;
      }

      this.subscriptions.push(interval(7500).subscribe(x => {
        this.goToNextPill();
      }));

      this.subscriptions.push(...[
        this.cartFacade.getCart()
          .subscribe(cart => {
            this.cart = cart!;
            if (cart && cart.rows) {
              for (let x = 0; x < this.count; x++) {
                this.existInCart[x] = cart.rows.find(r => r.productId == this.product.productId) !== undefined;
                this.quantityInCart[x] = cart.rows.find(r => r.productId == this.product.productId)?.quantityRequested!;
              }
            }
          }),
        this.cartFacade.getAddToCartState()
          .pipe(pairwise())
          .subscribe(([prevState, nextState]) => {
            this.loading = nextState && nextState.loading;
          })
      ]);
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    // this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  onAddToCart($event: any, index: number) {
    this.loading = false;
    setTimeout(() => {
      if (!this.loading) {
        let quantity = +$event.target.value;

        this.currentIndex = index;
        if (!this.quantity[index]) this.quantity[index] = quantity;
        this.cartFacade.addToCart({ productId: this.product.productId, quantity: quantity });

        // this.toastr.success('l\'article a été ajouté au panier avec succès.','Panier');
      }
    }, 1000);
  }

  onAddOneToCart(index: number) {
    let quantity = 1;
    this.currentIndex = index;
    if (!this.quantity[index]) this.quantity[index] = quantity;
    this.cartFacade.addToCart({ productId: this.product.productId, quantity: quantity });
  }

  onAddRemoveFromDocument($event: any, resetQuantity: boolean, index: number, timeout: number) {

    if (!$event.target.value) {
      return;
    }
    let quantity = +$event.target.value;

    this.currentIndex = index;
    if (this.cart) {
      const documentRow = this.cart.rows.find(x => x.productId === this.product.productId);
      const documentRowId = documentRow?.documentRowId;
      // if(documentRow){}
      let addAllowed = this.product.forcePositiveStock || this.product.storageType === 4;
      if (!addAllowed) {
        if (quantity === 1) {
          const currentQuantity = this.cart.rows.find(x => x.documentRowId === documentRowId)?.quantityRequested;

          if (currentQuantity! + quantity <= this.product.stockValue) {
            addAllowed = true;
          }
          else {
            addAllowed = false;
          }
        }
        else {
          if (quantity <= this.product.stockValue) {
            addAllowed = true;
          }
          else {
            addAllowed = false;
          }
        }
      }
      addAllowed = true; // override check stock
      if (addAllowed) {
        setTimeout(() => {
          this.cartFacade.addRemoveFromCart({ documentRowId: documentRowId!, quantity: quantity, resetQuantity: resetQuantity });
        }, timeout);
      }
      else {
        // this.snackBar.open("La quantité demandé et plus que le stock disponbile.", "Ok", {
        //   duration: 2000
        // });
      }
    }
  }

  onClickProduct() {
    this.productIdClicked.next(this.product.productId);
  }

  onClickImage(link:string) {
    if (this.redirect) {
      this.router.navigate([link],{
        queryParams:{'returnUrl':this.returnUrl}
      });
    }
    else {
      this.onClickProduct();
    }
    this.scrollToService.scrollToTop();
  }

  goToNextPill() {
    if (this.pillsToShow.length > 0) {
      if (this.currentPillIdx == this.pillsToShow.length - 1) {
        this.currentPillIdx = 0;
      } else {
        this.currentPillIdx++;
      }
      this.currentPill = this.pillsToShow[this.currentPillIdx];
    }
  }

  showVariantBtnPlus(): boolean {
    if (this.product && this.product.variantsForContainer) {
      const variantsColor = this.product.variantsForContainer.find(v => v.attribut.attributId == 29)!;
      if (variantsColor) {
        const variantNoImage = variantsColor.attributListValues.filter(v => !v.values).length >= 1;
        const variantsMoreThanN = variantsColor.attributListValues.length > this.maxVariantsToShow;
        return variantNoImage || variantsMoreThanN;
      }
    }
    return false;
  }
}
