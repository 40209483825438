import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class FormatterService {

    public DateTimeToCounterString(t: any) {

        t = new Date(t);
        var days, hours, minutes, seconds;
        days = Math.floor(t / 86400);
        t -= days * 86400;
        hours = Math.floor(t / 3600) % 24;
        t -= hours * 3600;
        minutes = Math.floor(t / 60) % 60;
        t -= minutes * 60;
        seconds = t % 60;
    
        if (days > 15) return '';
    
        return [
          days + 'J / ',
          hours + 'H / ',
          minutes + 'M / ',
          seconds + 'S'
        ].join(' ');
      }

}

