<!-- Loader -->

<div class="col-12 px-0 row mx-0 bg-white content-loader">
  <div class="col-12 bloc-img px-0">

    <div class="w-100 h-100 bg-grey">
      <div class="item">
        <a class="d-flex justify-content-center h-100 minh-210px">
          <div class="w-100 h-100">&nbsp;</div>
        </a>
      </div>
    </div>

    <!-- TAG  -->
    <div class=" position-absolute t-15px l-15px z-index-1">
      <!-- TAG  -->
      <p class="bg-grey-light text-white font-weight-bold font-style-italic px-1 f-size-9px f-size-md-12px rounded d-flex w-fit-content pr-30px">
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      </p>

    </div>

  </div>
  <div class="col-12 row mx-0 pb-1 bloc-text bg-white mh-100 h-39">
    <!-- DESIGNATION -->
    <div class="col-12 px-0 pt-2 pb-0 bg-grey-light">
      <p class="mb-0 description">
        <a class="text-black text-decoration-none f-size-16px">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </a>
      </p>
    </div>

  </div>

</div>
