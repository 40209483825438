import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { CatalogFacade } from '../../../store/catalog/catalog.facade';
import { TranslateService } from '@ngx-translate/core';
import { SharedFacade } from '../../../store/shared/shared.facade';
import { Category } from '../../../models/category.model';
import { AuthFacade } from '../../../store/authentification/auth.facade';
import { SideNavService } from 'src/app/services/sidenav.service';

@Component({
  selector: 'app-navigation-side-content',
  templateUrl: './navigation-side-content.component.html',
  styleUrls: ['./navigation-side-content.component.scss']
})
export class NavigationSideContentComponent implements OnInit, OnDestroy {

  @Input() drawerMenu!: MatSidenav;
  isAuthenticated = false;
  categories!: Category[];
  categoriesDisplayed!: Category[];
  categoryParent!: Category | null;
  categoryParents: Category[] = [];

  themes: { id: number,class: string, name: string }[] = [
    {id: 0, class: "", name:"None"},
    {id: 1, class: "bg-event-1", name:"basic-1"},
    {id: 2, class: "bg-event-2", name:"basic-2"},
    {id: 3, class: "bg-event-soldes", name:"Soldes"},
    {id: 4, class: "bg-event-new-year", name:"new year"},
    {id: 5, class: "bg-event-chinese-year", name:"ch year"},
    {id: 6, class: "bg-event-winter", name:"winter"},
    {id: 7, class: "bg-event-spring", name:"spring"},
    {id: 8, class: "bg-event-summer", name:"summer"},
    {id: 9, class: "bg-event-back-to-school", name:"BtoSc"},
    {id: 10, class: "bg-event-halloween", name:"halloween"},
    {id: 11, class: "bg-event-black-friday", name:"B Friday"},
    {id: 12, class: "bg-event-cyber-monday", name:"C Monday"},
    {id: 13, class: "bg-event-french-days", name:"F Days"},
    {id: 14, class: "bg-event-christmas", name:"christmas"},
    {id: 15, class: "bg-grey", name:"Gris"},
    {id: 16, class: "bg-grey-light", name:"Gris clair"},
    {id: 17, class: "bg-grey-dark", name:"Gris foncé"},
    {id: 18, class: "bg-grey-custom", name:"Gris autre"},
  ];
  selectedTheme =this.themes[0];

  subscriptions: Subscription[] = [];

  languages: { language: string, code: string }[] = [];
  selectedLanguageCode = 'FR';
  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    public catalogFacade: CatalogFacade,
    private sideNavService: SideNavService,
    public translateService: TranslateService,
    private sharedFacade: SharedFacade) { }

  ngOnInit(): void {
    

    // this.catalogFacade.fetchCategories({});

    this.subscriptions.push(...[
      this.authFacade.getIsAuthenticated()
        .subscribe(isAuth => {
          this.isAuthenticated = isAuth;

        }),

      this.catalogFacade.getCategories().subscribe(categories => {
        this.categories = categories?.filter(x => x.menu)!;
        this.categoriesDisplayed = categories!;
      }),
      this.sharedFacade.getCurrentLanguage()
      .subscribe(lang => {
        if (lang)
          this.selectedLanguageCode = lang.substring(0, 2).toUpperCase()!;
      }),
      this.sharedFacade.getCurrentLanguage()
        .subscribe(lang => {
          if ((lang && lang != '')) {
            //  this.catalogFacade.fetchCategories({});
          }
        }),
        this.drawerMenu.openedChange.subscribe(opened => {
          // console.log(opened)
          // this.catalogFacade.fetchCategories({});

          if(opened){
            if (this.sideNavService.sideNavLeftCategory) {
              this.onOpenSubMenu(this.sideNavService.sideNavLeftCategory);
            }
          }
          else {
            this.sideNavService.setSidenavLeftCategory(null);
            this.categoryParent = null;
            this.categoriesDisplayed = this.categories;
          }
        })
    ]);
   
    this.languages.push({ language: 'Français', code: 'fr-fr' }); // temp 

    // this.sharedFacade.fetchWebsiteLanguages({});
  }
  onChangeTheme(id:number){
    this.selectedTheme = this.themes.find(x =>x.id == id)!;
    if(this.selectedTheme)
      localStorage.setItem('theme', this.selectedTheme.class);

  }

  onOpenSubMenu(category: Category) {
    this.categoryParent = category;
    if (category.categories && category.categories.length > 0) {
      this.categoryParents.push(category);
      this.categoriesDisplayed = category.categories.filter(x => x.menu)!;
    }
  }

  onReturn(id: number) {
    const category = this.categoryParents.find(x => x.categoryId == id);
    this.categoryParent = category!;
    if (category) {
      this.categoriesDisplayed = category.categories.filter(x => x.menu);
    } else {
      this.categoriesDisplayed = this.categories;
      this.categoryParents = [];
    }
  }
  navigate(languageCode: string) {
    const path = this.router.url.substring(7, this.router.url.length);
    const url = `/${languageCode.toLowerCase()}${path.length > 0 ? '/' : ''}${path}`
    // console.log(path, url);
    this.selectedLanguageCode = languageCode.substring(0, 2).toUpperCase();
    this.router.navigateByUrl(url);
    // window.location.href = url;

  }
  onLogout() {
    this.authFacade.logout({});
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  getCategoriesMenu(categories: Category[]){
    return categories.filter(x => x.menu);
  }

}

