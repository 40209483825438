<h4 *ngIf="data.header" class="pt-3 px-3 pb-1 f-size-18px f-size-md-20px">{{ data.header }}</h4>
<div class="px-3 pt-2 f-size-14px">
    <p *ngIf="!data.isHtml">{{ data.message }}</p>
    <p *ngIf="data.isHtml" [innerHTML]="data.message | safe:'html'"></p>
</div>
<div mat-dialog-actions class="row text-center justify-content-around">
    <button mat-button cdkFocusInitial (click)="onNoClick()"
        class="col-md-4 col-12 my-1 text-uppercase btn-outline-black cursor-pointer font-weight-semibold">
        {{data.btnNoTxt}}
    </button>
    <button mat-button [mat-dialog-close]="true" class=" col-md-4 col-12 my-1 text-uppercase btn-theme font-weight-semibold">
        {{data.btnYesTxt}}
    </button>
</div>
