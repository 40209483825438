<div mat-dialog-content>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'search' | translate}}</mat-label>
            <input matInput placeholder="Ex. Dirt Bike" formControlName="search">
        </mat-form-field>

        <button [disabled]="!form.valid" mat-button class="btn-theme w-100 h-100 font-weight-semibold">
            <span class="my-auto f-size-14px f-size-md-16px">{{'search' | translate}}</span>
        </button>
    </form>

</div>