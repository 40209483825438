<div class="fullpage" id="image-viewer" [style]="'background:' + bgColor  " (keyup)="onKeyup($event)">
    <span (click)="onClose()" class="btn-close k-x cursor-pointer"></span>

    <div class="m-auto">
        <button *ngIf="showSwitchBtns && !advancedViewer" class="btn-switch  btn-next border-0   my-auto"
            (click)="onSwitchPhoto(+1)"></button>

        <ng-container *ngIf="displayedImages  && !advancedViewer">
            <div class="d-flex  justify-content-center mt-5 " #imageRef>
                <img [src]="loadingPicture ? selectedPicture.pictureUrlSmall : selectedPicture.pictureUrlFull"
                    class="image  img-responsive" [class.bg-grey]="withBackgroundColor" (load)="onLoadPicture()"
                    [class.loadimage]="selectedPicture.pictureUrlSmall">

            </div>

            <div class="d-flex justify-content-center mt-3" #imagesRef *ngIf="displayedImages.length > 1">
                <img *ngFor="let img of displayedImages; let i = index;" class="mr-2 cursor-pointer"
                    [class.bg-grey]="withBackgroundColor" [src]="img.pictureUrlSmall" width="50" height="50"
                    [class.select-shadow]="img.pictureId == selectedPicture.pictureId" (click)="onSelectPicture(img)">
            </div>

        </ng-container>

        <ng-container *ngIf="advancedViewer">
            <!-- <ngu-carousel #myCarouselImagesItem  [inputs]="carouselConfig" [dataSource]="displayedImages"
            (keydown)="onKeydown($event)">

                <ngu-tile *nguCarouselDef="let item; let i = index; let last = last; let first = first" class="w-50 h-100vh d-flex text-center p-0"
                   (click)="myCarouselImagesItem.moveTo(i)" >


                    <img [src]="item.pictureUrlFull" class="mx-auto img-slider">
                </ngu-tile>

                <button *ngIf="showSwitchBtns && carouselNb > 1 && !myCarouselImagesItem.isLast" NguCarouselNext id="next"
                    class="rightRs btn-carousel   bg-black text-white  border-0 px-0"
                   >
                    <span class="k-chevron-right f-size-32px text-white my-auto"></span>

                </button>

                <button *ngIf="showSwitchBtns &&  carouselNb > 1 && !myCarouselImagesItem.isFirst" NguCarouselPrev
                    class="leftRs btn-carousel my-auto   bg-black text-white  border-0 px-0"
                    >
                    <span class="k-chevron-left f-size-32px text-white my-auto"></span>

                </button>

                <ul class="myPoint " NguCarouselPoint>
                    <li *ngFor="let j of myCarouselImagesItem.pointNumbers; let i = index"
                        [class.active]="i==myCarouselImagesItem.activePoint" (click)="myCarouselImagesItem.moveTo(i)">
                    </li>
                </ul>
              
            </ngu-carousel> -->

            <!-- <pinch-zoom #myPinchZoom backgroundColor="transparent" overflow="visible" [limitPan]="true"
                (touchstart)="onZoomTouchStart($event)" (pan)="onZoomTouchStart($event)"> -->

            <div class="keen-slider w-100vw" #sliderRef>
                <div class="keen-slider__slide text-center" *ngFor="let item of displayedImages; let idx = index">
                    <img [src]="loadingPicture ? item.pictureUrlFull : item.pictureUrlRaw" (load)="onLoadPicture()"
                        [alt]="item.designation" [title]="item.designation" class="img-carousel m-auto">
                </div>
            </div>
            <!-- </pinch-zoom> -->


            <!-- && slider && !slider.options.disabled && currentSlide < slider.track.details.slides.length - 1 -->
            <button tabindex="0" autofocus
                *ngIf="showSwitchBtns  && slider"
                (keydown)="onKeydown($event)" (click)="slider.next()" id="next"
                class="rightRs btn-carousel   bg-black text-white  border-0 px-0">
                <span class="k-chevron-right f-size-32px text-white my-auto"></span>

            </button>

            <!-- && slider && !slider.options.disabled  && currentSlide > 0 -->
            <button tabindex="0" autofocus
                *ngIf="showSwitchBtns && slider"
                (click)="slider.prev()" (keydown)="onKeydown($event)"
                class="leftRs btn-carousel my-auto   bg-black text-white  border-0 px-0">
                <span class="k-chevron-left f-size-32px text-white my-auto"></span>

            </button>

            <div class="dots w-100 d-flex justify-content-center" *ngIf="slider && !slider.options.disabled ">
                <button (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
                    [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
            </div>
        </ng-container>


        <button *ngIf="showSwitchBtns && !advancedViewer" class="btn-switch k-chevron-left btn-prev border-0   my-auto"
            (click)="onSwitchPhoto(-1)"></button>
    </div>
</div>

<!-- 
[class.opacity-50]="(i == 1 && myCarouselImagesItem.activePoint == 0)
        || (i == 2 && myCarouselImagesItem.activePoint == 1)
        || (i == 3 && myCarouselImagesItem.activePoint == 2)
        || (i == 4 && myCarouselImagesItem.activePoint == 3)
        || (i == 5 && myCarouselImagesItem.activePoint == 4)
        || (i == 6 && myCarouselImagesItem.activePoint == 5)
        || (i == 7 && myCarouselImagesItem.activePoint == 6)
        || (i == 8 && myCarouselImagesItem.activePoint == 7)
        || (i == 9 && myCarouselImagesItem.activePoint == 8)
        || (i == 10 && myCarouselImagesItem.activePoint == 9)
        || (i == 11 && myCarouselImagesItem.activePoint == 10)
        || (i == 12 && myCarouselImagesItem.activePoint == 11)
        || (i == 13 && myCarouselImagesItem.activePoint == 12)
        || (i == 14 && myCarouselImagesItem.activePoint == 13)
        || (i == 15 && myCarouselImagesItem.activePoint == 14)
        || (i == 16 && myCarouselImagesItem.activePoint == 15)
        || (i == 0 && myCarouselImagesItem.activePoint == 1)
        || (i == 1 && myCarouselImagesItem.activePoint == 2)
        || (i == 2 && myCarouselImagesItem.activePoint == 3)
        || (i == 3 && myCarouselImagesItem.activePoint == 4)
        || (i == 4 && myCarouselImagesItem.activePoint == 5)
        || (i == 5 && myCarouselImagesItem.activePoint == 6)
        || (i == 6 && myCarouselImagesItem.activePoint == 7)
        || (i == 7 && myCarouselImagesItem.activePoint == 8)
        || (i == 8 && myCarouselImagesItem.activePoint == 9)
        || (i == 9 && myCarouselImagesItem.activePoint == 10)
        || (i == 10 && myCarouselImagesItem.activePoint == 11)
        || (i == 11 && myCarouselImagesItem.activePoint == 12)
        || (i == 12 && myCarouselImagesItem.activePoint == 13)
        || (i == 13 && myCarouselImagesItem.activePoint == 14)
        || (i == 14 && myCarouselImagesItem.activePoint == 15)" [class.bg-grey]="(i == 1 && myCarouselImagesItem.activePoint == 0)
         || (i == 2 && myCarouselImagesItem.activePoint == 1)
         || (i == 3 && myCarouselImagesItem.activePoint == 2)
         || (i == 4 && myCarouselImagesItem.activePoint == 3)
         || (i == 5 && myCarouselImagesItem.activePoint == 4)
         || (i == 6 && myCarouselImagesItem.activePoint == 5)
         || (i == 7 && myCarouselImagesItem.activePoint == 6)
         || (i == 8 && myCarouselImagesItem.activePoint == 7)
         || (i == 9 && myCarouselImagesItem.activePoint == 8)
         || (i == 10 && myCarouselImagesItem.activePoint == 9)
         || (i == 11 && myCarouselImagesItem.activePoint == 10)
         || (i == 12 && myCarouselImagesItem.activePoint == 11)
         || (i == 13 && myCarouselImagesItem.activePoint == 12)
         || (i == 14 && myCarouselImagesItem.activePoint == 13)
         || (i == 15 && myCarouselImagesItem.activePoint == 14)
         || (i == 16 && myCarouselImagesItem.activePoint == 15)
         || (i == 0 && myCarouselImagesItem.activePoint == 1)
         || (i == 1 && myCarouselImagesItem.activePoint == 2)
         || (i == 2 && myCarouselImagesItem.activePoint == 3)
         || (i == 3 && myCarouselImagesItem.activePoint == 4)
         || (i == 4 && myCarouselImagesItem.activePoint == 5)
         || (i == 5 && myCarouselImagesItem.activePoint == 6)
         || (i == 6 && myCarouselImagesItem.activePoint == 7)
         || (i == 7 && myCarouselImagesItem.activePoint == 8)
         || (i == 8 && myCarouselImagesItem.activePoint == 9)
         || (i == 9 && myCarouselImagesItem.activePoint == 10)
         || (i == 10 && myCarouselImagesItem.activePoint == 11)
         || (i == 11 && myCarouselImagesItem.activePoint == 12)
         || (i == 12 && myCarouselImagesItem.activePoint == 13)
         || (i == 13 && myCarouselImagesItem.activePoint == 14)
         || (i == 14 && myCarouselImagesItem.activePoint == 15)" -->