
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FullScreenService {
    constructor() { }

    public ShowFullScreen() {
        let elem = document.documentElement;
        let methodToBeInvoked = elem.requestFullscreen;
        if (methodToBeInvoked) methodToBeInvoked.call(elem);
    }

    public ExitFullScreen() {
        let methodToBeInvoked = document.exitFullscreen;
        try{
            if (methodToBeInvoked) methodToBeInvoked.call(document);
        }catch{}
    }
}