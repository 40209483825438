<div class="d-flex w-100" *ngIf="mobileQuery.matches && showdropdown">
  <button class="mx-0 mx-xl-0 bg-black d-flex w-100 border-0 py-3 justify-content-between" (click)="rotateChevron()"
    type="button" data-toggle="collapse" data-target="#collapseChooseVehicule" aria-expanded="false"
    aria-controls="collapseChooseVehicule">
    <h1 class="col-9 f-size-16px text-left font-weight-bold font-italic text-white my-auto">
      {{'how' | translate}} <span class="text-main-gradient f-size-16px font-weight-bold font-italic ">{{'chooseRight' |
        translate}}</span> {{'yourNextVehicle' | translate}}&nbsp;?
    </h1>
    <span class="my-auto px-4 border-left-grey">
      <span class="my-auto k-circle-arrow-down text-main-gradient f-size-28px rotate" [ngClass]="{rotate180: state}">

      </span>
    </span>
  </button>
</div>
<div class="collapse" id="collapseChooseVehicule" [class.show]="!mobileQuery.matches || !showdropdown">

  <form [formGroup]="formSearch" (ngSubmit)="onSubmit()"
    class="how-to-choose mx-0 mx-xl-0 bg-black row justify-content-around " id="how-to-choose">
    <div class="col-2 d-xl-flex d-none p-0 overflow-hidden justify-content-left-img"
      [class.justify-content-end]="displayFull">
      <!--  *ngIf="displayFull " -->
      <img src="assets/img/event/filter-left.png" alt="how to choose your vehicule left image" title="Dirt bike"
        [class.small-choose]="!displayFull" />
    </div>
    <div class="col-12 d-xl-none d-md-none p-0 overflow-hidden justify-content-end"
      *ngIf="!mobileQuery.matches || !showdropdown">
      <img src="assets/img/main/how-to-choose-mobile.jpg" alt="how to choose your vehicule mobile image"
        title="HTC mobile" class="w-100" />
    </div>
    <div class="col-12 col-xl my-auto row px-2 py-3 mx-0 justify-content-around">
      <div class="col-12" [class.mt-xl-4]="displayFull">
        <h1 class="text-center f-size-20px f-size-md-32px font-italic text-white mb-4 "
          *ngIf="!mobileQuery.matches || !showdropdown">
          {{'how' | translate}} <br class="d-lg-none" /><span
            class="text-main-gradient f-size-20px f-size-md-32px font-italic ">{{'chooseRight' | translate}}</span> <br
            class="d-lg-none" /> {{'yourNextVehicle' | translate}}&nbsp;?
        </h1>
        <p class="text-white text-center f-size-14px f-size-md-16px" *ngIf="displayFull">{{'simplyByUsingThisTool' | translate}}.</p>
        <p class="text-white text-center f-size-14px f-size-md-16px" *ngIf="displayFull">{{'discoverLargeProductSelection' | translate}}.</p>
      </div>
      <div class="row justify-content-center w-100">
        <div class=" col-12 col-md-4 my-2">
          <button mat-button class="bg-white px-4 py-3 w-100 d-flex border-0 rounded h-100 dropdown-toggle"
            *ngIf="!mobileQuery.matches && showCategories" type="button" id="dropdownVehicleType" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span class="smx-motocross mr-2 my-auto f-size-28px"></span>
            <span class="my-auto f-size-14px text-wrap-pretty">{{'vehicleType' | translate}} <span
                *ngIf="selectedCategories">({{selectedCategories.length}})</span>
            </span>
            <span class="k-chevron-right text-main-gradient my-auto ml-auto f-size-26px"></span>
          </button>

          <div class="dropdown-menu dropdownYour" aria-labelledby="dropdownVehicleType">
            <label class="dropdown-item cursor-pointer d-flex" (click)="onClickSelectCategory($event)" [for]="'elec'">
              <input type="checkbox" [id]="'elec'" (change)="onSelectVhsType(attributElectrique, $event)"
                [checked]="selectedVhsTypes && selectedVhsTypes.includes(attributElectrique)" />
              <span class="check d-flex">
                <span class="k-check my-auto f-size-13px"></span>
              </span>
              <label [for]="'elec'" class="ml-2 my-1 cursor-pointer">Electrique</label>
            </label>

            <label class="dropdown-item cursor-pointer d-flex" (click)="onClickSelectCategory($event)" [for]="'therm'">
              <input type="checkbox" [id]="'therm'" (change)="onSelectVhsType(attributThermique, $event)"
                [checked]="selectedVhsTypes && selectedVhsTypes.includes(attributThermique)" />
              <span class="check d-flex">
                <span class="k-check my-auto f-size-13px"></span>
              </span>
              <label [for]="'therm'" class="ml-2 my-1 cursor-pointer my-1">Thermique</label>
            </label>

            <hr>

            <label class="dropdown-item cursor-pointer d-flex" *ngFor="let category of categories"
              (click)="onClickSelectCategory($event)" [for]="'category_'+category.categoryId.toString()+idToAdd">
              <input type="checkbox" [checked]="selectedCategories && selectedCategories.includes(category.categoryId)"
                [id]="'category_'+category.categoryId.toString()+idToAdd"
                (change)="onSelectCategory(category, $event)" />
              <span class="check d-flex">
                <span class="k-check my-auto f-size-13px"></span>
              </span>
              <label [for]="'category_'+category.categoryId.toString()+idToAdd"
                class="ml-2 my-1 cursor-pointer">{{category.designation}} </label>
            </label>

          </div>
          <!-- 
          <mat-form-field appearance="outline" class="vehicule-type w-100 h-100 my-auto" *ngIf="!mobileQuery.matches && showCategories">
            <mat-label class="d-inline-flex">
              <span class="k-moto mx-2 my-auto f-size-28px"></span><span class="my-auto">{{'vehicleType' | translate}}</span>
            </mat-label>
            <mat-select multiple formControlName="categories">
              <mat-option *ngFor="let category of categories" [value]="category.categoryId">
                {{category.designation}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <button mat-button class="bg-white px-4 py-3 w-100 d-flex border-0 h-100 rounded line-1" type="button"
            (click)=" onOpenDialogSliderSelector('categories')" *ngIf="mobileQuery.matches && showCategories">
            <span class="k-moto mr-2 my-auto f-size-28px"></span>
            <span class="my-auto f-size-14px break-ellipsis">{{'vehicleType' | translate}} <span
                *ngIf="formSearch.value.categories && formSearch.value.categories.length > 0">
                ({{formSearch.value.categories.length}})</span></span>

            <span *ngIf="formSearch.value.categories && formSearch.value.categories.length > 0; else chevron"
              class="k-check my-auto ml-auto p-1 f-size-22px bg-main-gradient rounded-circle"></span>
            <ng-template #chevron> <span class="k-chevron-right text-main-gradient my-auto ml-auto f-size-26px"></span>
            </ng-template>
          </button>

        </div>

        <div class=" col-12  my-2" [class.col-md-4]="displayFull" [class.col-md-3]="!displayFull">
          <button mat-button class="bg-white px-4 py-3 w-100 d-flex border-0 h-100 rounded " type="button"
            id="dropdownYourSize" aria-haspopup="true" aria-expanded="false"
            (click)="onOpenDialogSliderSelector('height') " *ngIf="mobileQuery.matches">
            <span class="k-height mr-2 my-auto f-size-28px"></span>
            <span class="my-auto f-size-14px line-1">
              <span *ngIf="!formSearch.value.height">{{'yourSize' | translate}}</span>
              <span *ngIf="formSearch.value.height">{{formSearch.value.height}} cm</span>
            </span>
            <span class="k-chevron-right text-main-gradient my-auto ml-auto f-size-26px"></span>
          </button>

          <button mat-button class="bg-white px-4 py-3 w-100 d-flex border-0 h-100 rounded " type="button"
            id="dropdownYourSize" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            *ngIf="!mobileQuery.matches">
            <span class="k-height mr-2 my-auto f-size-28px"></span>
            <span class="my-auto f-size-14px line-1">
              <span *ngIf="!formSearch.value.height">{{'yourSize' | translate}}</span>
              <span *ngIf="formSearch.value.height">{{formSearch.value.height}} cm</span>
            </span>
            <span class="k-chevron-right text-main-gradient my-auto ml-auto f-size-26px"></span>
          </button>

          <!-- CHANGE FOR BS IN MOBILE -->
          <div class="dropdown-menu dropdownYour" aria-labelledby="dropdownYourSize">
            <div class="w-100 px-3">
              <input placeholder="Indiquer" formControlName="height"
                class="border-grey rounded px-1 py-2 w-100 input-choose" />
            </div>
            <a class="w-100 d-block px-3 py-2 text-black text-decoration-none cursor-pointer hover-choose"
              (click)="formSearch.patchValue({height:height!})" *ngFor="let height of listHeight">{{height}} cm</a>
          </div>
        </div>
        <div class=" col-12 my-2" [class.col-md-4]="displayFull" [class.col-md-3]="!displayFull">
          <button mat-button class="bg-white px-4 py-3 w-100 d-flex border-0 rounded h-100" type="button"
            id="dropdownYourWeight" aria-haspopup="true" aria-expanded="false"
            (click)=" onOpenDialogSliderSelector('weight')" *ngIf="mobileQuery.matches">
            <span class="k-fast mr-2 my-auto f-size-28px"></span>
            <span class="my-auto f-size-14px ">
              <span *ngIf="!formSearch.value.weight">{{'yourWeight' | translate}}</span>
              <span *ngIf="formSearch.value.weight">{{formSearch.value.weight}} kg</span>
            </span>
            <span class="k-chevron-right text-main-gradient my-auto ml-auto f-size-26px"></span>
          </button>

          <button mat-button class="bg-white px-4 py-3 w-100 d-flex border-0 rounded h-100" type="button"
            id="dropdownYourWeight" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            *ngIf="!mobileQuery.matches">
            <span class="k-fast mr-2 my-auto f-size-28px"></span>
            <span class="my-auto f-size-14px line-1">
              <span *ngIf="!formSearch.value.weight">{{'yourWeight' | translate}}</span>
              <span *ngIf="formSearch.value.weight">{{formSearch.value.weight}} kg</span>
            </span>
            <span class="k-chevron-right text-main-gradient my-auto ml-auto f-size-26px"></span>
          </button>
          <!-- CHANGE FOR BS IN MOBILE -->
          <div class="dropdown-menu dropdownYour" aria-labelledby="dropdownYourWeight">
            <div class="w-100 px-3">
              <input placeholder="Indiquer" formControlName="weight"
                class="border-grey rounded px-1 py-2 w-100 input-choose" />
            </div>
            <a class="w-100 d-block px-3 py-2 text-black text-decoration-none cursor-pointer hover-choose"
              (click)="formSearch.patchValue({weight:weight!})" *ngFor="let weight of listWeight">{{weight}} kg</a>
          </div>
        </div>

        <div class=" col-12 col-md-1 my-2" *ngIf="!displayFull">
          <button mat-button [disabled]="!formSearch.valid" [class.to-bounce]="formSearch.valid" class="btn-theme h-100"
            [title]="!formSearch.valid ? 'Veuillez choisir votre taille et poids' : ''">
            <span *ngIf="!mobileQuery.matches" class="k-search f-size-14px f-size-md-16px"></span>
            <span *ngIf="mobileQuery.matches" class="f-size-14px f-size-md-16px">{{'discoverMySelection' |
              translate}}</span>
            <span *ngIf="mobileQuery.matches" class="k-arrow-right ml-3 f-size-18px bounce-item"></span>
          </button>
        </div>

        <div class="col-10 col-md-4 my-2 px-3" *ngIf="displayFull">
          <button mat-button [disabled]="!formSearch.valid" [class.to-bounce]="formSearch.valid" class="btn-theme"
            [title]="!formSearch.valid ? 'Veuillez choisir votre taille et poids' : ''">
            <span class="f-size-14px f-size-md-16px">{{'discoverMySelection' | translate}}</span>
            <span class="k-arrow-right ml-3 f-size-18px bounce-item"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-2 d-xl-flex d-none p-0 overflow-hidden justify-content-right-img"
      [class.justify-content-start]="displayFull">
      <!--  *ngIf="displayFull" -->
      <img src="assets/img/event/filter-right.png" alt="how to choose your vehicule right image" title="Quad"
        [class.small-choose]="!displayFull" />
    </div>
  </form>

</div>
