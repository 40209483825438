
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  createLoadingStatesSelector } from 'ngrx-loading-state';
import * as CatalogActions from './catalog.actions';
import * as CatalogReducer from './catalog.reducer';


const selectState = createFeatureSelector<CatalogReducer.CatalogState>(CatalogReducer.CATALOG_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);


export const selectProduct = createSelector(
    selectState,
    (state) => state.product
  );

  export const selectProductCrossSell = createSelector(
    selectState,
    (state) => state.productCrossSell
  );

  export const selectProductEdit = createSelector(
    selectState,
    (state) => state.productEdit
  );

  
  export const selectCategory = createSelector(
    selectState,
    (state) => state.category
  );

  
  export const selectBrand = createSelector(
    selectState,
    (state) => state.brand
  );


  export const selectResultNotifyStock= createSelector(
    selectState,
    (state) => state.resultNotifyStock
  );

  export const selectTotalProducts= createSelector(
    selectState,
    (state) => state.products.totalRecords!
  );

  export const selectTotalPages= createSelector(
    selectState,
    (state) => state.products.totalPages!
  );

  export const selectPageNumber= createSelector(
    selectState,
    (state) => state.products.pageNumber!
  );

  export const selectSortEnumId= createSelector(
    selectState,
    (state) => state.sortingEnum!
  );

  
  export const selectTotalProductsCrossSell= createSelector(
    selectState,
    (state) => state.productsCrossSell.totalRecords!
  );

  export const selectTotalPagesCrossSell= createSelector(
    selectState,
    (state) => state.productsCrossSell.totalPages!
  );

  export const selectPageNumberCrossSell= createSelector(
    selectState,
    (state) => state.productsCrossSell.pageNumber!
  );




  export const selectProductReviews= createSelector(
    selectState,
    (state) => state.productReviews!
  );

  

  export const selectProducts= createSelector(
    selectState,
    (state) => state.products.list!
  );


  export const selectProductsCrossSell= createSelector(
    selectState,
    (state) => state.productsCrossSell.list!
  );

  
  export const selectCarriersByProductCountry= createSelector(
    selectState,
    (state) => state.carriersByProduct.country!
  );

  export const selectCarriersByProductZipCode= createSelector(
    selectState,
    (state) => state.carriersByProduct.zipCode!
  );



export const categoriesSelectors = CatalogReducer.adapterCategories.getSelectors(createSelector(selectState, (state) => state.categories));
export const categoriesHomeSelectors = CatalogReducer.adapterCategoriesHome.getSelectors(createSelector(selectState, (state) => state.categoriesHome));
export const categoriesSameParentSelectors = CatalogReducer.adapterCategoriesSameParent.getSelectors(createSelector(selectState, (state) => state.categoriesSameParent));
export const microFichesSelectors = CatalogReducer.adapterMicroFiches.getSelectors(createSelector(selectState, (state) => state.microfiches));
export const attributsSelectors = CatalogReducer.adapterAttributs.getSelectors(createSelector(selectState, (state) => state.attributs));
export const productFiltersSelectors = CatalogReducer.adapterProductFilters.getSelectors(createSelector(selectState, (state) => state.productFilters));
export const productFiltersCrossSellSelectors = CatalogReducer.adapterProductFiltersCrossSell.getSelectors(createSelector(selectState, (state) => state.productFiltersCrossSell));
export const brandsSelectors = CatalogReducer.adapterBrands.getSelectors(createSelector(selectState, (state) => state.brands));
// export const productsSelectors = CatalogReducer.adapterProducts.getSelectors(createSelector(selectState, (state) => state.products.list));
export const relatedProductsSelectors = CatalogReducer.adapterRelatedProducts.getSelectors(createSelector(selectState, (state) => state.relatedProducts));
export const productsBestSellerSelectors = CatalogReducer.adapterProductsBestSeller.getSelectors(createSelector(selectState, (state) => state.productsBestSeller));
export const crossSellGroupsSelectors = CatalogReducer.adapterCrossSellGroups.getSelectors(createSelector(selectState, (state) => state.crossSellGroups));
export const carriersByProductSelectors = CatalogReducer.adapterCarriersByProduct.getSelectors(createSelector(selectState, (state) => state.carriersByProduct.carriers));

  

export const fetchAttributsStateSelectors = CatalogActions.fetchAttributs.createSelectors(selectLoadingStates);
export const fetchProductFiltersStateSelectors = CatalogActions.fetchProductFilters.createSelectors(selectLoadingStates);
export const fetchCategoriesStateSelectors = CatalogActions.fetchCategories.createSelectors(selectLoadingStates);
export const fetchCategoriesHomeStateSelectors = CatalogActions.fetchCategoriesHome.createSelectors(selectLoadingStates);
export const fetchCategoriesSameParentStateSelectors = CatalogActions.fetchCategoriesSameParent.createSelectors(selectLoadingStates);
export const fetchMicrofichesStateSelectors = CatalogActions.fetchMicroFiches.createSelectors(selectLoadingStates);
export const fetchCategoryStateSelectors = CatalogActions.fetchCategory.createSelectors(selectLoadingStates);
export const fetchBrandStateSelectors = CatalogActions.fetchBrand.createSelectors(selectLoadingStates);
export const fetchBrandsStateSelectors = CatalogActions.fetchBrands.createSelectors(selectLoadingStates);
export const fetchProductsStateSelectors = CatalogActions.fetchProducts.createSelectors(selectLoadingStates);
export const fetchProductsCrossSellStateSelectors = CatalogActions.fetchProductsForCrossSell.createSelectors(selectLoadingStates);
export const fetchRelatedProductsStateSelectors = CatalogActions.fetchRelatedProducts.createSelectors(selectLoadingStates);
export const fetchProductStateSelectors = CatalogActions.fetchProduct.createSelectors(selectLoadingStates);
export const fetchProductForCrossSellStateSelectors = CatalogActions.fetchProductForCrossSell.createSelectors(selectLoadingStates);
export const fetchProductForModifyStateSelectors = CatalogActions.fetchProductForModify.createSelectors(selectLoadingStates);
export const fetchProductsBestSellerStateSelectors = CatalogActions.fetchProductsBestSeller.createSelectors(selectLoadingStates);
export const fetchProductReviewsSelectors = CatalogActions.fetchProductReviews.createSelectors(selectLoadingStates);
export const fetchCrossSellGroupsSelectors = CatalogActions.fetchCrossSellGroups.createSelectors(selectLoadingStates);
export const notifyStockStateSelectors = CatalogActions.notifyStock.createSelectors(selectLoadingStates);
export const fetchCarriersByProductStateSelectors = CatalogActions.fetchCarriersByProduct.createSelectors(selectLoadingStates);
