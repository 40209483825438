import { PipeTransform, Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'formatPrice'})
export class FormatPricePipe implements PipeTransform  {
  
  constructor(private sanitizer: DomSanitizer) {
     
    }
  transform(value:any, taxType:string = '') {
    if(!value) {
      return this.sanitizer.bypassSecurityTrustHtml( `0€`);
    }

    value =  Math.round((value + Number.EPSILON) * 100) / 100;
    value = value.toFixed(2);
    const price =  (value + '') as string;
    
    if(price.includes('.')){
    const priceArr = price.split('.');
        const left = priceArr[0];
        const right = priceArr[1];
        return this.sanitizer.bypassSecurityTrustHtml( `${left}<span class="sup">,${right}€${taxType}</span>`);
    }
    else if(price.includes(',')){
      const priceArr = price.split(',');
        const left = priceArr[0];
        const right = +priceArr[1];
        return this.sanitizer.bypassSecurityTrustHtml( `${left}<span class="sup">,${right}€${taxType}</span>`);
    }

    return this.sanitizer.bypassSecurityTrustHtml( `${price}<span class="sup">€${taxType}</span>`);
  }
}