import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer } from "@ngrx/store";
import { getInitialState, WithLoadingStates } from "ngrx-loading-state";
import { DoofinderResult } from "src/app/models/doofinder-result.model";

import * as DoofincerActions from './doofinder.actions';


export const DOOFINDER_FEATURE_KEY = 'doofinder';


export type DoofinderState = WithLoadingStates & {
    result:DoofinderResult | null
};



export const initialState: DoofinderState = {
    ...getInitialState(),
    result:null
};


export const doofinderReducer = createReducer(
    initialState,
    ...DoofincerActions.search.reducer<DoofinderState>({
        onSuccess: (state, response): DoofinderState => {
            // console.log(response.results);
            return {
                ...state,
                 result: response.results
            };
        }
    }),

);
