import { createReducer } from '@ngrx/store';
import { getInitialState, WithLoadingStates } from 'ngrx-loading-state';

import { User } from '../../models/user.model';
import * as AuthActions from './auth.actions';


export const AUTH_FEATURE_KEY = 'auth-feature';


export type AuthState = WithLoadingStates & {
    user: User | null,
    isAuthenticated: boolean,
    message:string|null,
 };

 
export const initialState: AuthState = {
    ...getInitialState(),
  user:null,
  isAuthenticated:false,
  message:null,
  };


export const authReducer = createReducer(
    initialState,
    ...AuthActions.register.reducer<AuthState>({
        onSuccess:(state, registerResult): AuthState => {
            return {
              ...state,
            };
        }
    }),


    ...AuthActions.login.reducer<AuthState>({
        onLoad:(state, loginResult): AuthState => {
           
            return {
              ...state,
              user: null,
            };
        },

        onSuccess:(state, loginResult): AuthState => {
            const user = new User(
                loginResult.firstName!,
                loginResult.lastName!,
                loginResult.email!,
                loginResult.userId!,
                loginResult.token!,
                loginResult.refreshToken,
                loginResult.expirationDate,
                loginResult.userRoles,
                loginResult.loginAs,
                loginResult.customerId
            );
            return {
              ...state,
              user: user,
              isAuthenticated:true
            };
        },
        onFailure:(state, errors) :AuthState => {
            let message ='';
            if(errors.errors){
                message = errors.errors[0].code!;
            }
            
            return {
                ...state,
                message:message
            }
        }
    }),

    ...AuthActions.requestResetPassword.reducer<AuthState>({
        onSuccess:(state, result): AuthState => {
            return {
              ...state,
              message:result.result
            };
        }
    }),

    ...AuthActions.resetPassword.reducer<AuthState>({
        onSuccess:(state, result): AuthState => {
            return {
              ...state,
              message:result.result
            };
        }
    }),

    ...AuthActions.logout.reducer<AuthState>({

        onSuccess:(state, result): AuthState => {
            return {
              ...state,
                user:null,
                isAuthenticated:false
            };
        }
    }),

   
);


