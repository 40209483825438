import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer } from '@ngrx/store';
import { getInitialState, WithLoadingStates } from 'ngrx-loading-state';
import * as GlobalLoaderActions from './global-loader.actions';


export const GLOBAL_LOADER_FEATURE_KEY = 'global-loader-features';


export type GlobalLoaderState = WithLoadingStates & {
    loading: boolean ,
};


export const initialState: GlobalLoaderState = {
    ...getInitialState(),
    loading: false,
};


export const globalLoaderReducer = createReducer(
    initialState,
    ...GlobalLoaderActions.setLoading.reducer<GlobalLoaderState>({
        onLoad: (state, response): GlobalLoaderState => {
            return {
                ...state,
                loading: response.loading
            };
        }
    }),

    ...GlobalLoaderActions.clearLoading.reducer<GlobalLoaderState>({
        onLoad: (state, response): GlobalLoaderState => {
            return {
                ...state,
                loading:false
            };
        }
    }),


);


