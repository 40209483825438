<div *ngIf="loginAs" class="w-100 text-center py-2">
  <p class=" my-0 mx-auto"><span
      class="font-weight-bold text-white f-size-md-14px f-size-2-5vw text-decoration-underline">{{'connectAs' |
      translate}} :
      {{nameUser}} {{companyName ? ' - ' + companyName : ''}} {{customerId ? '( #'+customerId + ' )' : ''}} </span></p>
</div>

<!-- <div class="w-100 bg-main-gradient text-center py-2" *ngIf="blockBanner">
  <span class=" my-0 mx-auto f-size-md-14px f-size-2-5vw" [innerHTML]="blockBanner.content | safe:'html'"></span>
</div> -->


<div class="bg-black">

  <!------------------------------------------------ MOBILE ---------------------------------------------------------->

  <nav *ngIf="mobileQuery.matches && !navForProduct" class="d-flex navbar px-0 py-0 justify-content-between h-60px">

    <!-- BTN CATEGORY -->
    <button class="px-0 bg-main-gradient h-100 rounded-0  pt-1 " mat-button type="button"
      (click)="drawerMenu.close()" *ngIf="drawerMenu.opened">
      <span id="menu" class="k-x f-size-32px text-black m-auto"></span>
      <!-- <p class="f-size-8px text-black">{{'menu' | translate}}</p> -->
    </button>
    <button class="px-0 bg-main-gradient h-100 rounded-0 pt-1" mat-button type="button" (click)="drawerMenu.open()"
      *ngIf="!drawerMenu.opened"
      [class.content-loader]="(catalogFacade.getFetchCategoriesState() | async)?.loading"
      [disabled]="(catalogFacade.getFetchCategoriesState() | async)?.loading">
      <span id="menu" class="k-menu f-size-23px text-black"></span>
      <!-- <p class="f-size-8px text-black">{{'menu' | translate}}</p> -->
    </button>

    <!--<div style="width: 214px; height: 54px;" class="my-auto d-flex justify-content-start">
      <a (click)="drawerMenu.open()" *ngIf="!drawerMenu.opened" class="nav-item btn bg-main-gradient f-size-14px text-black px-2 py-2 my-auto w-100" style="height: 54px; align-items: center;" mat-button>
        <span class="k-menu f-size-16px"></span>
        <span class="f-size-15px font-weight-bold ml-2" style="font-style:normal;">{{'AllProduct' | translate}}</span>
      </a>

      <a (click)="drawerMenu.close()" *ngIf="drawerMenu.opened"
         class="nav-item btn bg-main-gradient f-size-14px text-black px-3 py-2 my-auto h-100" style="height:54px; align-items:center;" mat-button>
        <span class="k-x f-size-22px"></span>
      </a>
    </div>-->
    <!-- LOGO -->
    <h1 class="my-auto d-flex">
      <a [routerLink]="['/',translateService.currentLang]" (click)="drawerMenu.close()" class="my-auto d-flex">
        <img src="/assets/img/main/logo.png"  alt="SmallMX" title="SmallMX" class="mt-0 my-auto"
          id="logo-header-mobile" />
      </a>
    </h1>
    <span class="my-auto d-flex justify-content-between mr-3 minw-75px">
      <button class="px-0 bg-transparent border-0 px-1" type="button" (click)="onOpenDialogDooFinder()">
        <span class="m-auto d-flex">
          <span class="k-search f-size-23px text-white mr-0 ml-auto"></span>
        </span>
      </button>

      <a (click)="sideNavService.toggleSideNavRight('cart')" class="px-1">
        <span class="k-basket f-size-23px text-white"></span>
        <span *ngIf="numberProductRequested > 0"
          class="rounded-pill bg-main-gradient text-black position-absolute d-flex item-cart-number-mobile">
          <span class="m-auto font-weight-bold">
            {{numberProductRequested}}
          </span>
        </span>
      </a>
    </span>

  </nav>

  <!------------------------------------------------ DESKTOP ---------------------------------------------------------->

  <nav *ngIf="!mobileQuery.matches" class="py-2 d-flex px-5 justify-content-between w-100 limit-container nav-desktop">

    <!-- LOGO BRAND -->
    <h1 class="my-auto d-flex">
      <a [routerLink]="['/',translateService.currentLang]" class="m-auto d-flex">
        <img src="/assets/img/main/logo.png" class="mr-4 my-auto" alt="SmallMX" title="SmallMX" id="logo-desktop" />
      </a>
    </h1>

    <!-- BTN CATEGORY -->
    <div class="my-auto d-flex justify-content-start div-button-category">
      <a (click)="drawerMenu.open()" *ngIf="!drawerMenu.opened"
      [class.content-loader]="(catalogFacade.getFetchCategoriesState() | async)?.loading"
      [disabled]="(catalogFacade.getFetchCategoriesState() | async)?.loading"
        class="nav-item btn bg-main-gradient f-size-14px text-black px-2 py-2 my-auto w-100 flex " mat-button>
        <div class="d-flex justify-content-center align-items-center">
          <span class="k-menu f-size-16px"></span>
          <small class="f-size-15px font-weight-bold ml-2 font-style-normal">{{'AllProduct' | translate}}</small>
        </div>
      </a>

      <a (click)="drawerMenu.close()" *ngIf="drawerMenu.opened"
        class="nav-item btn bg-main-gradient f-size-14px text-black px-3 py-2 my-auto h-100" mat-button>
        <span class="d-block k-x f-size-32px"></span>
      </a>
    </div>

    <!-- BTN RESEARCH -->
    <div class="col my-auto px-0">
      <form class="bg-white rounded mx-auto d-flex py-2 px-4 w-100 h-54px" [formGroup]="formSearch"
        (ngSubmit)=" onSearch()">
        <button class="k-search opacity-50 my-auto f-size-16px border-0 bg-white" type="submit"
       ></button>
        <input class="ml-2 my-auto f-size-16px border-0 w-100" formControlName="search"
          placeholder="Que recherchez vous ?" />
      </form>
    </div>


    <!-- BTN TO CHOOSE VEHICULE -->
    <button class="mat-menu d-flex px-2 py-1 bg-black rounded my-auto btn-choose-vehicule"
      (click)="openDialogChooseVehicule()">
      <span class="text-white k-moto my-auto f-size-32px ml-2"></span>
      <span
        class="font-weight-semibold text-white text-left my-auto mx-3 mr-xl-auto f-size-16px choose">{{'ChooseYourFutureVehicule'
        | translate}}</span>
      <span class="text-main-gradient k-chevron-down my-auto mr-3 ml-auto margin-spe"></span>
    </button>





    <div class="my-auto d-flex justify-content-around">


      <!-- LANG MENU -->
      <!-- <button class="mat-menu langue-drop my-auto" mat-button [matMenuTriggerFor]="menuLang">
        <mat-icon iconPositionEnd class="text-white ml-0">keyboard_arrow_down</mat-icon>
        <span class="font-weight-normal text-white f-size-15px">{{selectedLanguageCode}}</span>

      </button>

      <mat-menu #menuLang="matMenu">
        <button mat-menu-item *ngFor="let lang of languages" (click)="navigate(lang.code)">
          {{lang.code.substring(0,2).toUpperCase()}}
        </button>
      </mat-menu> -->

      <!-- THEME MENU - FOR TESTING PURPOSES -->
      <button class="mat-menu langue-drop my-auto" mat-button [matMenuTriggerFor]="menuTheme" >
        <mat-icon iconPositionEnd class="text-white ml-0 rep-keyboard_arrow_down"></mat-icon>
        <span class="font-weight-normal text-white f-size-15px">
          <mat-icon class="rep-invert_colors_on f-size-22px"></mat-icon>
        </span>

      </button>

      <mat-menu #menuTheme="matMenu">
        <button mat-menu-item *ngFor="let theme of themes" (click)="onChangeTheme(theme.id)">
          <span [class.font-weight-bold]="selectedTheme && theme.id == selectedTheme.id">{{theme.name}}</span>
        </button>
      </mat-menu>


      <!-- HELP -->
      <!--<a class="text-white font-weight-bold f-size-16px my-auto mx-3">
    {{'assistance' | translate}}
  </a>-->
      <a class="px-2 mr-0 ml-3 icone-width my-auto d-flex" href="https://smallmx.zendesk.com/hc/fr" target="_blank">
        <span class="k-assistance f-size-28px text-white my-auto">
        </span>
      </a>

      <!-- CONTACT -->
      <!--<a [routerLink]="['/', translateService.currentLang, 'contact']"
     class="text-white font-weight-bold f-size-16px my-auto mx-3">
    {{'contact' | translate}}
  </a>-->
      <a class="px-2 mx-0 icone-width my-auto d-flex"
        [routerLink]="['/', translateService.currentLang, 'contact']">
        <span class="k-mail f-size-24px text-white line-height-1 my-auto"></span>
      </a>

      <!-- BTN ACCOUNT - CONNECT -->
      <button *ngIf="isAuthenticated" [matMenuTriggerFor]="menu" aria-label="account menu"
        class="px-2 mx-0 icone-width my-auto d-flex border-0 bg-transparent">
        <span class="k-user f-size-24px text-white my-auto"></span>
      </button>

      <mat-menu #menu="matMenu" class=" my-auto">

        <a mat-menu-item class="row mx-0 text-black pb-2 border-menu h-90px"
          [routerLink]="['/',translateService.currentLang, 'manage', 'profile']">
          <p class="font-weight-bold text-black mb-0 w-100 px-3">{{'hello' | translate}},</p>
          <p class="f-size-18px text-black mb-0 px-3">
            {{nameUser}}
            <span *ngIf="companyName"><br>{{companyName}}</span>
          </p>
        </a>
        <a mat-menu-item class="row mx-0 text-black h-85px"
          [routerLink]="['/',translateService.currentLang, 'manage', 'orders']">
          <span class="my-auto text-black col-2 d-flex"><span class="my-auto f-size-24px k-box-fill"></span></span>
          <span class="my-auto text-black col-8">
            {{'myOrder' | translate}}<br /><span class="f-size-12px">{{'historyTrackingBack' | translate}}</span>
          </span>
          <span class="col-1 k-chevron-right my-auto text-black"></span>

        </a>
        <div class="row mx-0 px-4">
          <hr class="w-100 my-0 border-grey-light" />
        </div>
        <a mat-menu-item class="row mx-0 text-black h-85px"
          [routerLink]="['/',translateService.currentLang, 'manage', 'profile']">
          <span class="my-auto text-black col-2 d-flex"><span class="my-auto f-size-24px k-user-fill"></span></span>
          <span class="my-auto text-black col-8">
            {{'myProfil' | translate}}<br /><span class="f-size-12px">{{'passwordContactDetails' | translate}}</span>
          </span>
          <span class="col-2 ml-auto k-chevron-right my-auto text-black"></span>

        </a>
        <div class="row mx-0 px-4">
          <hr class="w-100 my-0 border-grey-light" />
        </div>
        <a mat-menu-item class="row mx-0 text-black h-85px"
          [routerLink]="['/',translateService.currentLang, 'manage', 'adresses']">
          <span class="my-auto text-black col-2 d-flex"><span class="my-auto f-size-24px k-truc-fill"></span></span>
          <span class="my-auto text-black col-8">
            {{'myAddress' | translate}}<br /><span class="f-size-12px">{{'deliveryAddress' | translate}}</span>
          </span>
          <span class="col-2 ml-auto k-chevron-right my-auto text-black"></span>

        </a>
        <div class="row mx-0 px-4">
          <hr class="w-100 my-0 border-grey-light" />
        </div>
        <a mat-menu-item class="row mx-0 text-black h-85px"
          [routerLink]="['/',translateService.currentLang, 'manage', 'coupons']">
          <span class="my-auto text-black col-2 d-flex"><span class="my-auto f-size-24px k-coupon-fill"></span></span>
          <span class="my-auto text-black col-8">{{'myCoupons' | translate}}</span>
          <span class="col-2 ml-auto k-chevron-right my-auto text-black"></span>

        </a>
        <div class="row mx-0 px-4">
          <hr class="w-100 my-0 border-grey-light" />
        </div>
        <a mat-menu-item class="row mx-0 text-black h-85px"
          [routerLink]="['/',translateService.currentLang,'manage', 'contact']">
          <span class="my-auto text-black col-2 d-flex"><span class="my-auto f-size-24px k-mail-fill"></span></span>
          <span class="my-auto text-black col-8">{{'contactUs' | translate}}</span>
          <span class="col-2 ml-auto k-chevron-right my-auto text-black"></span>

        </a>
        <div class="row mx-0 px-4">
          <hr class="w-100 my-0 border-grey-light" />
        </div>
        <button mat-menu-item (click)="onLogout()" class="row mx-0 text-black h-85px">
          <span class="my-auto text-black font-weight-semibold col-10">{{'disconnect' | translate}}</span>
          <span class="ml-auto k-power my-auto text-black col-2"></span>
        </button>
      </mat-menu>

      <!-- BTN ACCOUNT - NO CONNECT -->
      <a *ngIf="!isAuthenticated" class="px-2 mx-0 icone-width my-auto d-flex"
        [routerLink]="['/', translateService.currentLang, 'auth']">
        <span class="k-user f-size-24px text-white my-auto"></span>
      </a>

      <!-- BTN CART -->
      <a class="pl-1 mx-0 pr-0 icone-width my-auto d-flex position-relative" (click)="sideNavService.toggleSideNavRight('cart')">
        <span class="k-basket f-size-24px text-white my-auto"></span>
        <span class="rounded-pill bg-main-gradient text-black position-absolute d-flex item-cart-number"
          *ngIf="numberProductRequested > 0">
          <span class="m-auto font-weight-bold text-black line-height-1">
            {{numberProductRequested}}
          </span>
        </span>
      </a>


    </div>

  </nav>

</div>
