import { Injectable, EventEmitter } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Category } from "../models/category.model";
import { Product } from "../models/product.model";

@Injectable({
    providedIn: 'root'
})
export class SideNavService {
    public sidenavRight!: MatSidenav;
    public sidenavLeft!: MatSidenav;

    public sidenavRightContentType: string = 'cart'; // cart, cross-sell
    public sidenavRightCrossSellStep = 1; // cart, cross-sell

    public sideNavLeftCategory!: Category | null;

    public dataCrossSell!: {
        crossSellId: number
        designation: string,
        categories: number[],
        close?: boolean,
    } | null;

    public documentRowId!: number | null;

    public dataCrossSellEmitter = new EventEmitter<{
        crossSellId: number,
        designation: string,
        categories: number[],
        close?: boolean
    } | null>();

    public productAdded!: Product | null;
    public currentProduct!: Product | null;
    public addCurrentProductToCart!: boolean;

    constructor() {
    }

    public setSidenavRight(sidenav: MatSidenav) {
        this.sidenavRight = sidenav;
    }
    public setSidenavLeft(sidenav: MatSidenav) {
        this.sidenavLeft = sidenav;
    }

    public setSidenavRightContentType(type: string) {
        this.sidenavRightContentType = type;
    }

    public setSidenavLeftCategory(category: Category | null) {
        this.sideNavLeftCategory = category;
    }

    public openSideNavRight(type?: string) {
        if (type) this.setSidenavRightContentType(type);
        if (type == 'cart') this.documentRowId = null;
        return this.sidenavRight.open();
    }


    public openSideNavLeft() {
        return this.sidenavLeft.open();
    }

    public closeSideNavRight(type?: string) {
        if (this.sidenavRight) {
            if (type) this.setSidenavRightContentType(type);
            if (type == 'cart') this.documentRowId = null;
            // console.log('closeSideNavRight');
             this.sidenavRight.close();
        }
        return undefined;
    }


    public closeSideNavLeft() {
        if (this.sidenavLeft)
            return this.sidenavLeft.close();
        return undefined;
    }

    public toggleSideNavRight(type?: string): void {
        if (type) this.setSidenavRightContentType(type);
        if (type == 'cart') this.documentRowId = null;
        this.sidenavRight.toggle();
    }



    public toggleSideNavLeft(): void {
        this.sidenavLeft.toggle();
    }
    public setDataCrossSell(crossSellId: number, designation: string, categories: number[], close?: boolean) {
        this.dataCrossSell = {
            crossSellId: crossSellId,
            designation: designation,
            categories: categories,
            close: close,
        };

        // console.log(designation)
        this.dataCrossSellEmitter.next(this.dataCrossSell);
    }

    public clearDataCrossSell(){
        this.dataCrossSell = null;
        this.productAdded = null;
        this.currentProduct = null;
        this.dataCrossSellEmitter.next(null);
        // console.log('clearDataCrossSell')
    }

    public setDocumentRowId(documentRowId: number) {
        this.documentRowId = documentRowId;
    }

}