import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CatalogEffects } from './catalog.effects';
import { CatalogFacade } from './catalog.facade';
import { catalogReducer, CATALOG_FEATURE_KEY } from './catalog.reducer';

@NgModule({
    declarations: [

    ],
    imports: [
        StoreModule.forFeature(CATALOG_FEATURE_KEY, catalogReducer),
        EffectsModule.forFeature([CatalogEffects]),
    ],
    providers: [CatalogFacade],
    bootstrap: []
})
export class CatalogStoreModule { }
