import { createLoadingActions, failure, load, success } from "ngrx-loading-state";

import {Error} from '../../models/results/base-result.model';
import { JsonLd } from "../../models/jsonld.model";
import { Tab } from "../../models/tab.model";


export const fetchTab = createLoadingActions(
    '[SEO] Fetch Tab',
    load<{tabId: number}>(),
    success<Tab>(),
    failure<{ errors: Error[] | undefined }>()
);



export const fetchJsonLd = createLoadingActions(
    '[SEO] Fetch JsonLd',
    load<{t:string ,id?: number}>(),
    success<JsonLd>(),
    failure<{ errors: Error[] | undefined }>()
);
