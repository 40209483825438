import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData{
  productId:number,
  shopId:number
}

@Component({
  selector: 'app-dialog-notify-stock',
  templateUrl: './dialog-notify-stock.component.html',
  styleUrls: ['./dialog-notify-stock.component.scss']
})
export class DialogNotifyStockComponent {

  constructor(
    public dialogRef : MatDialogRef<DialogNotifyStockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData

  ){}
}
