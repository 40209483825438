import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DocumentRow } from 'src/app/models/document-row.model';
import { ObjVariant } from 'src/app/models/product.model';

interface BsData{
  documentRow?:DocumentRow,
  variants?:ObjVariant[],
  addToCart?:boolean
}


@Component({
  selector: 'app-bs-modify-product',
  templateUrl: './bs-modify-product.component.html',
  styleUrls: ['./bs-modify-product.component.scss']
})
export class BsModifyProductComponent {

  constructor(
    public bottomSheetRef: MatBottomSheetRef<BsModifyProductComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BsData,
  ){}
}
