
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer } from "@ngrx/store";
import { getInitialState, WithLoadingStates } from "ngrx-loading-state";
import { ReviewsResult } from "src/app/models/review.model";
import { MicroFiche } from "src/app/models/microfiche.model";
import { Attribut } from "../../models/attribut.model";
import { Brand } from "../../models/brand.model";
import { Category } from "../../models/category.model";
import { Product, ProductItem } from "../../models/product.model";

import * as CatalogActions from './catalog.actions';
import { ProductFilter } from "src/app/models/product-filter.model";
import { CrossSellGroup } from "src/app/models/cross-sell.model";
import { Carrier } from "src/app/models/carrier.model";

export const CATALOG_FEATURE_KEY = 'catalog';

export const adapterCarriersByProduct: EntityAdapter<Carrier> =
    createEntityAdapter<Carrier>({
        selectId: carrier => carrier.carrierId!
    });

export const adapterAttributs: EntityAdapter<Attribut> =
    createEntityAdapter<Attribut>({
        selectId: attribut => attribut.attributId!
    });

export const adapterProductFilters: EntityAdapter<ProductFilter> =
    createEntityAdapter<ProductFilter>({
        selectId: filter => filter.filterId!
    });

    
export const adapterProductFiltersCrossSell: EntityAdapter<ProductFilter> =
createEntityAdapter<ProductFilter>({
    selectId: filter => filter.filterId!
});


export const adapterCrossSellGroups: EntityAdapter<CrossSellGroup> =
    createEntityAdapter<CrossSellGroup>({
        selectId: g => g.crossSellGroupId!
    });


export const adapterCategories: EntityAdapter<Category> =
    createEntityAdapter<Category>({
        selectId: category => category.categoryId!
    });

    export const adapterCategoriesHome: EntityAdapter<Category> =
    createEntityAdapter<Category>({
        selectId: category => category.categoryId!
    });


export const adapterCategoriesSameParent: EntityAdapter<Category> =
    createEntityAdapter<Category>({
        selectId: category => category.categoryId!
    });

export const adapterMicroFiches: EntityAdapter<MicroFiche> =
    createEntityAdapter<MicroFiche>({
        selectId: mf => mf.categoryId!
    });

export const adapterBrands: EntityAdapter<Brand> =
    createEntityAdapter<Brand>({
        selectId: brand => brand.brandId!
    });

export const adapterProducts: EntityAdapter<ProductItem> =
    createEntityAdapter<ProductItem>({
        selectId: productItem => productItem.productId!
    });

export const adapterProductsCrossSell: EntityAdapter<ProductItem> =
    createEntityAdapter<ProductItem>({
        selectId: productItem => productItem.productId!
    });


export const adapterRelatedProducts: EntityAdapter<ProductItem> =
    createEntityAdapter<ProductItem>({
        selectId: productItem => productItem.productId!
    });

export const adapterProductsBestSeller: EntityAdapter<ProductItem> =
    createEntityAdapter<ProductItem>({
        selectId: productItem => productItem.productId!
    });




export type CatalogState = WithLoadingStates & {
    categories: EntityState<Category>,
    categoriesHome: EntityState<Category>,
    categoriesSameParent: EntityState<Category>,
    microfiches: EntityState<MicroFiche>,
    category: Category | null,
    brands: EntityState<Brand>,
    brand: Brand | null,
    products: { list: ProductItem[], totalRecords?: number | null | undefined, totalPages?: number | null | undefined, pageNumber?: number | null | undefined },
    productsCrossSell: { list: ProductItem[], totalRecords?: number | null | undefined, totalPages?: number | null | undefined, pageNumber?: number | null | undefined },
    relatedProducts: EntityState<ProductItem>,
    productsBestSeller: EntityState<ProductItem>,
    attributs: EntityState<Attribut>,
    productFilters: EntityState<ProductFilter>,
    productFiltersCrossSell: EntityState<ProductFilter>,
    sortingEnum: number | null,
    crossSellGroups: EntityState<CrossSellGroup>,
    product: Product | null,
    productCrossSell: Product | null,
    productEdit: Product | null,
    resultNotifyStock: boolean | null
    productReviews: ReviewsResult | null,
    carriersByProduct: { carriers: EntityState<Carrier>, country?: string | null, zipCode?: string | null },
};



export const initialState: CatalogState = {
    ...getInitialState(),
    categories: adapterCategories.getInitialState(),
    categoriesHome: adapterCategoriesHome.getInitialState(),
    categoriesSameParent: adapterCategoriesSameParent.getInitialState(),
    microfiches: adapterMicroFiches.getInitialState(),
    category: null,
    brands: adapterBrands.getInitialState(),
    brand: null,
    products: { list: [], totalRecords: null, totalPages: null, pageNumber: null },
    productsCrossSell: { list: [], totalRecords: null, totalPages: null, pageNumber: null },
    relatedProducts: adapterRelatedProducts.getInitialState(),
    productsBestSeller: adapterProductsBestSeller.getInitialState(),
    attributs: adapterAttributs.getInitialState(),
    productFilters: adapterProductFilters.getInitialState(),
    productFiltersCrossSell: adapterProductFiltersCrossSell.getInitialState(),
    sortingEnum:null,
    crossSellGroups: adapterCrossSellGroups.getInitialState(),
    product: null,
    productCrossSell: null,
    productEdit: null,
    resultNotifyStock: null,
    productReviews: null,
    carriersByProduct: { carriers: adapterCarriersByProduct.getInitialState(), country: null, zipCode: null }
};




export const catalogReducer = createReducer(
    initialState,
    ...CatalogActions.fetchCarriersByProduct.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                // carriersByProduct: {
                //     carriers:adapterCarriersByProduct.getInitialState()
                // }
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                carriersByProduct: {
                    carriers: adapterCarriersByProduct.setAll(response.carriers, state.carriersByProduct.carriers),
                    zipCode: response.zipCode!,
                    country: response.country!
                }
            };
        }
    }),

    ...CatalogActions.clearCarriersByProduct.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                carriersByProduct: {
                    carriers:adapterCarriersByProduct.getInitialState()
                }
            };
        }
    }),


    ...CatalogActions.fetchAttributs.reducer<CatalogState>({
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                attributs: adapterAttributs.setAll(response.attributs, state.attributs)
            };
        }
    }),

    ...CatalogActions.fetchProductFilters.reducer<CatalogState>({
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                productFilters: adapterProductFilters.setAll(response.filters, state.productFilters)
            };
        }
    }),

    ...CatalogActions.fetchBrands.reducer<CatalogState>({
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                brands: adapterBrands.setAll(response.brands, state.brands)
            };
        }
    }),

    ...CatalogActions.fetchBrand.reducer<CatalogState>({
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                brand: response.brand
            };
        }
    }),

    ...CatalogActions.clearBrand.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                brand: null
            };
        }
    }),

    ...CatalogActions.fetchCategories.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return{
                ...state,
                categories:adapterCategories.getInitialState()
            }
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                categories: adapterCategories.setAll(response.categories, state.categories)
            };
        }
    }),

     ...CatalogActions.fetchCategoriesHome.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return{
                ...state,
                categoriesHome:adapterCategoriesHome.getInitialState()
            }
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                categoriesHome: adapterCategoriesHome.setAll(response.categories, state.categories)
            };
        }
    }),

    ...CatalogActions.fetchCategoriesSameParent.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                categoriesSameParent: adapterCategoriesSameParent.getInitialState()
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                categoriesSameParent: adapterCategoriesSameParent.setAll(response.categories, state.categoriesSameParent)
            };
        }
    }),

    ...CatalogActions.fetchMicroFiches.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                microfiches: adapterMicroFiches.getInitialState()
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                microfiches: adapterMicroFiches.setAll(response.microFiches, state.microfiches)
            };
        }
    }),

    ...CatalogActions.fetchCategory.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                category: null
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                category: response.category
            };
        }
    }),

    ...CatalogActions.clearCategory.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                category: null
            };
        }
    }),


    ...CatalogActions.fetchProducts.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                product:null,
                carriersByProduct:{carriers:adapterCarriersByProduct.getInitialState(),country:null,zipCode:null},
                // products: { list: [], totalRecords: null, totalPages: state.products.totalPages, pageNumber: null },
                // productFilters:adapterProductFilters.getInitialState()
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                products: {
                    list: [...response.result.entity.products],
                    totalRecords: response.result.totalRecords,
                    totalPages: response.result.totalPages,
                    pageNumber: response.result.pageNumber
                },
                productFilters: adapterProductFilters.setAll(response.result.entity.filters, state.productFilters),
                sortingEnum:response.result.entity.sortingEnum
            };
        }
    }),

    ...CatalogActions.fetchProductsForCrossSell.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                productCrossSell:null
            };
        },
        onSuccess: (state, response): CatalogState => {
            // console.log(response)
            return {
                ...state,
                productsCrossSell: {
                    list: [...response.result.entity.products],
                    totalRecords: response.result.totalRecords,
                    totalPages: response.result.totalPages,
                    pageNumber: response.result.pageNumber
                },
                productFiltersCrossSell: adapterProductFiltersCrossSell.setAll(response.result.entity.filters, state.productFiltersCrossSell),
                sortingEnum:response.result.entity.sortingEnum
            };
        }
    }),

    ...CatalogActions.clearProductsForCrossSell.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                productsCrossSell: {
                    list: [],
                    totalRecords:null,
                    totalPages:null,
                    pageNumber: null
                },
                productFiltersCrossSell: adapterProductFiltersCrossSell.getInitialState(),
                sortingEnum:null
            };
        }
    }),


    ...CatalogActions.fetchRelatedProducts.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            // console.log("load");
            return {
                ...state,
                relatedProducts: adapterRelatedProducts.getInitialState(),
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                relatedProducts: adapterRelatedProducts.setAll(response.products, state.relatedProducts)
            };
        }
    }),

    ...CatalogActions.fetchCrossSellGroups.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            // console.log("load");
            return {
                ...state,
                crossSellGroups: adapterCrossSellGroups.getInitialState(),
                productCrossSell:null
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                crossSellGroups: adapterCrossSellGroups.setAll(response.crossSellGroups, state.crossSellGroups)
            };
        }
    }),

  
    ...CatalogActions.fetchProduct.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                // product: null,
                carriersByProduct:{carriers:adapterCarriersByProduct.getInitialState(), country:null, zipCode:null}
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                product: { ...response.product }
            };
        }
    }),

    
    ...CatalogActions.fetchProductForCrossSell.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                //  productCrossSell: null
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                productCrossSell: { ...response.product }
            };
        }
    }),

    ...CatalogActions.fetchProductForModify.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                //  productEdit: null
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                productEdit: { ...response.product }
            };
        }
    }),


    ...CatalogActions.fetchProductsBestSeller.reducer<CatalogState>({
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                productsBestSeller: adapterProductsBestSeller.setAll(response.products, state.productsBestSeller)
            };
        }
    }),

    ...CatalogActions.notifyStock.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                resultNotifyStock: null
            };
        },
        onSuccess: (state, response): CatalogState => {
            return {
                ...state,
                resultNotifyStock: response.result
            };
        }
    }),


    ...CatalogActions.fetchProductReviews.reducer<CatalogState>({
        onLoad: (state, response): CatalogState => {
            return {
                ...state,
                productReviews: null
            };
        },
        onSuccess: (state, response): CatalogState => {
            // console.log(response)
            return {
                ...state,
                productReviews: response.reviews
            };
        }
    }),


);
