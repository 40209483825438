import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of, pairwise, Subscription } from 'rxjs';
import { DocumentRow } from 'src/app/models/document-row.model';
import { ObjVariant, Product, VariantDetails } from 'src/app/models/product.model';
import { BackgroundService } from 'src/app/services/background.service';
import { CustomToastrService } from 'src/app/services/custom-toastr.service';
import { CartFacade } from 'src/app/store/cart/cart.facade';
import { CatalogFacade } from 'src/app/store/catalog/catalog.facade';
import { environment } from 'src/environments/environment';
import { BsModifyProductComponent } from '../bs-modify-product/bs-modify-product.component';
import { DialogModifyProductComponent } from '../dialog-modify-product/dialog-modify-product.component';

@Component({
  selector: 'app-modify-product-content',
  templateUrl: './modify-product-content.component.html',
  styleUrls: ['./modify-product-content.component.scss']
})
export class ModifyProductContentComponent implements OnInit, OnDestroy {


  @Input() dialogRef!: MatDialogRef<DialogModifyProductComponent>;
  @Input() bsRef!: MatBottomSheetRef<BsModifyProductComponent>;
  @Input() documentRow!: DocumentRow;
  @Input() productVariants!: ObjVariant[];
  @Input() addToCart: boolean = false;

  product!: Product;
  selectedVariantDetails!: VariantDetails;

  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  subsciptions: Subscription[] = [];

  constructor(
    public catalogFacade: CatalogFacade,
    public cartFacade: CartFacade,
    private media: MediaMatcher,
    private cdr: ChangeDetectorRef,
    private toastr: CustomToastrService,
    private router: Router,
    public backgroundService: BackgroundService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 576px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);


  }

  ngOnInit(): void {
    if (this.documentRow) {
      this.catalogFacade.fetchProductForModify({ productId: this.documentRow.productId });

      this.subsciptions.push(...[
        this.catalogFacade.getProductForModify()
          .subscribe(product => {
            this.product = product!;

            if (product) {
              this.productVariants = [];

              product.productVariants.forEach(pv => {

                let variants = [...pv.variants];

                const productVariant: ObjVariant = { ...pv, variants: variants.sort((a, b) => a.designation > b.designation ? 1 : -1) };
                this.productVariants.push(productVariant);
              });
            }
          }),
        this.cartFacade.getReplaceProductInCartState()
          .pipe(pairwise())
          .subscribe(([prevState, nextState]) => {
            if (nextState && nextState.success) {
              this.onClose();
              this.toastr.success('L\'article a été modifié avec succès');
            }
          })
      ]);
    }


  }

  ngOnDestroy(): void {
    this.subsciptions.forEach(s => s.unsubscribe());
    this.subsciptions = [];
  }

  onSelectVariant(variantDetails: VariantDetails) {
    if (this.documentRow) {
      this.catalogFacade.fetchProductForModify({ productId: variantDetails.productId });
    }
    else {
      if (this.addToCart) {
        // this.cartFacade.addToCart({ productId: variantDetails.productId, quantity: 1 });
      }
      // this.router.navigate([variantDetails.productLink]);
      this.selectedVariantDetails = variantDetails;
      // this.onClose();
    }
  }

  getProductBgColor(variantDetails: VariantDetails, p1 = false) {
    const color = this.backgroundService.getBackgroundColor(variantDetails.values, this.mobileQuery.matches, p1)!;
    return color;
  }

  onReplaceProduct() {
    if (this.documentRow) {
      this.cartFacade.replaceProductInCart({ oldProductId: this.documentRow.productId, newProductId: this.product.productId });
    }
  }

  onAddToCart() {
    if (this.selectedVariantDetails) {
      if (this.addToCart) {
        this.cartFacade.addToCart({ productId: this.selectedVariantDetails.productId, quantity: 1 });
      }

      this.router.navigate([this.selectedVariantDetails.productLink],{
        queryParamsHandling:'preserve'
      });
      this.onClose();
    }
  }

  onClose() {
    if (this.dialogRef) this.dialogRef.close();
    if (this.bsRef) this.bsRef.dismiss();
  }
}
