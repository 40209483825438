import { Injectable } from "@angular/core";

export enum StarFillType {
    full,
    half,
    empty
}


@Injectable({
    providedIn: 'root'
})
export class RatingService {


    public starFillType(target: number, average: number): StarFillType {
        if (average >= target - 0.25) {
            return StarFillType.full;
        }
        else if (average >= target - 0.75) {
            return StarFillType.half;
        }
        else {
            return StarFillType.empty;
        }
    }

    public starFillTypeByString(target: number, value: string): StarFillType {
        value = value.replace(',','.');
    
        const average = +value;
        if (average >= target - 0.25) {
            return StarFillType.full;
        }
        else if (average >= target - 0.75) {
            return StarFillType.half;
        }
        else {
            return StarFillType.empty;
        }
    }

}