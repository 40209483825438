import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter, pairwise, Subscription } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';


import { PermissionsService } from '../../../services/permissions.service';
import { AuthFacade } from '../../../store/authentification/auth.facade';
import { SharedFacade } from '../../../store/shared/shared.facade';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';

import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';


import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SideNavService } from 'src/app/services/sidenav.service';
import { DialogChooseVehiculeComponent } from '../../shared/choose-vehicule/dialog-choose-vehicule/dialog-choose-vehicule.component';
import { CartFacade } from 'src/app/store/cart/cart.facade';
import { DialogSearchComponent } from '../dialog-search/dialog-search.component';
import { CatalogFacade } from 'src/app/store/catalog/catalog.facade';

@Component({
  selector: 'app-navigation-top',
  templateUrl: './navigation-top.component.html',
  styleUrls: ['./navigation-top.component.scss']
})
export class NavigationTopComponent implements OnInit, OnDestroy {

  @Output() onOpenNavMobile = new EventEmitter<any>();

  @Input() navForProduct: boolean = false ;
  @Input() drawerCart!: MatSidenav ;
  @Input() drawerSideNav!: MatSidenav ;
  @Input() drawerMenu!: MatSidenav ;
  
  languages: {  language: string, code: string }[] = [];
  themes: { id: number,class: string, name: string }[] = [
    {id: 0, class: "", name:"None"},
    {id: 1, class: "bg-event-1", name:"basic-1"},
    {id: 2, class: "bg-event-2", name:"basic-2"},
    {id: 3, class: "bg-event-soldes", name:"Soldes"},
    {id: 4, class: "bg-event-new-year", name:"new year"},
    {id: 5, class: "bg-event-chinese-year", name:"ch year"},
    {id: 6, class: "bg-event-winter", name:"winter"},
    {id: 7, class: "bg-event-spring", name:"spring"},
    {id: 8, class: "bg-event-summer", name:"summer"},
    {id: 9, class: "bg-event-back-to-school", name:"BtoSc"},
    {id: 10, class: "bg-event-halloween", name:"halloween"},
    {id: 11, class: "bg-event-black-friday", name:"B Friday"},
    {id: 12, class: "bg-event-cyber-monday", name:"C Monday"},
    {id: 13, class: "bg-event-french-days", name:"F Days"},
    {id: 14, class: "bg-event-christmas", name:"christmas"},
    {id: 15, class: "bg-grey", name:"Gris"},
    {id: 16, class: "bg-grey-light", name:"Gris clair"},
    {id: 17, class: "bg-grey-dark", name:"Gris foncé"},
    {id: 18, class: "bg-grey-custom", name:"Gris autre"},
  ];
  selectedTheme =this.themes[0];
  
  selectedLanguageCode = 'FR';

  // category!:Category;
  // blockBanner!:Block;

  cartProOpened = false;

  numberProductRequested: number = 0;

  isAuthenticated = false;
  isAdmin = false;
  typeClient = true;
  nameUser!: string;
  companyName!: string;

  loginAs = false;
  customerId!: number;

  formSearch = new FormGroup({
    search:new FormControl(null,[Validators.required])
  });

  subscriptions: Subscription[] = [];

  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private sharedFacade: SharedFacade,
    public catalogFacade:CatalogFacade,
    // private blocksFacade:BlocksFacade,
    private authFacade: AuthFacade,
    private cartFacade:CartFacade,
    // private manageFacade:ManageFacade,
    private permissionsService: PermissionsService,
    public translateService:TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private eRef: ElementRef,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    public sideNavService:SideNavService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 1200px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener); 
  }
  ngAfterContentInit(): void {
  }

  ngOnInit(): void {
     this.cartFacade.fetchCart({ setCart: true, calcShipping: false, calcDiscount: true, includeCarriers: false, includeGateways: false, validate:false });

     if(isPlatformBrowser(this.platformId)){
      this.selectedTheme = this.themes.find(x => x.class == localStorage.getItem('theme'))!;

     }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((e: any) => {

      if (isPlatformBrowser(this.platformId)) {
        // setTimeout(() => {
        //   document.getElementById('site-content')?.scrollIntoView({ behavior: 'smooth' });
        // }, 10);

        document.getElementById('navbarMobile')?.classList.remove('show');
        const menu = document.getElementById("menu");
        menu?.classList.add('icon-menu');
        menu?.classList.remove('icon-x');
        this.onOpenNavMobile.emit(false);
        
      }
       this.drawerMenu.close();
       
    });

    this.languages.push({ language: 'Français', code: 'fr-fr' }); // temp 

    // this.sharedFacade.fetchWebsiteLanguages({});
    // this.sharedFacade.getWebsiteLanguages()
    //   .subscribe(languages => {
    //     this.languages = [];
    //     if(languages){
    //       languages.forEach(lang => {
    //         const langSplit = lang.split('(');
    //       //  this.languages.push({language:langSplit[0], code: langSplit[1].substring(0,5)});
    //       });
    //     }
    //   });

    this.subscriptions.push(...[
      this.route.queryParams.subscribe((params:Params) => {
        if(params['q']){
          this.formSearch.setValue({search:params['q']});
          this.onSearch();
        }
      }),

      this.authFacade.getIsAuthenticated()
        .subscribe(isAuth => {
          this.isAuthenticated = isAuth;
          this.isAdmin = this.permissionsService.isInRole('admin');
          this.companyName = '';

        }),

      this.authFacade.getUser().subscribe(user => {
        this.loginAs = false;
        if (user) {
          this.nameUser = user.firstName + ' ' + user.lastName.toUpperCase();

          this.loginAs = user.loginAs!;
          this.customerId = user.customerId!;
        }
      }),

      this.authFacade.getLogoutState()
      .pipe(pairwise())
      .subscribe(([prevState, nextState]) => {
        if(nextState && nextState.success){
          // this.cartFacade.fetchCart({ setCart: true, calcShipping: false, calcDiscount: true, includeCarriers: false, includeGateways: false, validate:false });
        }
      }),

      this.sharedFacade.getCurrentLanguage()
        .subscribe(lang => {
          if (lang)
            this.selectedLanguageCode = lang.substring(0, 2).toUpperCase()!;
        }),

      // this.blocksFacade.getFetchBlockState()
      //   .pipe(pairwise())
      //   .subscribe(([prevState, nextState]) => {
      //     if (nextState && nextState.success) {
      //       this.blocksFacade.getBlocks().subscribe(blocks => {
      //         if (blocks) {
      //           this.blockBanner = blocks.filter(x => x.blocType == BlockType.bannerDesktop)[0]!;
      //         }
      //       });
      //     }
      //   }),

      this.sharedFacade.getCurrentLanguage()
        .subscribe(lang => {
          if ((lang && lang != '')) {
            //  this.catalogFacade.fetchCategories({});
          }
        }),

      this.cartFacade.getCart().subscribe(cart => {
        if (cart) {
          this.numberProductRequested = cart.numberProductRequested;
        }
        else {
          this.numberProductRequested = 0;
        }
      }),

      this.authFacade.getLogoutState()
      .pipe(pairwise())
      .subscribe(([prevState, nextState]) => {
        if(nextState && nextState.success){
          this.cartFacade.fetchCart({ setCart: true, calcShipping: false, calcDiscount: true, includeCarriers: false, includeGateways: false, validate:false });
        }
      }),

      

    ]);
  }


  navigate(languageCode: string) {
    const path = this.router.url.substring(7, this.router.url.length);
    const url = `/${languageCode.toLowerCase()}${path.length > 0 ? '/' : ''}${path}`
    // console.log(path, url);
    this.selectedLanguageCode = languageCode.substring(0, 2).toUpperCase();
    this.router.navigateByUrl(url);
    // window.location.href = url;

  }

  clicSurMenu() {
    if (isPlatformBrowser(this.platformId)) {
      const menu = document.getElementById("menu");
      if (menu?.classList.contains('icon-menu')) {
        menu?.classList.remove('icon-menu');
        menu?.classList.add('icon-x');
        this.onOpenNavMobile.emit(true);
      }
      else {
        menu?.classList.remove('icon-x');
        menu?.classList.add('icon-menu');
        this.onOpenNavMobile.emit(false);
      }

    }
    //const container = document.getElementById("body");
    //if (container?.classList.contains('no-scroll')) {
    //  container?.classList.remove('no-scroll');
    //}
    //else {
    //  container?.classList.add('no-scroll');
    //}
  }


  onLogout() {
    this.authFacade.logout({});
  }

  onOpenDialogDooFinder(){
    // if(!this.ctrlSearch.value ) return;
    // console.log("ok")
    //const dialogRef = this.dialog.open(DialogDoofinderComponent, {
    //  panelClass: ['bg-white'],
    //  width:'1200px',
    //  maxHeight: '80vh',
    //  data:{query:this.ctrlSearch.value}
    //});
    // if (!this.mobileQuery.matches) {
    //   const dialogRef = this.dialog.open(DialogDoofinderComponent, {
    //     panelClass: ['bg-white'],
    //     width: '1200px',
    //     maxHeight: '80vh',
    //     data: { query: this.ctrlSearch.value }
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //   });
    // }
    // else {
    //   const bsRef = this.bottomSheet.open(BsDoofinderComponent, {
    //     panelClass: ['mh-100'],
    //     data: { query: this.ctrlSearch.value }
    //   });
    // }

    const dialogRef = this.dialog.open(DialogSearchComponent, {
      panelClass: ['bg-white'],
      width: '90vw',
      position:{top: '65px'} 

    });

    dialogRef.afterClosed().subscribe(result => {
    });

    // console.log(dialogRef)
  }

  onSearch(){
    this.router.navigate(['/', this.translateService.currentLang, 'catalog' , 'search'], 
    {queryParams:{q:this.formSearch.value.search},queryParamsHandling:'merge'});
  }

  openDialogChooseVehicule(){
    this.dialog.open(DialogChooseVehiculeComponent, {
      panelClass: ['choose-vehicule-dialog'],
      backdropClass: 'backdrop-bg',
      width:this.mobileQuery.matches ? '100vw' : '90%',
      maxWidth:'100vw'
    });
    
  }

  onChangeTheme(id:number){
    this.selectedTheme = this.themes.find(x =>x.id == id)!;
    if(this.selectedTheme)
      localStorage.setItem('theme', this.selectedTheme.class);

  }

  ngOnDestroy(): void {
   this.subscriptions.forEach(s => {
    s.unsubscribe();
   });
   this.subscriptions = [];
  }


}
