import { Component } from '@angular/core';

@Component({
  selector: 'app-item-product-loader',
  templateUrl: './item-product-loader.component.html',
  styleUrls: ['./item-product-loader.component.scss']
})
export class ItemProductLoaderComponent {

}
