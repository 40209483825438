import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthFacade } from '../../../store/authentification/auth.facade';
import { SharedFacade } from '../../../store/shared/shared.facade';
import { environment } from '../../../../environments/environment';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    token = '';
    culture = 'fr-fr/';


    constructor(public authFacade: AuthFacade,
        private sharedFacade: SharedFacade,
        @Inject(PLATFORM_ID) private platformId: any,) {
        this.authFacade.getUser().subscribe(user => {
            this.token = user?.token!;
        });


    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        if (request.url.includes(environment.API_BASE_URL)) {
            // this.sharedFacade.getCurrentLanguage()
            // .subscribe(lang => {
            //     this.culture = lang! + '/';
            //     // console.log(this.culture);
            // });

            let url = request.url.replace(environment.API_BASE_URL, '');
            url = environment.API_BASE_URL + environment.API_VERSION + this.culture + url;

            request = request.clone({
                url: url
            });
        }


        if (request.url.startsWith('/assets/i18n/') && isPlatformServer(this.platformId)) {
            let url = environment.FRONT_BASE_URL + request.url;
            request = request.clone({
                url: url
            });
        }
        if (request.url.startsWith(environment.API_BASE_URL)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.token}`,
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    Pragma: 'no-cache',
                    Expires: '0',
                    shopId: '1'
                },
            });
        }

        const random = Math.random();

        request = request.clone({
            params:request.params.append('random', random)
        });

        //  console.log(request.url);

        return next.handle(request);
    }
}