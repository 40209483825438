import { Pipe, PipeTransform } from '@angular/core';
import { ProductFilter } from 'src/app/models/product-filter.model';

@Pipe({
  name: 'getNumberActiveFilters'
})
export class GetNumberActiveFiltersPipe implements PipeTransform {

  transform(selectedFilters: ProductFilter[], ...args: number[]): number {
    var selectedFilter = selectedFilters.find(x => x.filterId == args[0])
    if (selectedFilter)
      return selectedFilter.values.length;
    else
      return 0;
  }

}
