import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'getFormControl'
})
export class GetFormControlPipe implements PipeTransform {

  transform(formGroup: FormGroup, ...args: string[]): FormControl {
    return formGroup.get(args[0]) as FormControl<any>
  }

}
