<!-- Attention pas possible de mettre du padding en bas car drawer trop petit -->
<div *ngIf="loadedComponenet" class="container d-flex flex-column drawer">
  <!-- TITLE -->
  <div class="d-flex flex-column m-3">
    <div class="row m-0 pl-2">
      <ng-container *ngIf="cart && cart.numberProductRequested > 0">
        <h2 class="mr-2 font-weight-semibold f-size-26px text-black">{{ modeEdit ? 'Commande' : ('cart' | translate)}}
        </h2>
        <p class="m-0 f-size-20px font-weight-light font-italic">
          ({{cart.numberProductRequested}} {{cart.numberProductRequested == 1 ? ('article' | translate) : ('articles' |
          translate) }})
        </p>
      </ng-container>

      <h2 *ngIf="!cart || (cart && cart.numberProductRequested == 0)"
        class="mr-2 font-weight-semibold f-size-26px text-black">{{'cartIsEmpty' | translate}} !</h2>

      <button (click)="drawerCart.toggle()" class="ml-auto border-0 bg-white">
        <span class="k-x f-size-28px"></span>
      </button>
    </div>
  </div>

  <div class="d-flex flex-column mx-3 mb-3 mt-0 h-auto minh-100%">
    <!-- Product Container -->
    <div class="drawer-list pr-1 mb-5 mb-md-0 pb-4 pb-md-0" *ngIf="cart">
      <div class="mt-2" *ngFor="let row of cart.rows; let first = first;">

        <hr *ngIf="!row.canBeCrossSelled && row.documentRowParentId == -1" />
        <hr class="mt-1 mb-2 border-0" *ngIf="(row.documentRowParentId != -1 || row.canBeCrossSelled)" />

        <!-- <hr *ngIf="!first && (row.rowType == '0' || row.rowType == '8')  && row.documentRowParentId <= 0" /> -->

        <div class="row m-0 w-100" *ngIf="(row.rowType == '0' || row.rowType == '8')">
          <div class="col-2 px-0" *ngIf="row.documentRowParentId == -1">
            <a [routerLink]="row.linkProduct">
              <img class="product-img w-100 cursor-pointer" loading="lazy" [alt]="row.designation"
                [title]="row.designation" [src]="row.pictureUrlSmall" />
            </a>
          </div>
          <div class="col-2 px-0 pl-2" *ngIf="row.documentRowParentId > 0">
            <a [routerLink]="row.linkProduct">
              <img class="product-img w-100 cursor-pointer" loading="lazy" [alt]="row.designation"
                [title]="row.designation" [src]="row.pictureUrlSmall" />
            </a>
          </div>
          <div class="col-10 px-0 mx-0 row">
            <div class="pl-3 col-xl-8 col-12">
              <p class="m-0 line-2">
                <a [routerLink]="row.linkProduct" class="f-size-md-16px f-size-13px text-black font-weight-medium"
                  (click)="drawerCart.close()">
                  {{row.designation}}
                </a>
              </p>
              <div class="w-100">
                <p class="mb-1 line-height-1">
                  <span class="bg-main-gradient px-1 pb-1 rounded w-auto" *ngIf="row.crossSellDiscount > 0">
                    <span class="f-size-10px">{{'groupBuy' | translate}} : </span>
                    <span class="f-size-10px font-weight-bold">
                      <span class="f-size-10px" [innerHTML]="row.crossSellDiscount  | formatPrice"></span>
                      {{'ofDiscount' | translate}}
                    </span>
                  </span>
                </p>
              </div>
              <div class="d-flex w-100" *ngIf="row.rowType == '0' || row.rowType == '8'">
                <p class="my-auto font-weight-bold f-size-16px f-size-md-22px mr-2"
                  [innerHTML]="(typeClient ? row.priceTotalRequestedTtc : row.priceTotalRequestedHt) | formatPrice"
                  [class.text-danger]="row.percentOff > 0">
                </p>

                <p *ngIf="row.percentOff > 0"
                  class="my-auto text-decoration-linethrough text-grey-dark f-size-12px f-size-md-20px mr-2"
                  [innerHTML]="(typeClient ? row.priceValueTtcOff * row.quantityRequested : row.priceValueHtOff * row.quantityRequested) | formatPrice">
                </p>

                <p *ngIf="row.percentOff > 0"
                  class="f-size-12px f-size-md-16px font-weight-bold rounded text-center px-1 my-auto ml-1 border-1-5px border-grey">
                  -{{row.percentOff}}%</p>
              </div>

              <div class="w-100">
                <p *ngIf=" row.details && row.details != 'null'"
                  class="my-auto text-danger f-size-md-14px f-size-12px text-danger"
                  [innerHTML]="row.details | safe:'html'">
                </p>
              </div>
            </div>

            <div class="row mx-0 px-0 justify-content-end col-xl-4 col-12 mt-1"
              *ngIf="(row.rowType == '0' || row.rowType == '8') && !mobileQuery.matches">
              <div class="w-100 mb-2">
                <div class="row mx-0 my-md-auto my-2 justify-content-center">
                  <button *ngIf="!row.isCartEdit" (click)="onAddRemoveQuantity(row.documentRowId, -1)"
                    class="border-0 rounded-pill p-0 button-addremove d-flex">
                    <span class="f-size-md-18px f-size-24px m-auto k-minus"></span>
                  </button>
                  <input [readonly]="row.isCartEdit" type="number" [value]="row.quantityRequested" placeholder="0"
                    pattern="[0-9]*" inputmode="numeric" (input)="onChangeQuantity(row.documentRowId, $event)"
                    class="font-weight-bold f-size-16px text-black w-100 px-0 mx-2 text-center rounded border-grey mw-40px" />
                  <button *ngIf="!row.isCartEdit" (click)="onAddRemoveQuantity(row.documentRowId, 1)"
                    class="border-0 rounded-pill p-0 button-addremove d-flex">
                    <span class="f-size-md-18px f-size-24px m-auto k-plus"></span>
                  </button>
                </div>
              </div>
              <button *ngIf=" row.productVariantsCount > 0" (click)="onOpenDialogModifyProduct(row)"
                class="f-size-12px btn col-xl-12 col-6 mt-auto edit-button">
                <span class="k-edit mr-1 text-decoration-none text-grey-dark"></span><span
                  class="f-size-14px font-weight-medium text-decoration-underline text-grey-dark">{{'modify' |
                  translate}}</span>
              </button>
            </div>

            <div class="row mx-0 px-0 justify-content-end col-xl-4 mt-1"
              *ngIf="(row.rowType == '0' || row.rowType == '8') && mobileQuery.matches">
              <div class="col-5">
                <button *ngIf=" row.productVariantsCount > 0" (click)="onOpenDialogModifyProduct(row)"
                  class="f-size-12px btn edit-button">
                  <span class="k-edit mr-1 text-decoration-none text-grey-dark"></span><span
                    class="f-size-14px font-weight-medium text-decoration-underline text-grey-dark">{{'modify' |
                    translate}}</span>
                </button>
              </div>
              <div class="col-7">
                <div class="w-100">
                  <div class="row mx-0 my-md-auto justify-content-end">
                    <button (click)="onAddRemoveQuantity(row.documentRowId, -1)"
                      class="border-0 rounded-pill p-0 button-addremove d-flex">
                      <span class="f-size-md-18px f-size-24px m-auto k-minus"></span>
                    </button>
                    <input type="number" [value]="row.quantityRequested" placeholder="0" pattern="[0-9]*"
                      inputmode="numeric" (input)="onChangeQuantity(row.documentRowId, $event)"
                      class="font-weight-bold f-size-16px text-black px-0 mx-2 text-center rounded mw-40px border-grey" />
                    <button (click)="onAddRemoveQuantity(row.documentRowId, 1)"
                      class="border-0 rounded-pill p-0 button-addremove d-flex">
                      <span class="f-size-md-18px f-size-24px m-auto k-plus"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Pack -->
          <ng-container *ngIf="showPack[row.documentRowId]">
            <div class="w-100 row mt-1 py-1 mx-0 px-0"
              *ngFor="let childRow of getRowChildrenOfPack(row.documentRowId); let i = index;">
              <div class="col-2 text-center">
              </div>
              <div class="col-10">
                <div class="row">
                  <div class="col-1 px-0">
                    <img class="product-img w-100" loading="lazy" [src]="childRow.pictureUrlSmall"
                      [alt]="childRow.designation" [title]="childRow.designation" />
                  </div>
                  <div class="col-11 px-0 mx-0 row">
                    <div class="pl-3 col-xl-12 col-12 my-auto">
                      <p class="m-0 line-height-1">
                        <a [routerLink]="childRow.linkProduct"
                          class="f-size-md-12px f-size-11px text-black font-weight-medium" (click)="drawerCart.close()">
                          {{childRow.designation}}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- COUPON -->
          <div class="w-100 row mt-1 mx-0 bloc-coupon" *ngFor="let coupon of row.appliedCoupons">
            <div class="row col-1 ml-0 mr-0 my-auto justify-content-center">
              <span class="k-tag f-size-20px"></span>
            </div>
            <div class="col-10 my-auto">
              <p class="mb-0 font-weight-bold f-size-14px line-2">{{'couponDiscountIncluded' | translate}} (<span
                  [innerHTML]="coupon.value | formatPrice" class=" text-coupon"></span>) </p>
              <p class="mb-0 f-size-12px">{{coupon.couponCode}}</p>
            </div>
            <div class="row col-1 my-auto justify-content-center">
              <span (click)="onAddRemoveFromCart(coupon.couponRowId, -row.quantityRequested)"
                class="k-trash f-size-20px cursor-pointer"></span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <p *ngIf="cart">{{cart.message}}</p>

    <!-- SUM -->
    <ng-container *ngIf="!mobileQuery.matches && cart && cart.numberProductRequested > 0 && hasCartProducts">
      <div class="d-flex flex-column m-0 px-3 mb-1 bg-grey-light">
        <div>
          <p class="m-0 d-flex align-items-center justify-content-center f-size-14px">{{'subTotal' | translate}} : <span
              class="ml-1 font-weight-bold my-auto px-0 f-size-18px" *ngIf="cart"
              [innerHTML]="(typeClient ? cart.totalWoShipTtc : cart.totalWoShipHt) | formatPrice"></span></p>
        </div>
      </div>

      <div class="pb-2">
        <button class="btn-theme buybutton align-items-center to-bounce" (click)="onGoToCheckout()">
          <span class="m-0 font-weight-semibold text-center my-auto">
            {{'proccedPaiement' | translate}}
          </span><span class="k-basket font-weight-normal bounce-item my-auto ml-2"></span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div class="w-100 btn-fixed-mobile z-index-3 bg-white"
  *ngIf="mobileQuery.matches && cart && cart.numberProductRequested > 0 && hasCartProducts">
  <div class="d-flex flex-column m-0 px-3 py-1 bg-grey-light">
    <div>
      <p class="m-0 d-flex align-items-center justify-content-center f-size-14px">{{'subTotal' | translate}} : <span
          class="ml-1 font-weight-bold my-auto px-0 f-size-18px"
          [innerHTML]="(typeClient ? cart.totalWoShipTtc : cart.totalWoShipHt) | formatPrice"></span></p>
    </div>
  </div>

  <button class="btn-theme justify-content-center" (click)="onGoToCheckout()">
    <span class="font-weight-semibold m-0">{{'proccedPaiement' | translate}}</span>
  </button>
</div>
