import { createReducer } from "@ngrx/store";
import { getInitialState, WithLoadingStates } from "ngrx-loading-state";

import * as ScrollActions from './scroll.actions';


export const SCROLL_FEATURE_KEY = 'scroll';


export type ScrollState = WithLoadingStates & {
    scrollValue:number,
};


export const initialState: ScrollState = {
    ...getInitialState(),
    scrollValue:0,
};


export const scrollReducer = createReducer(
    initialState,
    ...ScrollActions.scroll.reducer<ScrollState>({
        onLoad: (state, response): ScrollState => {
            return {
                ...state,
                scrollValue:response.value
            };
        }
    }),
   
);
