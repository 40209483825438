import { createLoadingActions, failure, load, success } from "ngrx-loading-state";


export const imageViewerOpened = createLoadingActions(
    '[IMAGE VIEWER] Image Viewer Opened ',
    load<{opened: boolean}>(),
    success(),
    failure()
);

