import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, of } from 'rxjs';

import * as CatalogActions from './catalog.actions';

import { BaseResult } from "../../models/results/base-result.model";
import { environment } from "../../../environments/environment";
import { SharedFacade } from '../shared/shared.facade';

@Injectable()
export class CatalogEffects {

    culture = '';

    fetchAttributs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchAttributs.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/attributs'
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchAttributs.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchAttributs.success({ attributs: result.entity });
                        }),
                        CatalogActions.fetchAttributs.catchError()

                    )
            }),
        )
    });

    fetchProductFilters$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProductFilters.load),
            mergeMap((options) => {

                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/productFilters',
                    {
                        params: new HttpParams().append('productIdsStr', '1,2,3')
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchProductFilters.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchProductFilters.success({ filters: result.entity });
                        }),
                        CatalogActions.fetchProductFilters.catchError()
                    )
            })
        )
    });

    fetchCategories$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchCategories.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/categories-tree'
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchCategories.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchCategories.success({ categories: result.entity });
                        }),
                        CatalogActions.fetchCategories.catchError()
                    )
            }),
        )
    });

    fetchCategoriesHome$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchCategoriesHome.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/categories-tree'
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchCategoriesHome.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchCategoriesHome.success({ categories: result.entity });
                        }),
                        CatalogActions.fetchCategoriesHome.catchError()
                    )
            }),
        )
    });

    fetchCategoriesSameParent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchCategoriesSameParent.load),
            mergeMap((options) => {
                // console.log(options.categoryId)

                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/categories-same-parent',
                    {
                        params: new HttpParams().append('categoryId', options.categoryId.toString())
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchCategoriesSameParent.failure({ errors: result.errors, error: 'error' });


                            return CatalogActions.fetchCategoriesSameParent.success({ categories: result.entity });
                        }),
                        CatalogActions.fetchCategoriesSameParent.catchError()
                    )
            }),
        )
    });

    fetchCategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchCategory.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/category',
                    {
                        params: new HttpParams().append('categoryid', options.categoryId!.toString())
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchCategory.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchCategory.success({ category: result.entity });
                        }),
                        CatalogActions.fetchCategory.catchError()
                    )
            }),
        )
    });

    fetchCrossSellGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchCrossSellGroups.load),
            mergeMap((options) => {

                let params = new HttpParams();
                if (options.categoryId) params = params.append('categoryId', options.categoryId.toString());

                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/cross-sell-groups',
                    {
                        params: params
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchCrossSellGroups.failure({ errors: result.errors, error: 'error' });
                            // console.log(result)
                            return CatalogActions.fetchCrossSellGroups.success({ crossSellGroups: result.entity });

                        }),
                        CatalogActions.fetchCrossSellGroups.catchError()

                    )
            }),
        )
    });

    fetchMicroFiches$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchMicroFiches.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/microfiches',
                    {
                        params: new HttpParams().append('categoryid', options.categoryId!.toString())
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchMicroFiches.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchMicroFiches.success({ microFiches: result.entity });
                        }),
                        CatalogActions.fetchMicroFiches.catchError()
                    )
            }),
        )
    });

    fetchBrands$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchBrands.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/brands'
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchBrands.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchBrands.success(result.entity);
                        }),
                        CatalogActions.fetchBrands.catchError()
                    )
            }),
        )
    });

    fetchBrand$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchBrand.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/brand',{
                    params: new HttpParams().append('brandId', options.brandId.toString())
                }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchBrand.failure({ errors: result.errors, error: 'error' });
                            
                            return CatalogActions.fetchBrand.success({brand:result.entity});
                        }),
                        CatalogActions.fetchBrand.catchError()
                    )
            }),
        )
    });

    fetchProducts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProducts.load),
            mergeMap((options) => {
                //  console.log(options.search)
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'store/products',
                    {
                        "categoriesIds": options.categoriesIds,
                        "brandId": options.brandId,
                        "paginationFilter": {
                            "pageNumber": options.page,
                            "pageSize": options.pageSize
                        },
                        "filters": options.filters,
                        "sortingEnum": options.sortingEnumId,
                        "attributValues": options.attributsValues,
                        "nbColumns": options.nbColumns,
                        "search": options.search,
                        "attributIdToDisplayContainer": environment.ATTRIBUTS.COLOR
                    }
                )
                    .pipe(
                        map(result => {
                            // console.log(result)
                            if (!result.isValid)
                                return CatalogActions.fetchProducts.failure({ errors: result.errors, error: 'error' });
                            return CatalogActions.fetchProducts.success({ result: result });
                        }),
                        CatalogActions.fetchProducts.catchError()
                    )
            }),
        )
    });

    fetchProductsForCrossSell$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProductsForCrossSell.load),
            mergeMap((options) => {
                if (!options.page) return of();
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'store/products',
                    {
                        "categoriesIds": options.categoriesIds,
                        "paginationFilter": {
                            "pageNumber": options.page,
                            "pageSize": options.pageSize
                        },
                        "filters": options.filters,
                        "sortingEnum": options.sortingEnumId,
                        "attributValues": options.attributsValues,
                        "nbColumns": options.nbColumns,
                        "crossSell": true,
                        "productForCrossSell": options.productForCrossSell,
                    }
                )
                    .pipe(
                        map(result => {
                            // console.log(result)
                            if (!result.isValid)
                                return CatalogActions.fetchProductsForCrossSell.failure({ errors: result.errors, error: 'error' });
                            return CatalogActions.fetchProductsForCrossSell.success({ result: result });
                        }),
                        CatalogActions.fetchProductsForCrossSell.catchError()
                    )
            }),
        )
    });

    fetchProductsByCategories$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProductsByCategories.load),
            mergeMap((options) => {
                let params = new HttpParams();
                if (options.categoriesIds) params = params.append('categoriesIds', options.categoriesIds.join(','));
                if (options.page) params = params.append('pageNumber', options.page!.toString());
                if (options.pageSize) params = params.append('pageSize', options.pageSize!.toString());
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/products-by-categories',
                    {
                        params: params
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchProductsByCategories.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchProductsByCategories.success({ result: result });
                        }),
                        CatalogActions.fetchProductsByCategories.catchError()
                    )
            }),

        )
    });

    fetchCarriersByProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchCarriersByProduct.load),
            mergeMap((options) => {
                let params = new HttpParams();
                if (options.productId) params = params.append('productId', options.productId.toString());
                if (options.country) params = params.append('country', options.country!.toString());
                if (options.zipCode) params = params.append('zipCode', options.zipCode!.toString());
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/carriers-by-product',
                    {
                        params: params
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchCarriersByProduct.failure({ errors: result.errors, error: 'error' });

                            // console.log(result.entity)
                            return CatalogActions.fetchCarriersByProduct.success(result.entity);
                        }),
                        CatalogActions.fetchCarriersByProduct.catchError()
                    )
            }),

        )
    });


    fetchRelatedProducts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchRelatedProducts.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/related-products',
                    {
                        params: new HttpParams().append('currentProductId', options.productId.toString())
                            .append('count', options.count.toString())
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchRelatedProducts.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchRelatedProducts.success({ products: result.entity });
                        }),
                        CatalogActions.fetchRelatedProducts.catchError()
                    )
            }),
        )
    });

    fetchProductsBestSeller$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProductsBestSeller.load),
            mergeMap((options) => {
                let params = new HttpParams();
                if (options.categoryId) params = params.append('categoryId', options.categoryId);
                if (options.count) params = params.append('count', options.count);
                if (options.withCategory) params = params.append('withCategory', options.withCategory);

                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/products-best-seller',
                    {
                        params: params
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchProductsBestSeller.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchProductsBestSeller.success({ products: result.entity });
                        }),
                        CatalogActions.fetchProductsBestSeller.catchError()
                    )
            }),
        )
    });

    fetchProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProduct.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/product',
                    {
                        params: new HttpParams().append('productid', options.productId.toString()).append('attributIdToDisplayContainer', environment.ATTRIBUTS.COLOR)
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchProduct.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchProduct.success({ product: result.entity });
                        }),
                        CatalogActions.fetchProduct.catchError()
                    )
            }),
        )
    });

    fetchProductForCrossSell$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProductForCrossSell.load),
            mergeMap((options) => {
                let params = new HttpParams();
                params = params.append('productid', options.productId.toString());

                if (options.calcDiscountCrossSell) {
                    params = params.append('calcDiscountCrossSell', options.calcDiscountCrossSell);
                }
                if (options.documentRowId) {
                    params = params.append('documentRowId', options.documentRowId?.toString()!);
                }
                if (options.quantityRequested) {
                    params = params.append('quantityRequested', options.quantityRequested?.toString()!);
                }
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/product',
                    {
                        params: params,
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchProductForCrossSell.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchProductForCrossSell.success({ product: result.entity });
                        }),
                        CatalogActions.fetchProductForCrossSell.catchError()
                    )
            }),
        )
    });

    fetchProductForModify$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProductForModify.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/product',
                    {
                        params: new HttpParams().append('productid', options.productId.toString()),
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchProductForModify.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.fetchProductForModify.success({ product: result.entity });
                        }),
                        CatalogActions.fetchProductForModify.catchError()
                    )
            }),
        )
    });

    fetchProductReviews$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.fetchProductReviews.load),
            mergeMap((options) => {
                let params = new HttpParams();
                if (options.productId) params = params.append('productid', options.productId.toString());
                if (options.count) params = params.append('count', options.count?.toString());

                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/product-reviews',
                    {
                        params: params
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.fetchProductReviews.failure({ errors: result.errors, error: 'error' });
                            return CatalogActions.fetchProductReviews.success({ reviews: result.entity });
                        }),
                        CatalogActions.fetchProductReviews.catchError()
                    )
            }),
        )
    });


    notifyStock$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CatalogActions.notifyStock.load),
            mergeMap((options) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'divers/agentstock',
                    {
                        email: options.email,
                        productId: options.productId,
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CatalogActions.notifyStock.failure({ errors: result.errors, error: 'error' });

                            return CatalogActions.notifyStock.success({ result: result.entity });
                        }),
                        CatalogActions.notifyStock.catchError()
                    )
            }),
        )
    });



    ////////////////

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private sharedFacade: SharedFacade
    ) {
        sharedFacade.getCurrentLanguage()
            .subscribe(lang => {
                this.culture = lang! + '/';
            });
    }
}
