import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions,createEffect, ofType } from "@ngrx/effects";
import {  map, mergeMap } from 'rxjs/operators'

import * as SharedActions from './shared.actions';

import { BaseResult } from "../../models/results/base-result.model";
import { environment } from "../../../environments/environment";

@Injectable()
export class SharedEffects {

    fetchWebsiteLanguages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.fetchWebsiteLanguages.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'misc/get-website-languages'
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SharedActions.fetchWebsiteLanguages.failure({ errors: result.errors , error: 'error'});

                            return SharedActions.fetchWebsiteLanguages.success( {languages:result.entity});
                        }),
                        SharedActions.fetchWebsiteLanguages.catchError()
                    )
            })
        )
    });

    
    fetchGateways$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.fetchGateways.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'gateway/gateways-list'
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SharedActions.fetchGateways.failure({ errors: result.errors , error: 'error'});

                            return SharedActions.fetchGateways.success( {gateways:result.entity});
                        }),
                        SharedActions.fetchGateways.catchError()
                    )
            })
        )
    });


      
    fetchGatewayMaturities$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.fetchGatewayMaturities.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'gateway/get-maturities',
                {
                    params:new HttpParams().append('gatewayId', options.gatewayId.toString())
                }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SharedActions.fetchGatewayMaturities.failure({ errors: result.errors , error: 'error'});

                            return SharedActions.fetchGatewayMaturities.success( {maturities:result.entity});
                        }),
                        SharedActions.fetchGatewayMaturities.catchError()
                    )
            })
        )
    });

    
    fetchSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.fetchSettings.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'misc/get-settings'
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SharedActions.fetchSettings.failure({ errors: result.errors , error: 'error'});

                            return SharedActions.fetchSettings.success( {settings:result.entity});
                        }),
                        SharedActions.fetchSettings.catchError()
                    )
            })
        )
    });


    fetchCountries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.fetchCountries.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'misc/countries-list'
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SharedActions.fetchCountries.failure({ errors: result.errors , error: 'error'});

                            return SharedActions.fetchCountries.success( {countries:result.entity});
                        }),
                        SharedActions.fetchCountries.catchError()
                    )
            })
        )
    });

    addToNewsletter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.addToNewsletter.load),
            mergeMap((options) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'misc/newsletter',
                {
                    email:options.email,
                    recaptchaToken:options.recaptchaToken
                }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SharedActions.addToNewsletter.failure({ errors: result.errors , error: 'error'});

                            return SharedActions.addToNewsletter.success( {result:result.entity});
                        }),
                        SharedActions.addToNewsletter.catchError()
                    )
            })
        )
    });

    fetchWebsiteReviews$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.fetchWebsiteReviews.load),
            mergeMap((options) => {
                let params = new HttpParams();
                if(options.count) params = params.append('count', options.count?.toString());

                return this.http.get<BaseResult>(environment.API_BASE_URL + 'misc/website-reviews',
                {
                    params:params
                }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return SharedActions.fetchWebsiteReviews.failure({ errors: result.errors , error: 'error'});
                            return SharedActions.fetchWebsiteReviews.success({reviews:result.entity});
                        }),
                        SharedActions.fetchWebsiteReviews.catchError()
                    )
            }),
            
        )
    });
   

     ////////////////

     constructor(
        private actions$: Actions,
        private http: HttpClient,
    ) {
      
    }
}