import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from 'rxjs/operators'

import * as CartActions from './cart.actions';

import { BaseResult } from "../../models/results/base-result.model";
import { environment } from "../../../environments/environment";
import { Document } from "../../models/document.model";

@Injectable()
export class CartEffects {

    fetchCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.fetchCart.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'document/cart',
                    {
                        params: new HttpParams().append('setCart', options.setCart)
                            .append('calcShipping', options.calcShipping)
                            .append('calcDiscount', options.calcDiscount)
                            .append('includeCarriers', options.includeCarriers)
                            .append('includeGateways', options.includeGateways)
                            .append('validate', options.validate),
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            // console.log(result);
                            if (!result.isValid)
                                return CartActions.fetchCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.fetchCart.success({document:result.entity});
                        }),
                        CartActions.fetchCart.catchError()
                    )
            })
        )
    });

    fetchProductsRelated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.fetchProductsRelated.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'document/related-products',
                    {

                        params: new HttpParams().append('count', options.count.toString()),
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            // console.log(result);
                            if (!result.isValid)
                                return CartActions.fetchProductsRelated.failure({ errors: result.errors, error: 'error' });

                            return CartActions.fetchProductsRelated.success({ products: result.entity });
                        }),
                        CartActions.fetchProductsRelated.catchError()
                    )
            })
        )
    });

    replaceProductInCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.replaceProductInCart.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'document/replace-product',
                    {
                        params: new HttpParams().append('oldProductId', options.oldProductId.toString()!)
                            .append('newProductId', options.newProductId.toString()!),
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            // console.log(result);
                            if (!result.isValid)
                                return CartActions.replaceProductInCart.failure({ errors: result.errors, error: 'error' });

                            const documentResult = <Document>(result.entity.document);
                            const newProductIdResult = <number>(result.entity.newProductId);

                            return CartActions.replaceProductInCart.success({ document: documentResult, newProductId: newProductIdResult });
                        }),
                        CartActions.replaceProductInCart.catchError()
                    )
            })
        )
    });

    updateCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.updateCart.load),
            mergeMap((options) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'document/update-cart',
                    {
                        carrierId: options.carrierId
                    },
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.updateCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.updateCart.success(result.entity);
                        }),
                        CartActions.updateCart.catchError()
                    )
            })
        )
    });


    deleteCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.deleteCart.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'document/delete-cart',

                    {
                        params: new HttpParams().append('documentId', options.documentId),
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.deleteCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.deleteCart.success(result.entity);
                        }),
                        CartActions.deleteCart.catchError()
                    )
            })
        )
    });

    addToCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.addToCart.load),
            mergeMap((options) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'document/add-to-document',
                    {
                        productId: options.productId,
                        quantity: options.quantity,
                        // crossSellId: options.crossSellId,
                        productIdCrossSellId: options.productIdCrossSellId,
                        validateCart: options.validateCart ? options.validateCart : false,
                        documentRowParentId: options.documentRowParentId!
                    },
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.addToCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.addToCart.success(result.entity);
                        }),
                        CartActions.addToCart.catchError()
                    )
            })
        )
    });

    addRemoveFromCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.addRemoveFromCart.load),
            mergeMap((options) => {
                // console.log('reset q', options.resetQuantity);
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'document/add-remove-from-document',
                    {
                        documentRowId: options.documentRowId,
                        quantity: options.quantity,
                        resetQuantity: options.resetQuantity ? options.resetQuantity : false,
                        validateCart: options.validateCart ? options.validateCart : false
                    },
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.addRemoveFromCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.addRemoveFromCart.success(result.entity);
                        }),
                        CartActions.addRemoveFromCart.catchError()
                    )
            })
        )
    });


    addCouponToCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.addCouponToCart.load),
            mergeMap((options) => {
                // console.log(options.code);
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'document/apply-coupon',
                    {
                        code: options.code
                    },
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.addCouponToCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.addCouponToCart.success(result.entity);
                        }),
                        CartActions.addCouponToCart.catchError()
                    )
            })
        )
    });

    addCommentToCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.addCommentToCart.load),
            mergeMap((options) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'document/set-comment',
                    {
                        comment: options.comment,
                        refCommande: options.documentId
                    },
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.addCommentToCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.addCommentToCart.success(result.entity);
                        }),
                        CartActions.addCommentToCart.catchError()
                    )
            })
        )
    });

    addCommentToRowCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.addCommentToRowCart.load),
            mergeMap((options) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'document/set-comment-item',
                    {
                        comment: options.comment,
                        documentRowId: options.documentRowId
                    },
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.addCommentToRowCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.addCommentToRowCart.success(result.entity);
                        }),
                        CartActions.addCommentToRowCart.catchError()
                    )
            })
        )
    });


    setShippingAddress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.setShippingAddress.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'document/set-ship-address',
                    {
                        params: new HttpParams().append('shippingAddressId', options.shippingAddressId.toString()),
                        withCredentials: true,
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.setShippingAddress.failure({ errors: result.errors, error: 'error' });

                            return CartActions.setShippingAddress.success(result.entity);
                        }),
                        CartActions.setShippingAddress.catchError()
                    )
            })
        )
    });



    loadCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.loadCart.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'document/load-cart',
                    {
                        params: new HttpParams().append('key', options.key),
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.loadCart.failure({ errors: result.errors, error: 'error' });

                            // console.log(result);
                            return CartActions.loadCart.success(result.entity);
                        }),
                        CartActions.loadCart.catchError()
                    )
            })
        )
    });


    fetchUserAddresses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.fetchUserAddresses.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'manage/shipping-addresses',
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.fetchUserAddresses.failure({ errors: result.errors, error: 'error' });

                            return CartActions.fetchUserAddresses.success(result.entity);
                        }),
                        CartActions.fetchUserAddresses.catchError()
                    )
            })
        )
    });


    clearCart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.clearCart.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'document/clear-cart',
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.clearCart.failure({ errors: result.errors, error: 'error' });

                            return CartActions.clearCart.success(result.entity);
                        }),
                        CartActions.clearCart.catchError()
                    )
            })
        )
    });


    fetchPaymentForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.fetchPaymentForm.load),
            mergeMap((options) => {

                let params = new HttpParams();
                params = params.append('gatewayId', options.gatewayId.toString());
                params = params.append('documentId', options.documentId.toString());
                params = params.append('shippingAddressId', options.shippingAddressId.toString());

                if (options.device) params = params.append('device', options.device);

                return this.http.get<BaseResult>(environment.API_BASE_URL + 'document/get-payment-form',
                    {
                        params: params,
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.fetchPaymentForm.failure({ errors: result.errors, error: 'error' });

                            const resData = <{ form: string, paymentType: string, cart: Document }>(result.entity);
                            resData.paymentType = result.entity.type;
                            return CartActions.fetchPaymentForm.success(resData);
                        }),
                        CartActions.fetchPaymentForm.catchError()
                    )
            })
        )
    });

    checkMandat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.checkMandat.load),
            mergeMap((options) => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'gateway/check-mandat',
                    {
                        withCredentials: true
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.checkMandat.failure({ errors: result.errors, error: 'error' });

                            const resData = <{ form: string, paymentType: string, cart: Document }>(result.entity);
                            resData.paymentType = result.entity.type;
                            return CartActions.checkMandat.success(resData);
                        }),
                        CartActions.checkMandat.catchError()
                    )
            })
        )
    });


    checkoutOrder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CartActions.checkoutOrder.load),
            mergeMap((options) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'document/checkout-order',
                    {
                        documentId: options.documentId,
                        gatewayId: options.gatewayId,
                        transactionId: options.transactionId ? options.transactionId : -1,
                        info: options.info
                    },
                    {
                        withCredentials: true,
                    }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return CartActions.checkoutOrder.failure({ errors: result.errors, error: 'error' });

                            const resData = <{ cart: Document, status: string, documentId: number, documentValue: number }>(result.entity);
                            return CartActions.checkoutOrder.success(resData);
                        }),
                        CartActions.checkoutOrder.catchError()
                    )
            })
        )
    });


    ////////////////

    constructor(
        private actions$: Actions,
        private http: HttpClient,
    ) {

    }
}