import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

interface BsData{
  productId:number,
  shopId:number
}


@Component({
  selector: 'app-bs-notify-stock',
  templateUrl: './bs-notify-stock.component.html',
  styleUrls: ['./bs-notify-stock.component.scss']
})
export class BsNotifyStockComponent {
  constructor(
    public bottomSheetRef: MatBottomSheetRef<BsNotifyStockComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BsData,
  ){}
}
