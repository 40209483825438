import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CartEffects } from './cart.effects';
import { CartFacade } from './cart.facade';
import { cartReducer ,CART_FEATURE_KEY} from './cart.reducer';



@NgModule({
    declarations: [

    ],
    imports: [
        StoreModule.forFeature(CART_FEATURE_KEY, cartReducer),
    EffectsModule.forFeature([CartEffects]),
    ],
    providers: [CartFacade],
    bootstrap: []
})
export class CartStoreModule { }
