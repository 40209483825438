import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


export interface DialogData {
  header: string;
  message: string;
  isHtml?:boolean,
  btnYesTxt?:string,
  btnNoTxt?:string,
}

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService:TranslateService) { 
      
      this.translateService.getTranslation(this.translateService.currentLang).subscribe(translations => {
        if(!data.btnYesTxt) data.btnYesTxt = translations['yes'];
        if(!data.btnNoTxt) data.btnNoTxt = translations['no'];
      });

    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
