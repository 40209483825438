import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { SideNavService } from 'src/app/services/sidenav.service';
import { CatalogFacade } from 'src/app/store/catalog/catalog.facade';
import { CustomToastrService } from '../../../services/custom-toastr.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { GlobalLoaderFacade } from 'src/app/store/global-loader/global-loader.facade';
import { SeoFacade } from 'src/app/store/seo/seo.facade';
import { filter, map, take } from 'rxjs';
import { ImageViewerFacade } from 'src/app/store/image-viewer/image-viewer.facade';
import { ScrollFacade } from 'src/app/store/scroll/scroll.facade';

@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.scss']
})
export class LayoutMainComponent implements AfterViewInit, OnInit, AfterViewChecked, AfterContentInit {

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('sideNavMenu') sidenavMenu!: MatSidenav;

  @ViewChild('MatSidenavContainer') MatSidenavContainer! : MatSidenavContainer;

  opened = false;
  openedMenu = false;

  openedSideNav = false;
  navForProduct = false;

  // blockBanner!: Block;
  noScrollMenu = false;
  noScrollNavMobile = false;
  noScrollImageViewer = false;
  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    // private cartFacade: CartFacade,
    public sideNavService: SideNavService,
    public globalLoaderFacade: GlobalLoaderFacade,
    public imageViewerFacade: ImageViewerFacade,
    private catalogFacade: CatalogFacade,
    private scrollFacade: ScrollFacade,
    public seoFacade: SeoFacade,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    media: MediaMatcher,
  ) {
    this.catalogFacade.fetchCategories({});

    this.mobileQuery = media.matchMedia('(max-width: 767px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  onOpenMenu() {
    this.noScrollMenu = true;
  }

  onCloseMenu() {
    this.noScrollMenu = false;
  }


  onOpenNavMobile(event: any) {
    this.noScrollNavMobile = event;
  }

  onActivate(event: any) {
    // setTimeout(() => {
    //   // window.scrollTo({top:0, left:0, behavior:'smooth'});
    // }, 2000);
  }

  showBtnOrder = false;

  onScroll(event: any): void {
    const scrollTop = this.MatSidenavContainer.scrollable.getElementRef().nativeElement.scrollTop
    this.scrollFacade.scroll({ value: scrollTop });
  }

  ngOnInit(): void {
    this.navForProduct = false;

    this.route.queryParams.subscribe((params: Params) => {
      if (params['returnUrl']) {
        this.navForProduct = true;
      }
      else {

        this.navForProduct = false;
      }
    });


    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.rootRoute(this.route)),
    ).subscribe((route: ActivatedRoute) => {
      // this.navForProduct = false;

    });




  }


  ngAfterViewInit(): void {
    this.sideNavService.setSidenavRight(this.sidenav);
    this.sideNavService.setSidenavLeft(this.sidenavMenu);

    this.cdr.detectChanges();
  }
  ngAfterViewChecked(): void {
    this.imageViewerFacade.getOpened().subscribe(opened => {
      this.noScrollImageViewer = opened!;
      this.cdr.detectChanges();
    });
  }
  ngAfterContentInit(): void {

  }
  onClose() {
    // this.catalogFacade.clearProductsCrossSell({});
    this.sideNavService.clearDataCrossSell();
    this.sideNavService.setSidenavRightContentType('cart');

  }


  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
