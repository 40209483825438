import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-new-version',
  templateUrl: './dialog-new-version.component.html',
  styleUrls: ['./dialog-new-version.component.scss']
})
export class DialogNewVersionComponent {

  onRefresh(): void {
    window.location.reload();
  }
}
