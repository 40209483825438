import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoadAction, LoadingState } from "ngrx-loading-state";
import { Observable } from "rxjs";
import { Gateway } from "../../models/gateway.model";
import { Setting } from "../../models/setting.model";
import { Country } from "../../models/country.model";

import * as SharedActions from './shared.actions';
import * as SharedSelectors from './shared.selectors';
import { ReviewsResult } from "src/app/models/review.model";


@Injectable({ providedIn: 'root' })
export class SharedFacade {
  constructor(private store: Store) { }

  fetchWebsiteLanguages(options: {} & LoadAction): void {
    this.store.dispatch(SharedActions.fetchWebsiteLanguages.load(options));
  }


  getWebsiteLanguages(): Observable<string[] | null> {
    return this.store.select(SharedSelectors.selectLanguages);
  }

  getFetchWebsiteLanguagesState(): Observable<LoadingState> {
    return this.store.select(SharedSelectors.fetchTabStateSelectors.state);
  }

  setCurrentLanguage(options: {language:string} & LoadAction): void {
    this.store.dispatch(SharedActions.setWebsiteLanguage.load(options));
  }

  getCurrentLanguage(): Observable<string | null> {
    return this.store.select(SharedSelectors.selectCurrentLanguage);
  }


  /////

  addToNewsletter(options: {email:string, recaptchaToken:string} & LoadAction): void {
    this.store.dispatch(SharedActions.addToNewsletter.load(options));
  }

  getResult(): Observable<string | null> {
    return this.store.select(SharedSelectors.selectResult);
  }

  getAddToNewsletterState(): Observable<LoadingState> {
    return this.store.select(SharedSelectors.addToNewsletterStateSelectors.state);
  }

  ///////

  
  fetchCountries(options: {} & LoadAction): void {
    this.store.dispatch(SharedActions.fetchCountries.load(options));
  }


  getCountries(): Observable<Country[] | null> {
    return this.store.select(SharedSelectors.countriesSelectors.selectAll);
  }

  getFetchCountriesState(): Observable<LoadingState> {
    return this.store.select(SharedSelectors.fetchCountriesStateSelectors.state);
  }

  /////

  
  fetchGateways(options: {} & LoadAction): void {
    this.store.dispatch(SharedActions.fetchGateways.load(options));
  }


  getGateways(): Observable<Gateway[] | null> {
    return this.store.select(SharedSelectors.gatewaysSelectors.selectAll);
  }

  getFetchGatewaysState(): Observable<LoadingState> {
    return this.store.select(SharedSelectors.fetchGatewaysStateSelectors.state);
  }

  
  /////

  
  fetchGatewayMaturities(options: {gatewayId:number} & LoadAction): void {
    this.store.dispatch(SharedActions.fetchGatewayMaturities.load(options));
  }


  getGatewayMaturities(): Observable<string[] | null> {
    return this.store.select(SharedSelectors.selectMaturities);
  }

  getFetchGatewayMaturitiesState(): Observable<LoadingState> {
    return this.store.select(SharedSelectors.fetchGatewayMaturitiesStateSelectors.state);
  }

   /////

  
   fetchSettings(options: {} & LoadAction): void {
    this.store.dispatch(SharedActions.fetchSettings.load(options));
  }


  getSettings(): Observable<Setting[] | null> {
    return this.store.select(SharedSelectors.settingsSelectors.selectAll);
  }

  getFetchSettingsState(): Observable<LoadingState> {
    return this.store.select(SharedSelectors.fetchSettingsStateSelectors.state);
  }

  /////

  
  setNewVersion(options: {version:number} & LoadAction): void {
    this.store.dispatch(SharedActions.setNewVersion.load(options));
  }
  clearVersion(options:  LoadAction): void {
    this.store.dispatch(SharedActions.clearVersion.load(options));
  }

  getVersion(): Observable<number | null> {
    return this.store.select(SharedSelectors.selectVersion);
  }

  

  //////////

  
  fetchWebsiteReviews(options: {count?:number } & LoadAction): void {
    this.store.dispatch(SharedActions.fetchWebsiteReviews.load(options));
  }


  getWebsiteReviews(): Observable<ReviewsResult | null> {
    return this.store.select(SharedSelectors.selectWebsiteReviews);
  }

  getFetchWebsiteReviewsState(): Observable<LoadingState> {
    return this.store.select(SharedSelectors.fetchWebsiteReviewsSelectors.state);
  }


}