import { createFeatureSelector, createSelector } from "@ngrx/store";
import { createLoadingStatesSelector } from "ngrx-loading-state";
import { GlobalLoaderState, GLOBAL_LOADER_FEATURE_KEY } from "./global-loader.reducer";


const selectState = createFeatureSelector<GlobalLoaderState>(GLOBAL_LOADER_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);


export const selectLoading = createSelector(
    selectState,
    (state) => state?.loading
  );
  