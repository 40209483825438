import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentRow } from 'src/app/models/document-row.model';
import { ObjVariant } from 'src/app/models/product.model';

interface DialogData{
  documentRow?:DocumentRow
  variants?:ObjVariant[],
  addToCart?:boolean
}

@Component({
  selector: 'app-dialog-modify-product',
  templateUrl: './dialog-modify-product.component.html',
  styleUrls: ['./dialog-modify-product.component.scss']
})
export class DialogModifyProductComponent {

  
  constructor(
    public dialogRef : MatDialogRef<DialogModifyProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData

  ){}

}
