import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-choose-vehicule',
  templateUrl: './dialog-choose-vehicule.component.html',
  styleUrls: ['./dialog-choose-vehicule.component.scss']
})
export class DialogChooseVehiculeComponent {

  dialogId!:number;

  constructor(
    public dialogRef: MatDialogRef<DialogChooseVehiculeComponent>,
  ){
    this.dialogId = Math.random();
  }
}
