import { Component } from '@angular/core';

@Component({
  selector: 'app-fullpage-loader',
  templateUrl: './fullpage-loader.component.html',
  styleUrls: ['./fullpage-loader.component.scss']
})
export class FullpageLoaderComponent {

}
