import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer } from "@ngrx/store";
import { getInitialState, WithLoadingStates } from "ngrx-loading-state";
import { Gateway } from "../../models/gateway.model";
import { Setting } from "../../models/setting.model";
import { Country } from "../../models/country.model";

import * as SharedActions from './shared.actions';
import { ReviewsResult } from "src/app/models/review.model";


export const SHARED_FEATURE_KEY = 'shared';


export type SharedState = WithLoadingStates & {
    languages:string[] | null,
    currentLanguage:string|null,
    countries:EntityState<Country>,
    gateways:EntityState<Gateway>,
    maturities:string[] | null,
    settings:EntityState<Setting>,
    result:string|null,
    version: number|null,
    websiteReviews:ReviewsResult | null,

};

export const adapterCountries: EntityAdapter<Country> =
    createEntityAdapter<Country>({
        selectId: country => country.twoLetterCode!
    });

    
export const adapterGateways: EntityAdapter<Gateway> =
createEntityAdapter<Gateway>({
    selectId: gateway => gateway.gatewayId!
});

export const adpaterSettings: EntityAdapter<Setting> =
createEntityAdapter<Setting>({
    selectId: setting => setting.designation!
});





export const initialState: SharedState = {
    ...getInitialState(),
    languages: null,
    currentLanguage : null,
    countries:adapterCountries.getInitialState(),
    gateways:adapterGateways.getInitialState(),
    settings:adpaterSettings.getInitialState(),
    result:null,
    version:null,
    maturities:null,
    websiteReviews:null
};


export const sharedReducer = createReducer(
    initialState,
    ...SharedActions.fetchWebsiteLanguages.reducer<SharedState>({
        onSuccess: (state, response): SharedState => {
            return {
                ...state,
                 languages: [...response.languages]
            };
        }
    }),

    ...SharedActions.fetchGateways.reducer<SharedState>({
        onSuccess: (state, response): SharedState => {
            return {
                ...state,
                 gateways: adapterGateways.setAll(response.gateways, state.gateways)
            };
        }
    }),
    ...SharedActions.fetchGatewayMaturities.reducer<SharedState>({
        onSuccess: (state, response): SharedState => {
            return {
                ...state,
                maturities:[...response.maturities]
            };
        }
    }),



    ...SharedActions.fetchSettings.reducer<SharedState>({
        onSuccess: (state, response): SharedState => {
            return {
                ...state,
                 settings: adpaterSettings.setAll(response.settings, state.settings)
            };
        }
    }),

    ...SharedActions.fetchCountries.reducer<SharedState>({
        onSuccess: (state, response): SharedState => {
            return {
                ...state,
                 countries: adapterCountries.setAll(response.countries, state.countries)
            };
        }
    }),

    ...SharedActions.setWebsiteLanguage.reducer<SharedState>({
        onLoad: (state, response): SharedState => {
            return {
                ...state,
                currentLanguage:response.language
            };
        }
    }),
    
    ...SharedActions.addToNewsletter.reducer<SharedState>({
        onSuccess: (state, response): SharedState => {
            return {
                ...state,
                result:response.result
            };
        }
    }),

    ...SharedActions.setNewVersion.reducer<SharedState>({
        onLoad: (state, response): SharedState => {
            return {
                ...state,
                version :response.version
            };
        }
    }),

    ...SharedActions.clearVersion.reducer<SharedState>({
        onLoad: (state, response): SharedState => {
            return {
                ...state,
                version :null
            };
        }
    }),

    ...SharedActions.fetchWebsiteReviews.reducer<SharedState>({
        onLoad: (state, response): SharedState => {
            return {
                ...state,
                websiteReviews: null
            };
        },
        onSuccess: (state, response): SharedState => {
            // console.log(response)
            return {
                ...state,
                websiteReviews: response.reviews
            };
        }
    }),
);
