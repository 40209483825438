import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData{
  images:string[],
  imageIndex:number
}

@Component({
  selector: 'app-dialog-image-viewer',
  templateUrl: './dialog-image-viewer.component.html',
  styleUrls: ['./dialog-image-viewer.component.scss']
})
export class DialogImageViewerComponent implements OnInit {

  images:string[]=[];
  imageIndex:number = 0;


  constructor(
    public dialogRef: MatDialogRef<DialogImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ){
  }

  ngOnInit(): void {
    this.images=this.data.images;
    // this.imageIndex=this.data.imageIndex;
    
  }

  onClose(){
    this.dialogRef.close();
  }

}
