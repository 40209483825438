import { createLoadingActions, failure, load, success } from "ngrx-loading-state";


export const scroll = createLoadingActions(
    '[SCROLL] Scroll',
    load<{value: number}>(),
    success(),
    failure()
);

