import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';


// material
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';

import { LayoutMainComponent } from './layout-main/layout-main.component';
import { NavigationTopComponent } from './navigation-top/navigation-top.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationSideContentComponent } from './navigation-side-content/navigation-side-content.component';
import { DrawerCartContentComponent } from './drawer-cart-content/drawer-cart-content.component';
import { SharedModule } from '../shared/shared.module';
import { CartStoreModule } from '../../store/cart/cart-store.module';
import { AuthStoreModule } from '../../store/authentification/auth-store.module';
import { DoofinderStoreModule } from 'src/app/store/doofinder/doofinder-store.module';
import { DrawerCrossSellContentComponent } from './drawer-cross-sell-content/drawer-cross-sell-content.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { NguCarouselModule } from '@ngu/carousel';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BsModifyProductComponent } from './modify-product/bs-modify-product/bs-modify-product.component';
import { DialogModifyProductComponent } from './modify-product/dialog-modify-product/dialog-modify-product.component';
import { ModifyProductContentComponent } from './modify-product/modify-product-content/modify-product-content.component';
import { MatSelectModule } from '@angular/material/select';
import { DialogSearchComponent } from './dialog-search/dialog-search.component';
import { ScrollStoreModule } from 'src/app/store/scroll/scroll-store.module';

@NgModule({
    declarations: [
        LayoutMainComponent,
        NavigationTopComponent,
        FooterComponent,
        NavigationSideContentComponent,
        DrawerCartContentComponent,
        DrawerCrossSellContentComponent,
        DialogModifyProductComponent,
        BsModifyProductComponent,
        ModifyProductContentComponent,
        DialogSearchComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSliderModule,
        MatSidenavModule,
        MatMenuModule,
        MatTabsModule,
        MatListModule,
        MatDialogModule,
        MatSelectModule,
        NguCarouselModule,
        MatToolbarModule,
        SharedModule,
        AuthStoreModule,
        CartStoreModule,
        ScrollStoreModule,
        DoofinderStoreModule,
    ],
    exports: [

    ],
    providers: [


    ],
})
export class LayoutMainModule { }
