import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Picture } from 'src/app/models/picture.model';
import { BackgroundService } from 'src/app/services/background.service';
import { FullScreenService } from 'src/app/services/full-screen.service';
import KeenSlider, { KeenSliderInstance, TrackDetails } from "keen-slider";
import { PinchZoomComponent } from '@mapaxe/ngx-pinch-zoom';
import { ImageViewerFacade } from 'src/app/store/image-viewer/image-viewer.facade';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @ViewChild('imageRef') imageRef!: ElementRef
  @ViewChild('imagesRef') imagesRef!: ElementRef

  @ViewChild('myCarouselImagesItem') carousel!: NguCarousel<any>;

  @ViewChild('myPinchZoom') pinchZoomComponent!: PinchZoomComponent;

  @Output() onCloseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() images!: Picture[];
  @Input() displayedImages!: Picture[];
  @Input() selectedPicture!: Picture;
  @Input() bgColor!: string | null;

  @Input() withBackgroundColor = false;
  @Input() advancedViewer = true;
  @Input() fullScreen = true;
  @Input() showSwitchBtns = true;

  loadingPicture = false;

  public carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, all: 0 },
    // gridBreakpoints: { sm: 576, md: 768, lg: 992, xl: 1480 },
    slide: 1,
    speed: 1000,
    // interval: {
    //   timing: 4000,
    // },
    point: {
      visible: true,
    },
    load: 1,
    // velocity: 0.2,
    touch: true,
    // loop: true,

    // easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  styleCarousel = '';
  carouselNb = 0;

  @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>;

  currentSlide: number = 0;
  dotHelper: Array<Number> = [];
  details: TrackDetails | undefined;
  slider: KeenSliderInstance | null = null;


  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    private backgroundService: BackgroundService,
    private fullScreenService: FullScreenService,
    private imageViewerFacade: ImageViewerFacade,
    private cdr: ChangeDetectorRef,
    media: MediaMatcher,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 576px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    if (!this.images) this.onClose();

   
    this.displayedImages = [...this.images];
    if (this.fullScreen && !this.mobileQuery.matches)
      this.fullScreenService.ShowFullScreen();

    // this.showSwitchBtns = this.images.length > 1;
    this.loadingPicture = true;
    if (!this.selectedPicture) {
      this.selectedPicture = this.displayedImages[0];
    }

    if (this.selectedPicture && this.selectedPicture.attributListValue_Values) {
      this.bgColor = this.backgroundService.getBackgroundColor(this.selectedPicture.attributListValue_Values);
    }
    if (!this.bgColor) {
      this.bgColor = '#F2F3F5';
    }

  }

  ngAfterViewChecked(): void {
    this.imageViewerFacade.opened({ opened: true });
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {


    if (this.displayedImages.length > 0) {
      const selectedImageIdx = this.displayedImages.findIndex(x => x.pictureId == this.selectedPicture.pictureId);
      // console.log(selectedImageIdx)
      this.currentSlide = selectedImageIdx;
      setTimeout(() => {
        if (this.sliderRef) {

          this.slider = new KeenSlider(this.sliderRef.nativeElement, {
            initial: this.currentSlide,
            mode: 'snap',
            loop:true,
            slideChanged: (s) => {
              this.currentSlide = s.track.details.rel;
              const currentImage = this.displayedImages[this.currentSlide];
              this.selectedPicture = currentImage;

              if (currentImage && currentImage.attributListValue_Values)
                this.bgColor = this.backgroundService.getBackgroundColor(currentImage.attributListValue_Values)!;
              if (!this.bgColor) {
                this.bgColor = '#F2F3F5';
              }
            },
          })
          this.dotHelper = [
            ...Array(this.slider?.track.details.slides.length).keys(),
          ]

        }
        // if(this.slider){
        //   this.slider.moveToIdx(selectedImageIdx)
        // }
      }, 50);

      // this.carouselNb = this.displayedImages.length;
      // this.styleCarousel = `calc(100%/${this.carouselNb})`;

      // if (this.carousel) {
      //   this.carousel.moveTo(selectedImageIdx);

      //   this.carousel.onMove.subscribe((value: any) => {
      //     const currentImage = this.displayedImages[value.currentSlide];
      //     if (currentImage && currentImage.attributListValue_Values)
      //       this.bgColor = this.backgroundService.getBackgroundColor(currentImage.attributListValue_Values);
      //     if (!this.bgColor) {
      //       this.bgColor = '#F2F3F5';
      //     }

      //   });
      // }
    }

    if (this.pinchZoomComponent) {
      this.pinchZoomComponent.onZoomChange.subscribe(val => {
        if (this.slider) {
          if (this.pinchZoomComponent.isZoomedIn) {
            const currentImage = this.images[this.currentSlide];
            this.selectedPicture = currentImage;
            this.slider.update({ initial: this.currentSlide, disabled: this.pinchZoomComponent.isZoomedIn });
            this.displayedImages = [currentImage];

          } else {
            this.displayedImages = [...this.images];
            const currentImage = this.displayedImages[this.currentSlide];
            this.selectedPicture = currentImage;
            // console.log('init', this.currentSlide)
            this.slider.update({
              initial: this.currentSlide,
              mode: 'snap',
              disabled: false,
              slideChanged: (s) => {
                // console.log('s.track.details.rel', s.track.details.rel)
                this.currentSlide = s.track.details.rel;
                const currentImage = this.displayedImages[this.currentSlide];
                this.selectedPicture = currentImage;
                // console.log('current', this.currentSlide);
                if (currentImage && currentImage.attributListValue_Values)
                  this.bgColor = this.backgroundService.getBackgroundColor(currentImage.attributListValue_Values)!;
                if (!this.bgColor) {
                  this.bgColor = '#F2F3F5';
                }
              },
            });
            this.slider.moveToIdx(this.currentSlide)
          }
          this.cdr.detectChanges();

        }
        // if(this.pinchZoomComponent.isZoomedIn){
        //   setTimeout(() => {
        //     if(this.sliderRef){

        //     this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        //       initial: this.currentSlide,
        //       mode:'snap',
        //       disabled:true,
        //       slideChanged: (s) => {
        //         this.currentSlide = s.track.details.rel;
        //         const currentImage = this.images[ this.currentSlide];
        //         this.selectedPicture = currentImage;

        //         if (currentImage && currentImage.attributListValue_Values)
        //           this.bgColor = this.backgroundService.getBackgroundColor(currentImage.attributListValue_Values)!;
        //         if (!this.bgColor) {
        //           this.bgColor = '#F2F3F5';
        //         }
        //       },
        //     })
        //     this.dotHelper = [
        //       ...Array(this.slider?.track.details.slides.length).keys(),
        //     ]

        //   }
        //   // if(this.slider){
        //   //   this.slider.moveToIdx(selectedImageIdx)
        //   // }
        //   },50);
        // }else{
        //   setTimeout(() => {
        //     if(this.sliderRef){

        //     this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        //       initial: this.currentSlide,
        //       mode:'snap',
        //       slideChanged: (s) => {
        //         this.currentSlide = s.track.details.rel;
        //         const currentImage = this.images[ this.currentSlide];
        //         this.selectedPicture = currentImage;

        //         if (currentImage && currentImage.attributListValue_Values)
        //           this.bgColor = this.backgroundService.getBackgroundColor(currentImage.attributListValue_Values)!;
        //         if (!this.bgColor) {
        //           this.bgColor = '#F2F3F5';
        //         }
        //       },
        //     })
        //     this.dotHelper = [
        //       ...Array(this.slider?.track.details.slides.length).keys(),
        //     ]

        //   }
        //   // if(this.slider){
        //   //   this.slider.moveToIdx(selectedImageIdx)
        //   // }
        //   },50);
        // }
      })
    }
   
  }

  
  onSelectPicture(picture: Picture) {
    if (picture.pictureId == this.selectedPicture.pictureId) return;
    this.loadingPicture = true;
    this.selectedPicture = picture;
  }

  onLoadPicture() {
    this.loadingPicture = false;
  }

  onClose() {
    this.onCloseEvent.next(true);

    if (this.fullScreen && !this.mobileQuery.matches)
      this.fullScreenService.ExitFullScreen();
  }

  onSwitchPhoto(n: number) {
    const idx = this.displayedImages.findIndex(x => x.pictureId == this.selectedPicture.pictureId);
    let newIdx = idx + n;
    if (newIdx < 0) {
      newIdx = this.displayedImages.length - 1;
    } else if (newIdx >= this.displayedImages.length) {
      newIdx = 0;
    }
    this.selectedPicture = this.displayedImages[newIdx];

  }

  onZoomTouchStart(event: any) {
    // console.log(event)
  }



  public onKeydown(event: any) {

    if (event.key === 'Escape') {
      this.onClose();
    }

    // if (event.key === "ArrowLeft" && !this.carousel.isFirst) {
    //   this.carousel.moveTo(this.carousel.currentSlide - 1);
    // }
    // if (event.key === "ArrowRight" && !this.carousel.isLast) {
    //   this.carousel.moveTo(this.carousel.currentSlide + 1);
    // }
    // if (this.slider) {
    //   if (event.key === "ArrowRight" ) {
    //     this.slider.next();
    //   }
    //   if (event.key === "ArrowLeft" ) {
    //     this.slider.prev();
    //   }
    // }
  }

  onKeyup(event: any) {
    if (this.slider) {
      if (event.key === "ArrowRight") {
        this.slider.next();
      }
      if (event.key === "ArrowLeft") {
        this.slider.prev();
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.imageRef && (this.imageRef.nativeElement == event.target)) {
      this.onClose();
    }
    if (this.imagesRef && (this.imagesRef.nativeElement == event.target)) {
      this.onClose();
    }
  }

  ngOnDestroy(): void {
    if (this.fullScreen && !this.mobileQuery.matches)
      this.fullScreenService.ExitFullScreen();

    this.imageViewerFacade.opened({ opened: false });
  }
}
