<mat-sidenav-container #MatSidenavContainer class="sidenav-container" id="content" (scroll)="onScroll($event)">
  <mat-sidenav #sidenav mode="over" position="end" class="sidenav h-100 position-fixed r-0px t-0px"
               [ngClass]="{'drawer-cart': sideNavService.sidenavRightContentType == 'cart', 'drawer-crosssell' : sideNavService.sidenavRightContentType == 'cross-sell'}"
               (closed)="onClose()">
    <app-drawer-cart-content *ngIf="sideNavService.sidenavRightContentType == 'cart'" [drawerCart]="sidenav" />
    <app-drawer-cross-sell-content *ngIf="sideNavService.sidenavRightContentType == 'cross-sell'" />
  </mat-sidenav>
  <mat-sidenav-content [class.no-scroll]="noScrollMenu || noScrollNavMobile || noScrollImageViewer || (sideNavService.sidenavRight && sideNavService.sidenavRight.opened)"  (scroll)="onScroll($event)">
    <div id="site-content">
      <span id="_top"></span>
      <app-navigation-top [drawerCart]="sidenav" [drawerMenu]="sideNavMenu"  [navForProduct]="navForProduct"
      (onOpenNavMobile)="onOpenNavMobile($event)" >
      </app-navigation-top>

      <!-- <div class="w-100 bg-black content-loader h-85px" *ngIf="(seoFacade.getFetchTabState() | async)?.loading">

      </div> -->

      <mat-sidenav-container class="bg-white">
        <mat-sidenav #sideNavMenu [mode]="mobileQuery.matches ? 'side' : 'over'" position="start" class="sidenavcategory" (closed)="onCloseMenu()"
                     (openedStart)="onOpenMenu()">
          <app-navigation-side-content [drawerMenu]="sideNavMenu" class="">
          </app-navigation-side-content>
        </mat-sidenav>
        <mat-sidenav-content class="pr-0 limit-container">
          <div  >
            <router-outlet  (activate)="onActivate($event)"></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
    <app-footer ></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
