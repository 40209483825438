import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LoadAction, LoadingState } from "ngrx-loading-state";
import { Observable } from "rxjs";

import * as ScrollActions from './scroll.actions';
import * as SeoSelectors from './scroll.selectors';


@Injectable({ providedIn: 'root' })
export class ScrollFacade {
  constructor(private store: Store) { }


  scroll(options: {value:number} & LoadAction): void {
    this.store.dispatch(ScrollActions.scroll.load(options));
  }


  getScrollValue(): Observable<number> {
    return this.store.select(SeoSelectors.selectScrollValue);
  }


}