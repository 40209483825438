import { createLoadingActions, failure, load, success } from "ngrx-loading-state";


import { ShippingAddress } from "../../models/shipping-address.model";
import { Document } from "../../models/document.model";
import { Error } from '../../models/results/base-result.model';
import { Product, ProductItem } from "../../models/product.model";
import { KeyValuePair } from '../../Utils/KeyValuePair.interface';


export const fetchCart = createLoadingActions(
    '[Cart] Fetch Cart',
    load<{ setCart: boolean, calcShipping: boolean, calcDiscount: boolean, includeCarriers: boolean, includeGateways: boolean, validate: boolean }>(),
    success<{document:Document}>(),
    failure<{ errors: Error[] | undefined }>()
);


export const updateCart = createLoadingActions(
    '[Cart] Update Cart',
    load<{ carrierId: number }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);

export const deleteCart = createLoadingActions(
    '[Cart] Delete Cart',
    load<{ documentId: number }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);


export const clearCart = createLoadingActions(
    '[Cart] Clear Cart',
    load(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);



export const loadCart = createLoadingActions(
    '[Cart] Load Cart',
    load<{ key: string }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);


export const addToCart = createLoadingActions(
    '[Cart] Add to Cart',
    load<{ quantity: number,productId?: number,  validateCart?: boolean, productIdCrossSellId?: KeyValuePair , documentRowParentId?: number }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);

export const addRemoveFromCart = createLoadingActions(
    '[Cart] Add Remove From Cart',
    load<{ documentRowId: number, quantity: number, resetQuantity?: boolean, validateCart?: boolean }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);

export const addCouponToCart = createLoadingActions(
    '[Cart] Add Coupon To Cart',
    load<{ code: string }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);

export const addCommentToCart = createLoadingActions(
    '[Cart] Add Comment To Cart',
    load<{ comment: string, documentId: number }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);


export const addCommentToRowCart = createLoadingActions(
    '[Cart] Add Comment To Row Cart',
    load<{ comment: string, documentRowId: number }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);



export const setShippingAddress = createLoadingActions(
    '[Cart] Set Shipping Address',
    load<{ shippingAddressId: number }>(),
    success<Document>(),
    failure<{ errors: Error[] | undefined }>()
);



export const fetchUserAddresses = createLoadingActions(
    '[Cart] Fetch User Addresses',
    load(),
    success<{ addresses: ShippingAddress[] }>(),
    failure<{ errors: Error[] | undefined }>()
);

export const openCloseDrawer = createLoadingActions(
    '[Cart] Open Drawer',
    load<{ value: boolean, content: { componentName: string, data: any } | null }>(),
    success<{ value: boolean, content: { componentName: string, data: any } | null }>(),
    failure<{ errors: Error[] | undefined }>()
);


export const replaceProductInCart = createLoadingActions(
    '[Cart] Replace Product In Cart',
    load<{ oldProductId: number, newProductId: number }>(),
    success<{ document: Document, newProductId: number }>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchPaymentForm = createLoadingActions(
    '[Cart] Get Payment Form',
    load<{ gatewayId: number, documentId: number, shippingAddressId: number, device?: string }>(),
    success<{ form: string, paymentType: string, cart: Document }>(),
    failure<{ errors: Error[] | undefined }>()
);

export const checkoutOrder = createLoadingActions(
    '[Cart] Get Checkout Order',
    load<{ gatewayId: number, documentId: number, transactionId?: number, info?: string }>(),
    success<{ cart: Document, status: string, documentId: number, documentValue: number }>(),
    failure<{ errors: Error[] | undefined }>()
);


export const fetchProductsRelated = createLoadingActions(
    '[Cart] Fetch Products Related',
    load<{ count: number }>(),
    success<{ products: ProductItem[] }>(),
    failure<{ errors: Error[] | undefined }>()
);

export const checkMandat = createLoadingActions(
    '[Cart] Check Mandat',
    load(),
    success<{ form: string, paymentType: string, cart: Document }>(),
    failure<{ errors: Error[] | undefined }>()
);