import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

import { environment } from '../../../environments/environment';

import * as AuthActions from './auth.actions';

import { User } from '../../models/user.model';
import { BaseResult } from '../../models/results/base-result.model';
import { LoginSuccess } from '../../modules/authentification/models/login-success.model';
import { isPlatformBrowser } from '@angular/common';
import { PermissionsService } from '../../services/permissions.service';


export interface RegisterResponseData {
    status: string
};


export interface LoginResponseData {
    id: string,
    username: string,
    email: string,
    firstName: string,
    lastName: string,
    token: string,
    refreshToken: string,
    expiresIn: string,
    isPro: boolean,
    emailConfimred: boolean,
};


@Injectable()
export class AuthEffects {
    token: string | null = null;
    refreshToken: string | null = null;

    register$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.register.load),
            mergeMap((data) => {
                //  console.log(data);

                const formData = new FormData();
                for (let [key, value] of Object.entries(data)) {
                    if (!value) continue;
                    formData.append(key, value);
                }

                return this.http.post<BaseResult>(environment.API_BASE_URL + 'account/register',
                    formData

                ).pipe(
                    map((result) => {
                        if (!result.isValid)
                            return AuthActions.register.failure({ errors: result.errors, error: 'error' });

                        const userData = {
                            username: result.entity.username,
                            token: result.entity.token,
                            refreshToken: result.entity.refreshToken,
                            expiresIn: result.entity.expiresIn,
                            id: result.entity.id,
                        };

                        const decodedToken: {
                            exp: number,
                            iat: number,
                            unique_name: string,
                            given_name: string,
                            family_name: string,
                            user_data: string,
                            role: string[],
                            actort: string[],
                        } = jwt_decode.default(userData.token!);


                        const userRoles: string[] = [];
                        if (typeof (decodedToken.role) === 'string') {
                            const userRole = decodedToken.role;
                            userRoles.push(userRole);
                        }
                        else {
                            decodedToken.role.forEach(role => {
                                userRoles.push(role);
                            });
                        }

                        //  console.log(userRoles)


                        const resultLogin: LoginSuccess = {
                            email: userData.username,
                            userId: userData.id,
                            token: userData.token,
                            refreshToken: userData.refreshToken,
                            expirationDate: new Date(userData.expiresIn!),
                            firstName: decodedToken.given_name,
                            lastName: decodedToken.family_name.split('|')[0],
                            redirect: data.redirect,
                        };
                        if(isPlatformBrowser(this.platformId))
                            localStorage.setItem('userData', JSON.stringify(userData));


                        return AuthActions.login.success(resultLogin);

                    }),
                    AuthActions.register.catchError()
                );
            })
        )
    });

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.login.load),
            mergeMap((loginData) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'account/login',
                    {
                        email: loginData.email,
                        password: loginData.password
                    })
                    .pipe(
                        tap(result => {
                            if (result.entity) {
                                const expirationDate = new Date((<LoginSuccess>(result.entity)).expiresIn!);
                                const expiresIn = expirationDate.getTime() - (new Date()).getTime();
                                this.permissionsService.setLogoutTimer(expiresIn);
                            }
                        }),
                        map(result => {
                            if (!result.isValid)
                                return AuthActions.login.failure({ errors: result.errors, error: 'error' });

                            const resData = <LoginSuccess>(result.entity);
                            resData.redirect = loginData.redirect;

                            const userData = {
                                username: resData.username,
                                token: resData.token,
                                refreshToken: resData.refreshToken,
                                expiresIn: resData.expiresIn,
                                id: resData.userId,
                            };

                            const decodedToken: {
                                exp: number,
                                iat: number,
                                unique_name: string,
                                given_name: string,
                                family_name: string,
                                role: string[],
                                actort: string[],
                            } = jwt_decode.default(userData.token!);

                            const userRoles: string[] = [];
                            if (typeof (decodedToken.role) === 'string') {
                                const userRole = decodedToken.role;
                                userRoles.push(userRole);
                            }
                            else {
                                decodedToken.role.forEach(role => {
                                    userRoles.push(role);
                                });
                            }


                            const resultLogin: LoginSuccess = {
                                email: userData.username,
                                userId: userData.id,
                                token: userData.token,
                                refreshToken: userData.refreshToken,
                                expirationDate: new Date(userData.expiresIn!),
                                firstName: decodedToken.given_name,
                                lastName: decodedToken.family_name.split('|')[0],
                                redirect: resData.redirect,
                                userRoles: userRoles
                            };

                            if(isPlatformBrowser(this.platformId))
                                localStorage.setItem('userData', JSON.stringify(userData));
                            // console.log('success');
                            return AuthActions.login.success(resultLogin);
                        }),
                        AuthActions.login.catchError()
                    )
            })
        )
    });

    loginAs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.loginAs.load),
            mergeMap((loginData) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'account/login-as',
                    {
                        userId: loginData.userId,
                    })
                    .pipe(
                        tap(result => {
                            if (result.entity) {
                                const expirationDate = new Date((<LoginSuccess>(result.entity)).expiresIn!);
                                const expiresIn = expirationDate.getTime() - (new Date()).getTime();
                                this.permissionsService.setLogoutTimer(expiresIn);
                            }
                        }),
                        map(result => {
                            if (!result.isValid)
                                return AuthActions.login.failure({ errors: result.errors, error: 'error' });

                            const resData = <LoginSuccess>(result.entity);
                            resData.redirect = loginData.redirect;

                            const userData = {
                                username: resData.username,
                                token: resData.token,
                                refreshToken: resData.refreshToken,
                                expiresIn: resData.expiresIn,
                                id: resData.userId,
                            };

                            const decodedToken: {
                                exp: number,
                                iat: number,
                                unique_name: string,
                                given_name: string,
                                family_name: string,
                                role: string[],
                                actort: string[],
                            } = jwt_decode.default(userData.token!);
                            // console.log(decodedToken);


                            const userRoles: string[] = [];
                            if (typeof (decodedToken.role) === 'string') {

                                const userRole = decodedToken.role;
                                userRoles.push(userRole);

                            }
                            else {
                                decodedToken.role.forEach(role => {
                                    userRoles.push(role);
                                });
                            }


                            let loginAs = false;
                            let customerId: number | null | undefined = null;
                            if (typeof (decodedToken.actort) === 'string') {
                                loginAs = (<string>decodedToken.actort).split('|')[0] === 'true';
                                customerId = +(<string>decodedToken.actort).split('|')[1];
                            }

                            const resultLogin: LoginSuccess = {
                                email: userData.username,
                                userId: userData.id,
                                token: userData.token,
                                refreshToken: userData.refreshToken,
                                expirationDate: new Date(userData.expiresIn!),
                                firstName: decodedToken.given_name,
                                lastName: decodedToken.family_name.split('|')[0],
                                redirect: resData.redirect,
                                userRoles: userRoles,
                                loginAs: loginAs,
                                customerId: customerId
                            };

                            if(isPlatformBrowser(this.platformId))
                                localStorage.setItem('userData', JSON.stringify(userData));
                            // console.log('success');
                            return AuthActions.login.success(resultLogin);
                        }),
                        AuthActions.login.catchError()
                    )
            })
        )
    });

    refreshLogin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.refreshLogin.load),
            mergeMap((loginData) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'account/refresh-token',
                    {
                        accessToken: this.token,
                        refreshToken: this.refreshToken
                    },
                )
                    .pipe(
                        tap(result => {
                            const expirationDate = new Date((<LoginSuccess>(result.entity)).expiresIn!);
                            const expiresIn = expirationDate.getTime() - (new Date()).getTime();
                            this.permissionsService.clearLogoutTimer();
                            this.permissionsService.setLogoutTimer(expiresIn);
                        }),
                        map(result => {

                            if (!result.isValid)
                                return AuthActions.login.failure({ errors: result.errors, error: 'error' });

                            const resData = <LoginSuccess>(result.entity);

                            const userData = {
                                username: resData.username,
                                token: resData.token,
                                refreshToken: resData.refreshToken,
                                expiresIn: resData.expiresIn,
                                id: resData.userId,
                            };
                            if(isPlatformBrowser(this.platformId))
                                localStorage.setItem('userData', JSON.stringify(userData));

                            return AuthActions.login.success(resData);
                        }),
                        AuthActions.refreshLogin.catchError()
                    )
            })
        )
    });



    requestResetPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.requestResetPassword.load),
            mergeMap((data) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'account/forgot-password',
                    {
                        email: data.email,
                    })
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return AuthActions.requestResetPassword.failure({ errors: result.errors, error: 'error' });

                            const resData = <string>(result.entity);
                            return AuthActions.requestResetPassword.success({ result: resData });
                        }),
                        AuthActions.requestResetPassword.catchError()

                    )
            })
        )
    });


    resetPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.resetPassword.load),
            mergeMap((data) => {
                return this.http.post<BaseResult>(environment.API_BASE_URL + 'account/reset-password',
                    {
                        email: data.email,
                        token: data.token,
                        password: data.password,
                        confirmPassword: data.confirmPassword
                    })
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return AuthActions.resetPassword.failure({ errors: result.errors, error: 'error' });

                            const resData = <string>(result.entity);
                            return AuthActions.resetPassword.success({ result: resData });
                        }),
                        AuthActions.resetPassword.catchError()
                    )
            })
        )
    });



    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.logout.load),
            mergeMap(() => {
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'account/logoff',{
                    withCredentials:true
                })
                    .pipe(
                        map(result => {
                            // console.log(result);
                            if (!result.isValid)
                                return AuthActions.logout.failure({ errors: result.errors, error: 'error' });

                            const resData = <boolean>(result.entity);
                            return AuthActions.logout.success({ result: resData });
                        }),
                        AuthActions.logout.catchError()
                    )
            })
        )
    });


    logoutSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.logout.success),
            tap(() => {
                this.permissionsService.clearLogoutTimer();
                if (isPlatformBrowser(this.platformId))
                    localStorage.removeItem('userData');
                this.router.navigate(['/']);
            })
        )
    }, { dispatch: false });


    autoLogin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.autoLogin.load),
            map(() => {
                const userData: {
                    username: string;
                    id: string,
                    token: string,
                    refreshToken: string,
                    expiresIn: string,
                    documentColumns: string
                } = JSON.parse(localStorage.getItem('userData')!);

                if (!userData) {
                    return { type: 'DUMMY' };
                }

                // console.log(jwt_decode.default(userData.token!))

                const loadedUser = new User(
                    '',
                    '',
                    userData.username,
                    userData.id,
                    userData.token,
                    userData.refreshToken,
                    new Date(userData.expiresIn),
                    [],
                    false,
                    null
                );

                if (loadedUser.token) {

                    const decodedToken: {
                        exp: number,
                        iat: number,
                        unique_name: string,
                        given_name: string,
                        family_name: string,
                        user_data: string,
                        role: string[],
                        actort: string[],
                    } = jwt_decode.default(loadedUser.token);

                    // console.log(decodedToken)

                    const userRoles: string[] = [];
                    if (typeof (decodedToken.role) === 'string') {

                        const userRole = decodedToken.role;
                        userRoles.push(userRole);

                    }
                    else {
                        decodedToken.role.forEach(role => {
                            userRoles.push(role);
                        });
                    }

                    let loginAs = false;
                    let customerId: number | null | undefined = null;
                    if (typeof (decodedToken.actort) === 'string') {
                        loginAs = (<string>decodedToken.actort).split('|')[0] === 'true';
                        customerId = +(<string>decodedToken.actort).split('|')[1];
                    }

                    loadedUser.userRoles = [...userRoles];
                    loadedUser.loginAs = loginAs;
                    loadedUser.customerId = customerId;

                    const expirationDuration =
                        new Date(userData.expiresIn).getTime() -
                        new Date().getTime();
                    this.permissionsService.setLogoutTimer(expirationDuration);

                    const result: LoginSuccess = {
                        email: loadedUser.email,
                        userId: loadedUser.id,
                        token: loadedUser.token,
                        refreshToken: loadedUser.refreshToken,
                        expirationDate: new Date(userData.expiresIn),
                        firstName: decodedToken.given_name,
                        lastName: decodedToken.family_name.split('|')[0],
                        redirect: false,
                        userRoles: userRoles,
                        loginAs: loginAs,
                        customerId: customerId
                    };

                    // console.log(result)
                    return AuthActions.login.success(result);

                }
                return { type: 'DUMMY' };
            })
        )
    });

    authRedirect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.login.success),
            tap((authSuccessAction) => {
                if (authSuccessAction.redirect) {
                    if (!this.route.snapshot.queryParams['returnUrl']) {
                        this.router.navigate(['/']);
                    }
                    this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
                }
            })
        )
    },
        { dispatch: false });


    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private permissionsService: PermissionsService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {


    }
}