import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {pairwise} from 'rxjs';

import { Gateway } from '../../../models/gateway.model';
import { AuthFacade } from '../../../store/authentification/auth.facade';
import { CustomToastrService } from '../../../services/custom-toastr.service';
import { SharedFacade } from '../../../store/shared/shared.facade';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  gateways!:Gateway[];
  hasGatewayCB = false;
  hasGatewayPaypal = false;
  hasGatewayWire = false;

  linkFacebook!:string;
  linkInsta!:string;

  emailCtrl = new FormControl(null, [Validators.required, Validators.email]);

  currentYear = '2023';

  typeClient = true;

  constructor(
    private sharedFacade:SharedFacade,
    private toastr:CustomToastrService,
    private authFacade:AuthFacade,
    public translateService:TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,

  ) { 
    this.currentYear = new Date().getFullYear().toString();
  }

  ngOnInit(): void {
    this.sharedFacade.getAddToNewsletterState()
    .pipe(pairwise())
    .subscribe(([prevState, nextState]) => {
      if(nextState && nextState.success){
        this.emailCtrl.reset();
        this.toastr.success('Votre adresse mail a bien été ajoutée à la newsletter');
      }
    });

    this.sharedFacade.fetchGateways({});
    this.sharedFacade.getGateways().subscribe(gateways => {
      this.gateways = gateways!;
    if(gateways){
      this.hasGatewayCB = gateways.find(g => g.paymentChoice.toLowerCase().includes('cb')) != undefined;
      this.hasGatewayPaypal = gateways.find(g => g.paymentChoice.toLowerCase().includes('paypal')) != undefined;
      this.hasGatewayWire = gateways.find(g => g.paymentChoice.toLowerCase().includes('virement')) != undefined;
    }
    });


  }

  scroll(){
    if(isPlatformBrowser(this.platformId))
      window.scrollTo({top:0, left:0, behavior:'smooth'})
 }

 onSubmit(){
  this.sharedFacade.addToNewsletter({recaptchaToken:'token', email:this.emailCtrl.value!});

 }

 
 onResolveCaptcha($event:any){
  const token = $event;
  this.sharedFacade.addToNewsletter({recaptchaToken:token, email:this.emailCtrl.value!});
 }
}
