import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { interval, Subscription, pairwise, delay, timeout, of } from 'rxjs';

import { RatingService } from '../../../services/rating.service';

import { Document } from '../../../models/document.model';
import { ProductItem } from '../../../models/product.model';
import { CustomToastrService } from '../../../services/custom-toastr.service';
import { CartFacade } from '../../../store/cart/cart.facade';
import { MediaMatcher } from '@angular/cdk/layout';
import { ScrollToService } from 'src/app/services/scroll-to.service';
import { FormatterService } from 'src/app/services/formatter.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import KeenSlider, { KeenSliderInstance, TrackDetails } from 'keen-slider';
import { Picture } from 'src/app/models/picture.model';

@Component({
  selector: 'app-item-product',
  templateUrl: './item-product.component.html',
  styleUrls: ['./item-product.component.scss'],
  animations: [
    trigger('fade-pill', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(500)]),
      transition(':leave', animate(0, style({ opacity: 0 }))),
    ]),
  ],
})
export class ItemProductComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @Input() product!: ProductItem;
  @Input() i!: number;
  @Input() count!: number;
  @Input() typeClient!: boolean;
  @Input() showBtnAddToCart: boolean = false;
  @Input() showInputQuantity: boolean = false;
  @Input() redirect: boolean = true;
  @Output() productIdClicked = new EventEmitter<number>();
  @Input() returnUrl!: string;

  @Input() carouselGridXS: number = 1;
  @Input() carouselGridSM: number = 1;
  @Input() carouselGridMD: number = 1;
  @Input() carouselGridLG: number = 1;
  @Input() carouselGridALL: number = 0;

  quantity: number[] = [];
  currentIndex!: number;
  addingToCart: boolean[] = [];
  existInCart: boolean[] = [];
  quantityInCart: number[] = [];
  loading = false;
  cart!: Document;

  promoCounter!: string;

  pillsToShow: string[] = [];
  currentPill = '';
  currentPillIdx = 0;

  // @ViewChild('myCarousel') myCarousel: NguCarousel;
  carouselConfigProductImages: NguCarouselConfig = {
    grid: { xs: this.carouselGridXS, sm: this.carouselGridSM, md: this.carouselGridMD, lg: this.carouselGridLG, all: this.carouselGridALL },
    load: 3,
    // interval: { timing: 4000, initialDelay: 0 },
    loop: false,
    touch: true,
    velocity: 0.2,
    easing: 'ease',
    animation: 'lazy',
    point: {
      visible: true,
      hideOnSingleSlide: true
    }

  }
  carouselItems: Picture[] = [];
  carouselNb = 0;
  styleCarousel = '';

  @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>;

  currentSlide: number = 0;
  dotHelper: Array<Number> = [];
  details: TrackDetails | undefined;
  slider: KeenSliderInstance | null = null;


  mobileQuery!: MediaQueryList;

  private _mobileQueryListener: () => void;

  subscriptions: Subscription[] = [];
  constructor(
    private cdr: ChangeDetectorRef,
    private cartFacade: CartFacade,
    private toastr: CustomToastrService,
    public ratingService: RatingService,
    public scrollToService: ScrollToService,
    media: MediaMatcher,
    private formatterService: FormatterService,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }


  ngOnInit(): void {
    this.carouselConfigProductImages = {
      ...this.carouselConfigProductImages,
      grid: { xs: this.carouselGridXS, sm: this.carouselGridSM, md: this.carouselGridMD, lg: this.carouselGridLG, all: this.carouselGridALL },

    }

    if (this.product) {
      let rem = 0;
      if (this.product.priceDateEndFlash) {
        rem = Math.floor((new Date(this.product.priceDateEndFlash!).getTime() - new Date().getTime()) / 1000);
        if (rem > 0) {
          this.promoCounter = this.formatterService.DateTimeToCounterString(rem);
        }
      }
      this.subscriptions.push(interval(1000)
        .subscribe(x => {
          if (this.product.priceDateEndFlash) {
            rem = Math.floor((new Date(this.product.priceDateEndFlash!).getTime() - new Date().getTime()) / 1000);
            if (rem > 0) {
              this.promoCounter = this.formatterService.DateTimeToCounterString(rem);
            }
          }
        }));


      this.product.productPictures.forEach(picture => {
        this.carouselItems.push(picture);
      });

      // setTimeout(() => {
      //   if(this.sliderRef){

      //   this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      //     initial: this.currentSlide,
      //     mode:'snap',
      //     slideChanged: (s) => {
      //       this.currentSlide = s.track.details.rel;

      //       const currentImage = this.carouselItems[ this.currentSlide];
      //       // if (currentImage && currentImage.attributListValue_Values)
      //       //   this.selectedColor = this.backgroundService.getBackgroundColor(currentImage.attributListValue_Values)!;
      //       // if (!this.selectedColor) {
      //       //   this.selectedColor = '#F2F3F5';
      //       // }
      //     },
      //   })
      //   this.dotHelper = [
      //     ...Array(this.slider?.track.details.slides.length).keys(),
      //   ]

      // }
      // });

      if (this.carouselItems.length > 0) {
        this.carouselNb = this.carouselItems.length;
        this.styleCarousel = `calc(100%/${this.carouselNb})`;
      }

      if (this.promoCounter) {
        this.pillsToShow.push('promo-counter');
      }

      if (this.product.isNew) {
        this.pillsToShow.push('new');
      }

      if (this.product.shippingDayInfo != 0 && this.product.disponibility == 0) {
        this.pillsToShow.push('shipping-info');
      }

      if (this.product.disponibility == 2) {
        this.pillsToShow = ['unavailable'];
      }

      if (this.pillsToShow.length > 0) {
        this.currentPillIdx = 0;
        this.currentPill = this.pillsToShow[this.currentPillIdx];
      }

      this.subscriptions.push(interval(7500).subscribe(x => {
        this.goToNextPill();
      }));

      this.subscriptions.push(...[
        this.cartFacade.getCart()
          .subscribe(cart => {
            this.cart = cart!;
            if (cart && cart.rows) {
              for (let x = 0; x < this.count; x++) {
                this.existInCart[x] = cart.rows.find(r => r.productId == this.product.productId) !== undefined;
                this.quantityInCart[x] = cart.rows.find(r => r.productId == this.product.productId)?.quantityRequested!;
              }
            }
          }),
        this.cartFacade.getAddToCartState()
          .pipe(pairwise())
          .subscribe(([prevState, nextState]) => {
            this.loading = nextState && nextState.loading;
          }),
        // of(([])).pipe(
        //   delay(0)
        // ).subscribe((results) => {
        //   if (this.sliderRef) {

        //     this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        //       initial: this.currentSlide,
        //       mode: 'snap',
        //       slideChanged: (s) => {
        //         this.currentSlide = s.track.details.rel;

        //         const currentImage = this.carouselItems[this.currentSlide];
        //         // if (currentImage && currentImage.attributListValue_Values)
        //         //   this.selectedColor = this.backgroundService.getBackgroundColor(currentImage.attributListValue_Values)!;
        //         // if (!this.selectedColor) {
        //         //   this.selectedColor = '#F2F3F5';
        //         // }
        //       },
        //     })
        //     this.dotHelper = [
        //       ...Array(this.slider?.track.details.slides.length).keys(),
        //     ]

        //   }
        // })
      ]);
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    // this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  onAddToCart($event: any, index: number) {
    this.loading = false;
    setTimeout(() => {
      if (!this.loading) {
        let quantity = +$event.target.value;

        this.currentIndex = index;
        if (!this.quantity[index]) this.quantity[index] = quantity;
        this.cartFacade.addToCart({ productId: this.product.productId, quantity: quantity });

        // this.toastr.success('l\'article a été ajouté au panier avec succès.','Panier');
      }
    }, 1000);
  }

  onAddOneToCart(index: number) {
    let quantity = 1;
    this.currentIndex = index;
    if (!this.quantity[index]) this.quantity[index] = quantity;
    this.cartFacade.addToCart({ productId: this.product.productId, quantity: quantity });
  }

  onAddRemoveFromDocument($event: any, resetQuantity: boolean, index: number, timeout: number) {

    if (!$event.target.value) {
      return;
    }
    let quantity = +$event.target.value;

    this.currentIndex = index;
    if (this.cart) {
      const documentRow = this.cart.rows.find(x => x.productId === this.product.productId);
      const documentRowId = documentRow?.documentRowId;
      // if(documentRow){}
      let addAllowed = this.product.forcePositiveStock || this.product.storageType === 4;
      if (!addAllowed) {
        if (quantity === 1) {
          const currentQuantity = this.cart.rows.find(x => x.documentRowId === documentRowId)?.quantityRequested;

          if (currentQuantity! + quantity <= this.product.stockValue) {
            addAllowed = true;
          }
          else {
            addAllowed = false;
          }
        }
        else {
          if (quantity <= this.product.stockValue) {
            addAllowed = true;
          }
          else {
            addAllowed = false;
          }
        }
      }
      addAllowed = true; // override check stock
      if (addAllowed) {
        setTimeout(() => {
          this.cartFacade.addRemoveFromCart({ documentRowId: documentRowId!, quantity: quantity, resetQuantity: resetQuantity });
        }, timeout);
      }
      else {
        // this.snackBar.open("La quantité demandé et plus que le stock disponbile.", "Ok", {
        //   duration: 2000
        // });
      }
    }
  }

  onClickProduct() {
    this.productIdClicked.next(this.product.productId);
  }

  onClickImage() {
    if (this.redirect) {
      this.router.navigate([this.product.linkProduct],
        { queryParams: { 'returnUrl': this.returnUrl } });
    }
    else {
      this.onClickProduct();
    }
    this.scrollToService.scrollToTop();
  }

  goToNextPill() {
    if (this.pillsToShow.length > 0) {
      if (this.currentPillIdx == this.pillsToShow.length - 1) {
        this.currentPillIdx = 0;
      } else {
        this.currentPillIdx++;
      }

      this.currentPill = this.pillsToShow[this.currentPillIdx];
    }
  }
}
