import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NguCarouselModule } from '@ngu/carousel';
import { MatIconModule } from '@angular/material/icon';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SafePipe } from './pipes/safe.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { SharedStoreModule } from '../../store/shared/shared-store.module';
import { FormatPricePipe } from './pipes/format-price.pipe';
import { SeoStoreModule } from '../../store/seo/seo-store.module';
import { CountryFullPipe } from './pipes/country-full.pipe';
import { DialogNewVersionComponent } from './dialog-new-version/dialog-new-version.component';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalLoaderComponent } from './global-loader/global-loader.component';
import { AuthStoreModule } from '../../store/authentification/auth-store.module';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';

import { ItemProductComponent } from './item-product/item-product.component';
import { ItemLargeProductComponent } from './item-large-product/item-large-product.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ItemPubProductComponent } from './item-pub-product/item-pub-product.component';
import { ChooseVehiculeComponent } from './choose-vehicule/choose-vehicule.component';
import { DialogChooseVehiculeComponent } from './choose-vehicule/dialog-choose-vehicule/dialog-choose-vehicule.component';
import { DialogGlobalLoaderComponent } from './dialog-global-loader/dialog-global-loader.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DialogImageViewerComponent } from './dialog-image-viewer/dialog-image-viewer.component';
import { DialogSliderSelectorComponent } from './dialog-slider-selector/dialog-slider-selector.component';
import { MatListModule } from '@angular/material/list';
import { FullpageLoaderComponent } from './fullpage-loader/fullpage-loader.component';
import { ItemProductLoaderComponent } from './item-product-loader/item-product-loader.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { PinchZoomModule } from '@mapaxe/ngx-pinch-zoom';
import { GetNumberActiveFiltersPipe } from './pipes/get-number-active-filters.pipe';
import { ToBasicStringPipe } from './pipes/to-basic-string.pipe';
import { GetFormControlPipe } from './pipes/get-form-control.pipe';
import { HasMatchingStringPipe } from './pipes/has-matching-string.pipe';
import { GetRowsCrossSellPipe } from './pipes/get-rows-cross-sell.pipe';


@NgModule({
  declarations: [
    SafePipe,
    FormatPricePipe,
    CountryFullPipe,
    DateAgoPipe,
    DialogNewVersionComponent,
    GlobalLoaderComponent,
    DialogConfirmationComponent,
    ItemProductComponent,
    ItemLargeProductComponent,
    ItemPubProductComponent,
    ChooseVehiculeComponent,
    DialogChooseVehiculeComponent,
    DialogGlobalLoaderComponent,
    DialogImageViewerComponent,
    DialogSliderSelectorComponent,
    FullpageLoaderComponent,
    ItemProductLoaderComponent,
    ImageViewerComponent,
    GetNumberActiveFiltersPipe,
    ToBasicStringPipe,
    GetFormControlPipe,
    HasMatchingStringPipe,
    GetRowsCrossSellPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    NguCarouselModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatListModule,
    PinchZoomModule ,
    TranslateModule,
    SharedStoreModule,
    SeoStoreModule,
    AuthStoreModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    SafePipe,
    FormatPricePipe,
    CountryFullPipe,
    DateAgoPipe,
    MatDialogModule,
    MatBottomSheetModule,
    GlobalLoaderComponent,
    DialogConfirmationComponent,
    ItemProductComponent,
    ItemLargeProductComponent,
    TranslateModule,
    ItemPubProductComponent,
    ChooseVehiculeComponent,
    FullpageLoaderComponent,
    ItemProductLoaderComponent,
    ImageViewerComponent,
    GetNumberActiveFiltersPipe,
    ToBasicStringPipe,
    GetFormControlPipe,
    HasMatchingStringPipe,
    GetRowsCrossSellPipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
  ],
})
export class SharedModule { }
