import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlobalLoaderFacade } from './global-loader.facade';
import { globalLoaderReducer,GLOBAL_LOADER_FEATURE_KEY } from './global-loader.reducer';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(GLOBAL_LOADER_FEATURE_KEY, globalLoaderReducer),
    ],
    providers: [GlobalLoaderFacade],
})
export class GlobalLoaderStoreModule { }