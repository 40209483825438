import { createFeatureSelector, createSelector } from "@ngrx/store";
import { createLoadingStatesSelector } from "ngrx-loading-state";
import * as DoofinderActions from './doofinder.actions';

import { DoofinderState, DOOFINDER_FEATURE_KEY } from "./doofinder.reducer";

const selectState = createFeatureSelector<DoofinderState>(DOOFINDER_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);



export const selectResult = createSelector(
    selectState,
    (state) => state?.result
  );
  

  export const searchSelectors = DoofinderActions.search.createSelectors(selectLoadingStates);
