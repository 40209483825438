import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DoofinderEffects } from './doofinder.effects';
import { DoofinderFacade } from './doofinder.facade';
import { doofinderReducer, DOOFINDER_FEATURE_KEY } from './doofinder.reducer';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(DOOFINDER_FEATURE_KEY, doofinderReducer),
        EffectsModule.forFeature([DoofinderEffects]),
    ],
    providers: [DoofinderFacade],
})
export class DoofinderStoreModule { }