<div>
  <!-- HEIGHT -->
  <div class="d-flex align-items-center h-60px bg-black text-white px-3" *ngIf="data.type == 'height'">
    <span class="k-height f-size-30px mr-2"></span>
    <h2 class="mb-0">{{'yourSize' | translate}}</h2>
  </div>

  <div class="content-container d-flex align-items-center" *ngIf="data.type == 'height'">
    <div class="container-list w-100 px-5 my-auto">
      <div class="selected-highlight bg-grey-light d-flex align-items-center">
        <span class="f-size-20px pl-1" id="unit"> cm</span>
      </div>

      <div class="scroll-container my-auto h-55vh w-100 f-size-20px" (scroll)="onScroll()">
        <div class="number"></div>
        <div class="number"></div>
        <div class="number"></div>
        <div class="number"></div>
        <div *ngFor="let number of data.numbers; index as i" class="number"
             [class.font-weight-semibold]="selectedValue == number">{{ number }}</div>
        <div class="number"></div>
        <div class="number"></div>
        <div class="number"></div>
        <div class="number"></div>
      </div>
    </div>
  </div>

  <!-- WEIGHT -->
  <div class="d-flex align-items-center h-60px bg-black text-white px-3" *ngIf="data.type == 'weight'">
    <span class="k-height f-size-30px mr-2"></span>
    <h2 class="mb-0">{{'yourWeight' | translate}}</h2>
  </div>

  <div class="content-container d-flex align-items-center" *ngIf="data.type == 'weight'">
    <div class="container-list w-100 px-5">
      <div class="selected-highlight bg-grey-light d-flex align-items-center">
        <span class="f-size-20px pl-1" id="unit"> kg</span>
      </div>

      <div class="scroll-container h-55vh w-100 f-size-20px" (scroll)="onScroll()">
        <div class="number"></div>
        <div class="number"></div>
        <div class="number"></div>
        <div class="number"></div>
        <div *ngFor="let number of data.numbers; index as i" class="number"
             [class.font-weight-semibold]="selectedValue == number">{{ number }}</div>
        <div class="number"></div>
        <div class="number"></div>
        <div class="number"></div>
        <div class="number"></div>
      </div>
    </div>
  </div>

  <!-- CATEGORIES -->
  <div class="d-flex align-items-center h-60px bg-black text-white px-3" *ngIf="data.type == 'categories'">
    <h2 class="mb-0">{{'vehicleType' | translate}}</h2>
  </div>

  <div class="content-container d-flex " *ngIf="data.type == 'categories'">
    <div class="container-list w-100 px-3">
      <p class="text-grey-dark pt-3">{{'pleaseChoostCategories' | translate}}</p>
      <div class="scroll-container w-100 my-auto">

      
        <mat-selection-list [formControl]="ctrlSelectedVhsType" class="w-100">
          <span>
            <mat-list-option [value]="attributElectrique" checkboxPosition="after">
              <span>
                <!-- <img [src]="category.fullUrlPictureSmall" [alt]="category.designation" width="30"
                     class="pr-2"> -->
              </span>
              Electrique
            </mat-list-option>
          </span>
          <span>
            <mat-list-option [value]="attributThermique" checkboxPosition="after">
              <span>
                <!-- <img [src]="category.fullUrlPictureSmall" [alt]="category.designation" width="30"
                     class="pr-2"> -->
              </span>
              Thermique
            </mat-list-option>
          </span>
        </mat-selection-list>

        <hr class="my-2 w-100" />

        <mat-selection-list [formControl]="ctrlSelectedCategories" class="w-100">
          <span *ngFor="let category of data.categories">
            <span>
              <mat-list-option [value]="category.categoryId" checkboxPosition="after">
                <span>
                  <img [src]="category.fullUrlPictureSmall" [alt]="category.designation" width="30"
                       class="pr-2">
                </span>
                {{category.designation}}
              </mat-list-option>
            </span>
          </span>
        </mat-selection-list>
      </div>
    </div>
  </div>

  <!-- BOTTOM BUTTON -->
  <div class="mt-auto p-3 w-100 d-flex justify-content-between position-fixed mx-0 b-0px l-0px g-1rem">
    <button (click)="onNoClick()"
            class="border-black bg-white rounded col-4 f-size-14px font-weight-medium h-60px d-flex align-items-center justify-content-center">
      <span class="k-x f-size-22px"></span>
      <span> {{'cancel' | translate }}</span>
    </button>
    <button class="btn-theme py-0 col f-size-14px font-weight-bold h-60px" (click)="onValidate()">
      <span> {{'validate' | translate }}</span>
    </button>
  </div>
</div>
