import { createReducer } from "@ngrx/store";
import { getInitialState, WithLoadingStates } from "ngrx-loading-state";
import { ShippingAddress } from "../../models/shipping-address.model";
import { Document } from "../../models/document.model";

import * as CartActions from './cart.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ProductItem } from "../../models/product.model";


export const CART_FEATURE_KEY = 'cart';


export const adapterShippingAddresses: EntityAdapter<ShippingAddress> =
    createEntityAdapter<ShippingAddress>({
        selectId: shippingAddress => shippingAddress.shippingAddressId!
    });

    export const adapterProductsRelated: EntityAdapter<ProductItem> =
    createEntityAdapter<ProductItem>({
        selectId: product => product.productId!
    });

export type CartState = WithLoadingStates & {
    cart: Document | null,
    documentId: number | null,
    documentValue: number | null,
    addresses: EntityState<ShippingAddress>,
    message: string | null,
    payStatus: string | null,
    drawerOpened: boolean,
    newProductId: number | null,
    paymentForm: {
        form:string,
        type:string,
    } | null,
    drawerContent:{componentName:string, data:any} | null,
    productsRelated:EntityState<ProductItem>
};



export const initialState: CartState = {
    ...getInitialState(),
    cart: null,
    documentId: null,
    documentValue: null,
    message: null,
    payStatus: null,
    addresses: adapterShippingAddresses.getInitialState(),
    drawerOpened: false,
    newProductId: null,
    paymentForm:null,
    drawerContent:null,
    productsRelated:adapterProductsRelated.getInitialState()
};




export const cartReducer = createReducer(
    initialState,
    ...CartActions.fetchCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            // console.log(response);

            return {
                ...state,
                cart: response.document
            };
        }
    }),
    ...CartActions.fetchProductsRelated.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            // console.log(response);

            return {
                ...state,
                productsRelated: adapterProductsRelated.setAll(response.products, state.productsRelated) 
            };
        }
    }),

    ...CartActions.updateCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.deleteCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.loadCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.addToCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.addRemoveFromCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.addCommentToCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.addCommentToRowCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.addCouponToCart.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.setShippingAddress.reducer<CartState>({
        // onLoad: (state, response): CartState => {
        //     return {
        //         ...state,
        //         cart: null
        //     };
        // },
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response }
            };
        }
    }),

    ...CartActions.fetchUserAddresses.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                addresses: adapterShippingAddresses.setAll(response.addresses, state.addresses)
            };
        }
    }),

    ...CartActions.openCloseDrawer.reducer<CartState>({
        onLoad: (state, response): CartState => {
            return {
                ...state,
                drawerOpened: response.value,
                drawerContent:response.content
            };
        }
    }),

    ...CartActions.replaceProductInCart.reducer<CartState>({
        onLoad: (state): CartState => {
            return {
                ...state,
                newProductId: null
            };
        },
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart: { ...response.document },
                newProductId: response.newProductId
            };
        }
    }),

    ...CartActions.fetchPaymentForm.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                paymentForm: {
                    form: response.form,
                type:response.paymentType,
                },
                cart:response.cart,
            };
        }
    }),

    ...CartActions.checkMandat.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                paymentForm: {
                    form: response.form,
                type:response.paymentType,
                },
                cart:response.cart,
            };
        }
    }),

    ...CartActions.checkoutOrder.reducer<CartState>({
        onSuccess: (state, response): CartState => {
            return {
                ...state,
                cart:response.cart,
                payStatus: response.status,
                documentId: response.documentId,
                documentValue: response.documentValue
            };
        }
    }),
);

export const selectAllShippingAddresses = adapterShippingAddresses.getSelectors().selectAll
