import { createFeatureSelector, createSelector } from "@ngrx/store";
import { createLoadingStatesSelector } from "ngrx-loading-state";
import * as AuthActions from './auth.actions';

import { AuthState, AUTH_FEATURE_KEY } from "./auth.reducer";

const selectState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);



export const selectUser = createSelector(
    selectState,
    (state) => state?.user
  );

  export const selectIsAuthenticated = createSelector(
    selectState,
    (state) => state.isAuthenticated
  );
  
  
  export const selectMessage = createSelector(
    selectState,
    (state) => state.message
  );
  

  export const loginSelectors = AuthActions.login.createSelectors(selectLoadingStates);
  export const refreshLoginSelectors = AuthActions.refreshLogin.createSelectors(selectLoadingStates);
  export const registerSelectors = AuthActions.register.createSelectors(selectLoadingStates);
  export const requestPasswordSelectors = AuthActions.requestResetPassword.createSelectors(selectLoadingStates);
  export const resetPasswordSelectors = AuthActions.resetPassword.createSelectors(selectLoadingStates);
  export const autoLoginSelectors = AuthActions.autoLogin.createSelectors(selectLoadingStates);
  export const logoutSelectors = AuthActions.logout.createSelectors(selectLoadingStates);
