
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  createLoadingStatesSelector } from 'ngrx-loading-state';
import * as SeoActions from './seo.actions';
import * as SeoReducer from './seo.reducer';


const selectState = createFeatureSelector<SeoReducer.SeoState>(SeoReducer.SEO_FEATURE_KEY);
const selectLoadingStates = createLoadingStatesSelector(selectState);


export const selectTab = createSelector(
    selectState,
    (state) => state.tab
  );

  
export const selectJsonLd = createSelector(
    selectState,
    (state) => state.jsonLd
  );


export const fetchTabStateSelectors = SeoActions.fetchTab.createSelectors(selectLoadingStates);
export const fetchJsonLdStateSelectors = SeoActions.fetchJsonLd.createSelectors(selectLoadingStates);
