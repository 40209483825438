<div class="col-12 px-0 row mx-0 bg-white" *ngIf="product">
  <div class="col-12 bloc-img px-0 overflow-hidden">
    <!-- <img [src]="product.pictureSmallUrl" alt="" width="100%"> -->
    <!-- <ng-image-slider [images]="productPictures"
  [imageSize]="{width:'100%', height:'140px'}"
  [manageImageRatio]="true"
  id="slider-{{product.productId}}"
  #nav></ng-image-slider> -->

    <div class="w-100 scrollable-image-container" *ngIf="mobileQuery.matches "
      [class.opacity-60]="product.disponibility == 2">
      <img *ngFor="let item of carouselItems" [src]="item.pictureUrlMedium" [alt]="product.designationDynamic"
        [title]="product.designationDynamic" class="img-carousel" (click)="onClickImage(item.productLink!)">
    </div>

    <!-- <div class="w-100 scrollable-image-container" *ngIf="!mobileQuery.matches"
      [id]="'scrollable-container-'+product.productId" [class.opacity-60]="product.disponibility == 2">
      <img *ngFor="let item of carouselItems; let i = index;" [src]="item" [alt]="product.designationDynamic"
        [title]="product.designationDynamic" class="img-carousel" (click)="onClickImage()">
    </div> -->

    <!-- <button (click)="scrollPhoto('R')" *ngIf="scrollLeft >= 0"
      class="rightRs btn-carousel position-absolute rounded-pill bg-main-gradient border-0 px-0">
      <span class="k-arrow-right text-white m-auto"></span>
    </button> -->

    <!-- <button
      class="rightRs btn-carousel position-absolute rounded-pill bg-main-gradient border-0 px-0 opacity-0">
      <span class="k-arrow-right text-white m-auto"></span>
    </button> -->

    <!-- <button (click)="scrollPhoto('L')"  *ngIf="scrollLeft > 0"
      class="leftRs btn-carousel position-absolute rounded-pill bg-main-gradient border-0 px-0">
      <span class="k-arrow-left text-white m-auto"></span>
    </button> -->
    <!-- 
    <button 
      class="leftRs btn-carousel position-absolute rounded-pill bg-main-gradient border-0 px-0 opacity-0">
      <span class="k-arrow-left text-white m-auto"></span>
    </button> -->

    <!-- BLOC IMAGE PRODUIT -->
    <ngu-carousel #myCarouselImagesItem [inputs]="carouselConfigProductImages" [dataSource]="carouselItems"
      class="w-100 bloc-img overflow-hidden" [class.opacity-60]="product.disponibility == 2"
      *ngIf="!mobileQuery.matches">

      <div *nguCarouselDef="let item;" class="item bloc-img">
        <a *ngIf="redirect" (click)="scrollToService.scrollToTop()" [routerLink]="[ item.productLink ]"
          [queryParams]="{'returnUrl':returnUrl}" class="d-flex justify-content-center h-100">
          <img [src]="item.pictureUrlMedium" [alt]="product.designationDynamic" [title]="product.designationDynamic"
            class="img-carousel">
        </a>
      </div>

      <button *ngIf="carouselNb > 1 && !myCarouselImagesItem.isLast" NguCarouselNext
        class="rightRs btn-carousel position-absolute  bg-black border-0 px-0">
        <span class="k-chevron-right text-white f-size-20px m-auto"></span>
      </button>

      <button *ngIf="carouselNb > 1 && myCarouselImagesItem.isLast" NguCarouselNext
        class="rightRs btn-carousel position-absolute  bg-black border-0 px-0 opacity-0">
        <span class="k-chevron-right text-white f-size-20px m-auto"></span>
      </button>

      <button *ngIf="carouselNb > 1 && !myCarouselImagesItem.isFirst" NguCarouselPrev
        class="leftRs btn-carousel position-absolute  bg-black border-0 px-0">
        <span class="k-chevron-left text-white f-size-20px m-auto"></span>
      </button>

      <button *ngIf="carouselNb > 1 && myCarouselImagesItem.isFirst" NguCarouselPrev
        class="leftRs btn-carousel position-absolute  bg-black border-0 px-0 opacity-0">
        <span class="k-chevron-left text-white f-size-20px m-auto"></span>
      </button>

      <div class="myPoint row px-0 mx-0 position-absolute w-100" NguCarouselPoint>
        <div *ngFor="let j of myCarouselImagesItem.pointNumbers; let i = index"
          [class.active]="i==myCarouselImagesItem.activePoint" (click)="myCarouselImagesItem.moveTo(i)" class="carousel"
          [ngStyle]="{'width': styleCarousel}"></div>
      </div>
    </ngu-carousel>

    <!-- <div class="col-12 mt-1 px-0 d-flex justify-content-center position-absolute variantsColor"
        *ngFor="let variant of product.variantsForContainer | slice:0:2">
        <ng-container *ngIf="variant.attribut.attributId == 29">

          <div class="d-flex my-auto h-100 div-variant ">
            <ng-container
              *ngFor="let variantValue of variant.attributListValues | slice:0:maxVariantsToShow; let last = last;">
              <a *ngIf="variantValue.values" [routerLink]="[ variantValue.productLink ]" 
              [title]="variantValue.designation">
                <span [style]="'background:' + backgroundService.getBackgroundColor(variantValue.values)"
                 class="pill-color"></span>
             </a>
            </ng-container>
            <span *ngIf="showVariantBtnPlus()">
              <a [routerLink]="[ product.linkProduct ]" class="text-decoration-none pill-color bg-black d-flex">
                <span class="k-plus text-white m-auto"></span>
              </a>
            </span>
          </div>
        </ng-container>
      </div> -->

    <!-- <div style="position: absolute; top:0px; right: 0px; width: 40px; height:100%; background: rgba(158, 158, 158, 0.696);">

    </div> -->

    <!-- TAGS -->
    <div class=" position-absolute tag-promo">
     
      <!-- TAG INDISPO -->
      <p class="bg-danger text-white pastilles px-3 rounded d-flex w-fit-content"
        *ngIf="product.disponibility == 2 && currentPill == 'unavailable'">
        {{'bientotDisponible' | translate}}
      </p>
      <!-- TAGS EXPRESS DELIVERY : TODAY & TOMORROW & MORE -->
      <p class="bg-grey-dark pastilles px-3 rounded d-flex" [@fade-pill]="'in'" *ngIf="currentPill != 'unavailable' && product.shippingDayInfo != 0">
        <span class="k-truck mr-1 my-auto text-white f-size-18px mb--1px mt--1px"></span>
        <span class="my-auto font-weight-bold font-italic text-white">
          {{'expedition' | translate }} 
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 1">{{'today-short' | translate }}</span>
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 2">{{'tomorrow' | translate }}</span>
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 3">{{'afterTomorrow' | translate }}</span>
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 4">{{'monday' | translate }}</span>
          <span class="font-weight-bold font-italic text-white"
            *ngIf="product.shippingDayInfo == 5">{{'tuesday' | translate }}</span>
        </span>
      </p>
    </div>

    <div class=" position-absolute tag-promo2" *ngIf="currentPill != 'unavailable'">
      <!-- TAG NEW -->
      <p class="bg-dark text-white pastilles px-3 rounded d-flex w-fit-content" *ngIf="currentPill == 'new'"
        [@fade-pill]="'in'">
        {{'new' | translate }}
      </p>
      <!-- TAG CHRONO-->
      <p class="bg-main-gradient text-black pastilles px-3 rounded d-flex w-fit-content"
        *ngIf="promoCounter && product.disponibility != 2 && currentPill == 'promo-counter'" [@fade-pill]="'in'">
        <span class="my-auto mr-1 k-clock f-size-18px mt--1px mb--1px"></span>
        <span class="my-auto">
          {{promoCounter}}
        </span>
      </p>
      
    </div>
  </div>

  <div class="col-12 mt-0 pb-1 bloc-text bg-white mh-100 h-39" [class.opacity-60]="product.disponibility == 2">
    <div class="row mx-0 h-100">

      <a class="col-12 px-0 text-decoration-none" *ngIf="redirect" [routerLink]="[ firstLinkProduct ]"
        [queryParams]="{'returnUrl':returnUrl}">
        <!-- BLOC PRICE -->
        <div class="row col-12 px-0 mx-0 mb-0 pt-2 justify-content-between">
          <div class="px-0">
            <!-- <div class="px-0 mx-0"> -->
            <span *ngIf="!product.containerHasAllSamePrice && product.typeId == 9" class="mb-1 px-0 line-height-1">
              <span class="f-size-12px line-height-1 font-weight-bold mr-1"
                [class.text-danger]="product.percentOff > 0">{{'from' | translate}} </span>
            </span>
            <!-- PRODUCT PRICE -->
            <span *ngIf="typeClient" class="mb-0 px-0">
              <span class="f-size-20px font-weight-bold mr-1 my-auto px-0 line-height-1"
                [innerHTML]="product.priceValueTtc | formatPrice" [class.text-danger]="product.percentOff > 0"></span>
            </span>
            <!-- PRODUCT PRICE REDUCTION -->
            <span class="f-size-12px mx-1 mt-1 px-0 text-decoration-linethrough line-height-1"
              *ngIf="product.percentOff > 0 && typeClient" [innerHTML]="product.priceValueTtcOff | formatPrice"></span>
            <!-- REDUCTION -->
            <span *ngIf="product.percentOff > 0 && typeClient"
              class="f-size-12px font-weight-semibold rounded text-center px-1 mx-1 my-auto border-1-5px border-grey">-{{product.percentOff}}%</span>
            <!-- </div> -->
            <!-- <div class="my-auto px-0 justify-content-start mr-0">
              <p class="mb-0 text-right">
                <span *ngIf="product.percentOff > 0 && typeClient"
                  class="f-size-md-20px f-size-18px font-weight-bold rounded text-center px-1 py-1 mx-3 my-auto border-1-5px border-black">-{{product.percentOff}}%</span>
              </p>
            </div> -->
          </div>
          <div class="px-0 d-flex justify-content-end variantsColor"
            *ngFor="let variant of product.variantsForContainer | slice:0:2">
            <ng-container *ngIf="variant.attribut.attributId == 29">
              <span class="f-size-14px my-auto mr-9px pb-2" *ngIf="!mobileQuery.matches">{{variant.count}}&nbsp;{{'colour' |
                translate}}</span>
              <div class="d-flex my-auto h-100 div-variant ">
                <ng-container
                  *ngFor="let variantValue of variant.attributListValues | slice:0:maxVariantsToShow; let last = last;">
                  <a *ngIf="variantValue.values" [routerLink]="[ variantValue.productLink ]"
                    [title]="variantValue.designation">
                    <span [style]="'background:' + backgroundService.getBackgroundColor(variantValue.values, false,true)"
                      class="pill-color"></span>
                  </a>
                </ng-container>
                <span *ngIf="showVariantBtnPlus()">
                  <a [routerLink]="[ firstLinkProduct]" [queryParams]="{'returnUrl':returnUrl}"
                    class="text-decoration-none pill-color bg-black d-flex">
                    <span class="k-plus text-white m-auto"></span>
                  </a>
                </span>
              </div>
            </ng-container>
          </div>
        </div>
      </a>

      <a class="col-7 px-0 d-flex text-decoration-none stars" *ngIf="redirect && product.nbReviews > 0"
        [routerLink]="[ firstLinkProduct]" [queryParams]="{'returnUrl':returnUrl}">
        <div class="col-12 d-flex px-0">
          <mat-icon class="f-size-16px mx-0 rep-star" *ngFor="let i of [1,2,3,4,5]"
            [class.text-main-gradient]="ratingService.starFillType(i, product.averageRating) == 0 || ratingService.starFillType(i, product.averageRating) == 1"
            [class.text-grey]="ratingService.starFillType(i, product.averageRating) == 2"></mat-icon>
          <span class="ml-2 f-size-13px">{{product.nbReviews}} avis </span>
        </div>
      </a>

      <div class="col-12 px-0 pt-1">
        <!-- DESIGNATION -->
        <div [class.col-9]="!typeClient && showInputQuantity" [class.col-12]="!(!typeClient && showInputQuantity)"
          class="px-0 pb-0">
          <p class="mb-0 description">
            <a *ngIf="redirect" (click)="scrollToService.scrollToTop()" [routerLink]="[ firstLinkProduct ]"
              [queryParams]="{'returnUrl':returnUrl}" class="text-black text-decoration-none"
              [class.f-size-16px]="!mobileQuery.matches" [class.f-size-13px]="mobileQuery.matches">
              {{ product.designationDynamic }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
