import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ScrollFacade } from './scroll.facade';
import { SCROLL_FEATURE_KEY,scrollReducer } from './scroll.reducer';


@NgModule({
  declarations: [
  
  ],
  imports: [
    StoreModule.forFeature(SCROLL_FEATURE_KEY, scrollReducer),
],
providers: [ScrollFacade],
})
export class ScrollStoreModule { }
