import { Pipe, PipeTransform } from '@angular/core';
import { DocumentRow } from 'src/app/models/document-row.model';

@Pipe({
  name: 'getRowsCrossSell'
})
export class GetRowsCrossSellPipe implements PipeTransform {

  transform(value: DocumentRow[], ...args: number[]): DocumentRow[] {
    if (value) {
      const rows = value.filter(r => r.crossSellId == args[0] && r.documentRowParentId == args[1]);
      return rows;
    }
    return [];
  }

}
