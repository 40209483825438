import { createLoadingActions, failure, load, success } from 'ngrx-loading-state';


import { Error } from "../../models/results/base-result.model";
import { LoginStart } from '../../modules/authentification/models/login-start.model';
import { LoginSuccess } from '../../modules/authentification/models/login-success.model';
import { RegisterStart } from '../../modules/authentification/models/register-start.model';
import { ResetPassword } from '../../modules/authentification/models/reset-password.model';


export const register = createLoadingActions(
    '[Auth] Register',
    load<RegisterStart>(),
    success<LoginSuccess>(),
    failure<{ errors: Error[] | undefined }>()
  );


  export const login = createLoadingActions(
    '[Auth] Login',
    load<LoginStart>(),
    success<LoginSuccess>(),
    failure<{ errors: Error[] | undefined }>()
  );

  export const refreshLogin = createLoadingActions(
    '[Auth] Refresh login',
    load(),
    success<LoginSuccess>(),
    failure<{ errors: Error[] | undefined }>()
  );

  export const requestResetPassword = createLoadingActions(
    '[Auth] Request reset password',
    load<{email:string}>(),
    success<{result:string}>(),
    failure<{ errors: Error[] | undefined }>()
  );

  export const resetPassword = createLoadingActions(
    '[Auth] Reset password',
    load<ResetPassword>(),
    success<{result:string}>(),
    failure<{ errors: Error[] | undefined }>()
  );

  export const logout = createLoadingActions(
    '[Auth] Logout',
    load(),
    success<{result:boolean}>(),
    failure<{ errors: Error[] | undefined }>()
  );

  
  export const autoLogin = createLoadingActions(
    '[Auth] Auto Login',
    load(),
    success(),
    failure()
  );

  export const loginAs = createLoadingActions(
    '[Auth] Login As',
    load<{userId:string, redirect:boolean}>(),
    success<LoginSuccess>(),
    failure<{ errors: Error[] | undefined }>()
  );
  