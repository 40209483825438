import { DomSanitizer, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";
import { SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safe'})
export class SafePipe implements PipeTransform  {
  constructor(private _sanitizer: DomSanitizer) {}
  
  
  transform(value?: string, type?: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl  {
   
    if(!value) this._sanitizer.bypassSecurityTrustHtml('');
   
    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value!);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value!);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value!);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value!);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value!);
      default:
        return this._sanitizer.bypassSecurityTrustHtml(value!);
    }
  }
  
}