import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { NguCarouselConfig } from '@ngu/carousel';
import { TranslateService } from '@ngx-translate/core';
import { pairwise, Subscription } from 'rxjs';
import { CrossSellGroup } from 'src/app/models/cross-sell.model';
import { DocumentRow } from 'src/app/models/document-row.model';
import { Document } from 'src/app/models/document.model';
import { Picture } from 'src/app/models/picture.model';
import { ProductFilter, ProductFilterValue } from 'src/app/models/product-filter.model';
import { ObjVariant, Product, ProductItem, Variant, VariantDetails } from 'src/app/models/product.model';
import { BackgroundService } from 'src/app/services/background.service';
import { CustomToastrService } from 'src/app/services/custom-toastr.service';
import { RatingService } from 'src/app/services/rating.service';
import { ScrollToService } from 'src/app/services/scroll-to.service';
import { SideNavService } from 'src/app/services/sidenav.service';
import { CartFacade } from 'src/app/store/cart/cart.facade';
import { CatalogFacade } from 'src/app/store/catalog/catalog.facade';
import { KeyValuePair } from 'src/app/Utils/KeyValuePair.interface';
import { environment } from 'src/environments/environment';
import { BsNotifyStockComponent } from '../../catalog/notify-stock/bs-notify-stock/bs-notify-stock.component';
import { DialogNotifyStockComponent } from '../../catalog/notify-stock/dialog-notify-stock/dialog-notify-stock.component';



interface Filter {
  filterId: number,
  filterDesignation: string,
  ctrlFilter: FormControl,
  type: number,
  values: ProductFilterValue[]
}


@Component({
  selector: 'app-drawer-cross-sell-content',
  templateUrl: './drawer-cross-sell-content.component.html',
  styleUrls: ['./drawer-cross-sell-content.component.scss']
})
export class DrawerCrossSellContentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('myCarouselImages', { static: false }) myCarouselImages: any;
  @ViewChild('myCarouselImages2', { static: false }) myCarouselImages2: any;

  header!: string;

  products!: ProductItem[];
  selectedProduct!: Product | null;
  crossSellGroups!: CrossSellGroup[];
  totalPages = 0;
  pageNumber = 1;
  productVariants: ObjVariant[] = [];
  selectedVariant: { [v: string]: boolean } = {};


  attributsToBeSelected: string[] = [];

  quantity = 1;

  cart!: Document;
  row: DocumentRow | null = null;

  carouselConfigImages: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    slide: 1,
    // interval: { timing: 4000, initialDelay: 0 },
    loop: false,
    touch: true,
    velocity: 0.2,
    easing: 'ease-in',
    animation: 'lazy',
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  }
  carouselItemsImages: Picture[] = [];
  carouselNbImages = 1;
  styleCarouselImages = '';

  carouselConfigImages2: NguCarouselConfig = {
    grid: { xs: 2, sm: 2, md: 2, lg: 2, all: 0 },
    load: 3,
    slide: 1,
    // interval: { timing: 4000, initialDelay: 0 },
    loop: false,
    touch: true,
    velocity: 0.2,
    easing: 'ease-in',
    animation: 'lazy',
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  }
  styleCarouselImages2 = '';


  //Filters 

  filters: Filter[] = [];
  selectedFilters: ProductFilter[] = [];
  ctrlSort = new FormControl('0');
  sortingEnumId!: number;
  ctrlFilterPriceMin = new FormControl(null);
  ctrlFilterPriceMax = new FormControl(null);

  filterMobileOpen = false;
  selectedFilterMobile: Filter | null = null;
  searchFilter: string | null = null;
  nbFiltersActive = 0;

  selectedOrder = false;
  selectedOrderChoice!: { key: number, text: string };


  bgColor!: string | null;
  selectedColor!: string | null;


  subscriptions: Subscription[] = [];

  mobileQuery!: MediaQueryList;

  variantSizeList!: VariantDetails[];
  variantColorList!: VariantDetails[];

  private _mobileQueryListener: () => void;


  constructor(
    public sideNavService: SideNavService,
    public catalogFacade: CatalogFacade,
    public cartFacade: CartFacade,
    public ratingService: RatingService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    media: MediaMatcher,
    public translateService: TranslateService,
    private scrollToService: ScrollToService,
    private toastrService: CustomToastrService,
    private backgroundService: BackgroundService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 576px)');
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // catalogFacade.clearProductsCrossSell({});
  }



  ngAfterViewInit(): void {
    this.subscriptions.push(...[
      this.sideNavService.dataCrossSellEmitter.subscribe(dataCrossSell => {
        // console.log(dataCrossSell)
        this.header = dataCrossSell?.designation!;
        // console.log('drawer cross sell')
        if (dataCrossSell?.categories) {
          this.catalogFacade.fetchProductsCrossSell({
            categoriesIds: dataCrossSell.categories,
            nbColumns: 4,
            page: 1,
            pageSize: 10000,
            productForCrossSell: this.sideNavService.addCurrentProductToCart ? this.sideNavService.currentProduct?.productId! : this.sideNavService.productAdded?.productId!
          });
        }
      }),
    ]);
  }

  ngOnInit(): void {
    // console.log(this.sideNavService.documentRowId)
    this.selectedOrderChoice = { key: 0, text: 'default' };
    if (this.sideNavService.dataCrossSell) {
      this.header = this.sideNavService.dataCrossSell.designation;
      // console.log('drawer cross sell')
      this.catalogFacade.fetchProductsCrossSell({
        categoriesIds: this.sideNavService.dataCrossSell.categories,
        nbColumns: 2,
        page: 1,
        pageSize: 10000,
        productForCrossSell: this.sideNavService.addCurrentProductToCart ? this.sideNavService.currentProduct?.productId! : this.sideNavService.productAdded?.productId!
      });
    }
    this.subscriptions.push(...[


      this.catalogFacade.getProductsCrossSell()
        .subscribe(products => {
          // console.log(products)
          this.products = products!;
        }),
      this.catalogFacade.getCrossSellGroups().subscribe(groups => {
        this.crossSellGroups = groups!;
        // console.log(groups)
      }),

      this.catalogFacade.getProductCrossSell()
        .subscribe(product => {
          if (this.selectedProduct?.productId != product?.productId) {
            this.quantity = 1;
          }
          this.attributsToBeSelected = [];
          this.productVariants = [];
          this.selectedVariant = {};
          this.selectedProduct = product!;
          if (product) {
            this.bgColor = null;
            this.selectedColor = null;
           
            this.carouselItemsImages = [];
            if (this.myCarouselImages) {
              this.myCarouselImages.reset(true);
              this.cdr.detectChanges();
            }
            if (this.myCarouselImages2) {
              this.myCarouselImages2.reset(true);
              this.cdr.detectChanges();
            }
            // if(this.carouselItemsImages.length > 1){
            //   this.carouselConfigImages = {
            //     ...this.carouselConfigImages,
            //     grid: { xs: 2, sm: 2, md: 2, lg: 2, all: 0 },
            //   }
            //   this.styleCarouselImages2 = `calc(100%/( (${this.carouselNbImages}) - 1 ) )`;
            // }
            // else{
            //   this.carouselConfigImages = {
            //     ...this.carouselConfigImages,
            //     grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },

            //   }
            //   this.styleCarouselImages = `calc(100%/${this.carouselNbImages})`;
            // }

            // this.cdr.detectChanges();

            // console.log('', product.productContainers)
            if (product.productContainers && product.productContainers.length == 1) {
              this.onSelectProduct(product.productContainers[0].productId, this.quantity);
              return;
            }

            // console.log(product.crossSellDiscount)


            product.productPictures.forEach(pic => {
              this.carouselItemsImages.push(pic);
              this.carouselNbImages = this.carouselItemsImages.length;
            });

            this.styleCarouselImages2 = `calc(100%/( (${this.carouselNbImages}) - 1 ) )`;
            this.styleCarouselImages = `calc(100%/${this.carouselNbImages})`;
            this.cdr.detectChanges();



         

            if (product.productVariants && product.productVariants.length > 0) {
            
              product.productVariants.forEach(pv => {

                let variants = [...pv.variants];
                const productVariant: ObjVariant = { ...pv, variants: variants.sort((a, b) => a.designation > b.designation ? 1 : -1) };
                this.productVariants.push(productVariant);

                const name = pv.designation;
                if (pv.variants) {
                  this.selectedVariant[name] = false;
                  pv.variants.forEach(vd => {
                    if (vd.current) {
                      this.selectedVariant[name] = vd.current;
                      if (pv.attributId == environment.ATTRIBUTS.COLOR) {
                        this.bgColor = vd.values;
                        this.selectedColor = this.backgroundService.getBackgroundColor(this.bgColor, this.mobileQuery.matches);
                      }
                      return;
                    }
                  });
                }
              });
            }
            else {
              const bgColors = this.selectedProduct.attributs.find(x => x.attributId == environment.ATTRIBUTS.COLOR)?.attributListValue_Values;
              this.selectedColor = this.backgroundService.getBackgroundColor(bgColors!, this.mobileQuery.matches);
            }


            setTimeout(() => {
              if (this.myCarouselImages) {
                this.myCarouselImages.onMove.subscribe((value: any) => {
                  const currentImage = this.carouselItemsImages[value.currentSlide];
                  if (currentImage && currentImage.attributListValue_Values)
                    this.selectedColor = this.backgroundService.getBackgroundColor(currentImage.attributListValue_Values)!;
                  if (!this.selectedColor) {
                    this.selectedColor = '#F2F3F5';
                  }

                });
              }
            }, 500);

   
            // console.log(this.productVariants, this.attributsToBeSelected)
            for (const [variant, isSelected] of Object.entries(this.selectedVariant)) {
              if (!isSelected) {
                // console.log(variant, isSelected)
                this.subscriptions.push(this.translateService.get(variant).subscribe(val => {
                  // console.log(this.attributsToBeSelected.find(x => x == val))
                  if (!this.attributsToBeSelected.find(x => x == val))
                    this.attributsToBeSelected.push(val);
                  // console.log(this.attributsToBeSelected)
                }));
              }
              else {
                const idxToRemove = this.attributsToBeSelected.findIndex(x => x == variant);
                if (idxToRemove > -1) {
                  this.attributsToBeSelected.splice(idxToRemove, 1);
                }
              }
            }

          }
        }),
      this.catalogFacade.getProductFiltersCrossSell()
        .subscribe(filters => {
          this.filters = [];
          if (filters) {
            filters.forEach(filter => {
              const f = {
                filterId: filter.filterId,
                filterDesignation: filter.filterDesignation,
                values: filter.values,
                type: filter.type,
                ctrlFilter: new FormControl()
              };
              if (filter.values) {
                f.ctrlFilter.setValue(filter.values.filter(sf => sf.selected).map(sf => sf.valueId));
              }
              this.filters.push(f);
            });
            // console.log('sf', this.filters)
          }
        }),
      this.catalogFacade.getSortEnumId()
        .subscribe(sortEnumId => {
          this.sortingEnumId = sortEnumId!;
          if (!this.sortingEnumId) this.sortingEnumId = 0;

          this.selectedOrderChoice = { key: this.sortingEnumId, text: '' };
        }),

      this.cartFacade.getCart()
        .subscribe(cart => {
          if (cart) {
            this.cart = cart!;

            if (cart.rows && this.sideNavService.documentRowId) {
              this.row = this.cart.rows.find(x => x.documentRowId == this.sideNavService.documentRowId)!; 
            }
          }
        }),
      this.cartFacade.getAddToCartState()
        .pipe(pairwise())
        .subscribe(([prevState, nextState]) => {
          if (nextState && nextState.success) {
            this.sideNavService.currentProduct = null;
            this.sideNavService.addCurrentProductToCart = false;

            if (this.sideNavService.dataCrossSell?.close) {
            setTimeout(() => {
              this.scrollToService.scrollTo('details_product_cross_sell');
              this.sideNavService.closeSideNavRight('cross-sell');
              this.sideNavService.clearDataCrossSell();
            }, 50);

            
            }
           
          }
        })
    ]);


  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.catalogFacade.clearProductsCrossSell({});
    this.selectedProduct = null;
    this.subscriptions = [];
  }



  onAddRemoveQuantity(quantity: number) {
    if (this.quantity === 1 && quantity === -1) return;
    if (this.quantity > 0) {
      this.quantity += quantity;
    }
    else {
      this.quantity = 1;
    }
    this.onSelectProduct(this.selectedProduct?.productId, this.quantity);
  }

  onChangeQuantity() {

  }

  getRowsCrossSell(crossSellId: number) {
    if (this.cart && this.cart.rows) {
      const rows = this.cart.rows.filter(r => r.crossSellId == crossSellId && r.documentRowParentId == this.sideNavService.documentRowId);
      return rows;
    }
    return [];
  }

  onAddToCart() {

    this.attributsToBeSelected = [];
    for (const [variant, isSelected] of Object.entries(this.selectedVariant)) {
      // console.log(variant, isSelected)
      if (!isSelected) {
        this.subscriptions.push(...[
          this.translateService.get('Select' + variant + 'Error')
            .subscribe(val => {
              // this.toastrService.error(val);
            }),
          this.translateService.get(variant)
            .subscribe(val => {
              if (!this.attributsToBeSelected.find(x => x == val))
                this.attributsToBeSelected.push(val);
            }),
        ]);
        // this.scrollToService.scrollTo("details_product_variants");

        return;
      }
      else {
        const idxToRemove = this.attributsToBeSelected.findIndex(x => x == variant);
        if (idxToRemove > -1) {
          this.attributsToBeSelected.splice(idxToRemove, 1);
        }
      }
    }

    for (const [variant, isSelected] of Object.entries(this.selectedVariant)) {
      if (!isSelected) {
        this.subscriptions.push(this.translateService.get('Select' + variant + 'Error').subscribe(val => {
          // this.toastrService.error(val);
        }));
        return;
      }
    }

    let productIdCrossSellId: KeyValuePair = {
      key: this.selectedProduct?.productId!,
      value: this.sideNavService.dataCrossSell?.crossSellId!
    };


    // if (this.sideNavService.addCurrentProductToCart && this.sideNavService.currentProduct) {
    //   this.cartFacade.addToCart({ productIds: [this.sideNavService.currentProduct.productId], quantity: 1, productIdCrossSellId: productIdCrossSellId });
    // } else {

    this.cartFacade.addToCart({ quantity: this.quantity, productIdCrossSellId: productIdCrossSellId, documentRowParentId: this.sideNavService.documentRowId! });
    // }
  }

  onRemoveFromCart(row: DocumentRow) {
    this.cartFacade.addRemoveFromCart({ documentRowId: row.documentRowId, quantity: -row.quantityRequested, validateCart: false })

  }

  onSelectProduct(event: any, quantityRequested?: number) {
    // console.log(event)
    if (event == this.selectedProduct?.productId) return;
    this.catalogFacade.fetchProductForCrossSell({ productId: event, calcDiscountCrossSell: true, documentRowId: this.sideNavService.documentRowId!, quantityRequested: quantityRequested });
    this.sideNavService.sidenavRightCrossSellStep = 3;
  }

  onAddCrossSell(crossSellId: number, designation: string, categories: number[]) {
    this.sideNavService.sidenavRightCrossSellStep = 2;
    this.sideNavService.setDataCrossSell(crossSellId, designation, categories)
  }

  onClose() {
    this.sideNavService.closeSideNavRight('cross-sell');
  }


  // filters 


  onFilterListMobile() { }
  onRemoveFilters() { }
  onRemoveFiltersMobile() { }
  onChangeOrder(event: any) {
    this.sortingEnumId = +event.target.value;
    // this.selectedOrderChoice = {key:event.target.value, text:}
  }

  onFilterList() {
    this.onCloseFilters();
    // console.log(filterPrice)
    const selectedFilters: ProductFilter[] = [];
    let priceMin = +this.ctrlFilterPriceMin.value!;
    let priceMax = +this.ctrlFilterPriceMax.value!;

    if (!priceMin) priceMin = 0;
    if (!priceMax) priceMax = 999999;

    if (this.ctrlFilterPriceMin.value || this.ctrlFilterPriceMax.value) {
      const fPrice = this.filters.find(x => x.type == 2)!;
      selectedFilters.push({
        filterId: fPrice.filterId,
        filterDesignation: fPrice.filterDesignation,
        type: fPrice.type,
        values: [{ valueDesignation: priceMin + '-' + priceMax, selected: true }]
      });
    }


    this.filters.filter(x => x.type != 2).forEach(filter => {
      if (filter.ctrlFilter.value) {
        if (filter.ctrlFilter.value.length === 1) {
          const f: ProductFilter = {
            filterId: filter.filterId,
            filterDesignation: filter.filterDesignation,
            type: filter.type,
            values: []
          };

          const val = filter.values.find(x => x.valueId == filter.ctrlFilter.value)!;
          f.values.push({
            valueId: val.valueId,
            valueDesignation: val.valueDesignation,
            selected: true
          });
          selectedFilters.push(f);
        }
        else {
          const f: ProductFilter = {
            filterId: filter.filterId,
            filterDesignation: filter.filterDesignation,
            type: filter.type,
            values: []
          };

          filter.ctrlFilter.value.forEach((v: any) => {
            const val = filter.values.find(x => x.valueId == v)!;
            f.values.push({
              valueId: val.valueId,
              valueDesignation: val.valueDesignation,
              selected: true
            });
          });
          selectedFilters.push(f);
        }
      }
    });

    this.nbFiltersActive = 0;
    selectedFilters.filter(x => x.values.filter(x => x.selected)).forEach(f => {
      this.nbFiltersActive += f.values.length;
    });

    this.selectedFilters = selectedFilters;
    // console.log(this.selectedFilters)
    if (this.sideNavService.dataCrossSell) {
      this.catalogFacade.fetchProductsCrossSell({
        categoriesIds: this.sideNavService.dataCrossSell.categories,
        nbColumns: 4,
        page: 1,
        pageSize: 10000,
        filters: this.selectedFilters,
        sortingEnumId: this.sortingEnumId,
        productForCrossSell: this.sideNavService.addCurrentProductToCart ? this.sideNavService.currentProduct?.productId! : this.sideNavService.productAdded?.productId!
      });
    }
  }

  onRemoveFilter(filter: ProductFilter, selectedValue: string) {


    switch (filter.type) {
      case 2:
        const filterPriceIdx = this.filters.findIndex(x => x.type == filter.type);
        this.filters.slice(filterPriceIdx, 1);
        this.ctrlFilterPriceMin.reset();
        this.ctrlFilterPriceMax.reset();
        this.onFilterList();
        break;

      default:
        const filterIdx = this.filters.findIndex(x => (x.filterId == filter.filterId && x.type == 1) || (x.filterId < 0 && x.type == filter.type));
        const newValues = [...filter.values].filter(x => x.valueId != +selectedValue && x.selected);
        const newFilter: Filter = {
          ...filter,
          values: newValues,
          ctrlFilter: new FormControl(newValues.map(x => x.valueId))
        };
        this.filters[filterIdx] = newFilter;

        this.onFilterList();
        break;
    }


  }

  onRemoveAllFilters() {
    this.selectedFilters = [];
    this.ctrlFilterPriceMax.reset();
    this.ctrlFilterPriceMin.reset();
    this.nbFiltersActive = 0;
    this.ctrlSort.setValue('0');
    if (this.sideNavService.dataCrossSell) {
      this.catalogFacade.fetchProductsCrossSell({
        categoriesIds: this.sideNavService.dataCrossSell.categories,
        nbColumns: 4,
        page: 1,
        pageSize: 10000,
        filters: this.selectedFilters,
        sortingEnumId: this.sortingEnumId,
        productForCrossSell: this.sideNavService.addCurrentProductToCart ? this.sideNavService.currentProduct?.productId! : this.sideNavService.productAdded?.productId!
      });
    }
    this.onCloseFilters();

  }


  onSelectFilterMobile(filter: Filter) {
    // console.log(filter)
    this.selectedFilterMobile = filter;
  }

  onCloseFilters() {
    this.filterMobileOpen = false;
    this.selectedFilterMobile = null;
  }

  onSelectFilterValueMobile() {
    this.filters.forEach(filter => {

      if (filter.ctrlFilter.value) {
        this.nbFiltersActive += filter.ctrlFilter.value.length;
      }
    });
  }

  onSelectPage(page: number) {
    // this.router.navigate([], { queryParams: { page: page } });
    this.pageNumber = page;
  }

  onOpenNotifyStock() {
    if (!this.mobileQuery.matches) {
      const dialogRef = this.dialog.open(DialogNotifyStockComponent,
        {
          width: '1100px',
          disableClose: false,
          data: { productId: this.selectedProduct, shopId: 1 }
        });
    }
    else {
      const bsRef = this.bottomSheet.open(BsNotifyStockComponent, {
        data: { productId: this.selectedProduct, shopId: 1 }
      });
    }

  }


  getProductBgColor(variantDetails: VariantDetails, p1 = false) {
    const color = this.backgroundService.getBackgroundColor(variantDetails.values, this.mobileQuery.matches, p1)!;
    return color;
  }
}
