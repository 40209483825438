import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';

import * as DoofinderActions from './doofinder.actions';

import { BaseResult } from "../../models/results/base-result.model";
import { environment } from "../../../environments/environment";
import { SharedFacade } from '../shared/shared.facade';
import { DoofinderResult } from 'src/app/models/doofinder-result.model';

@Injectable()
export class DoofinderEffects {

    culture='';

    search$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DoofinderActions.search.load),
            mergeMap((options) => {
                let params  = new HttpParams();
                if(options.query) params = params.append('query', options.query);
                if(options.filterBy) params = params.append('filterBy', options.filterBy);
                if(options.rpp) params = params.append('rpp', options.rpp);
                if(options.grouping) params = params.append('grouping', options.grouping);
                if(options.query_name) params = params.append('query_name', options.query_name);
                if(options.page) params = params.append('page', options.page);
                if(options.sortBy) params = params.append('sortBy', options.sortBy);
                // console.log(options)
                // params = params.append('hashid', environment.DOOFINDER.HASH_ID);
                // console.log(options)
                // console.log(params)
                return this.http.get<BaseResult>(environment.API_BASE_URL + 'store/search-doofinder',
                {
                    params:params,
                    withCredentials:true,
                }
                )
                    .pipe(
                        map(result => {
                            if (!result.isValid)
                                return DoofinderActions.search.failure({ errors: result.errors , error: 'error'});
                            // console.log(result)
                            return DoofinderActions.search.success({results:result.entity});
                        }),
                        DoofinderActions.search.catchError()
                    )
            }),
           
        )
    });

     ////////////////

     constructor(
        private actions$: Actions,
        private http: HttpClient,
    ) {
      
    }
}
