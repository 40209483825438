import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedEffects } from './shared.effects';
import { SharedFacade } from './shared.facade';
import { SHARED_FEATURE_KEY,sharedReducer } from './shared.reducer';


@NgModule({
  declarations: [
  
  ],
  imports: [
    StoreModule.forFeature(SHARED_FEATURE_KEY, sharedReducer),
EffectsModule.forFeature([SharedEffects]),
],
providers: [SharedFacade],
})
export class SharedStoreModule { }
