import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasMatchingString'
})
export class HasMatchingStringPipe implements PipeTransform {

  transform(value: string, ...args: string[]): boolean {
    return value.toLowerCase().includes((args[0]).toLowerCase());

  }

}
